<template >
  <v-hover v-slot="{ hover }">
    <v-sheet
      shaped
      outlined
      :width="width"
      :min-width="minWidth"
      :max-height="maxWidth"
      :v-ripple="!hasError"
      :elevation="hasError ? 0 : hover ? 8 : 0"
      class="video-chip rounded-br-xl"
      @click="actionClick(onClick)"
    >
      <v-row no-gutters v-if="progress">
        <v-col>
          <v-progress-linear :value="progress"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters class="pa-1">
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <v-icon left right>{{
                hasError
                  ? "mdi-alert-circle-outline"
                  : alreadyWatched
                  ? "mdi-movie-open-check-outline"
                  : "mdi-movie-open-outline"
              }}</v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" v-if="!noChips">
            <v-col cols="auto">
              <span class="font-weight-bold text--secondary caption">
                {{
                  videoObject.durationString ? videoObject.durationString : ""
                }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters align="start" justify="center">
            <v-col>
              <span class="text-body-2 video-name">
                {{ videoObject.displayName }}
              </span>
            </v-col>
            <v-col cols="auto" v-if="noChips">
              <span class="font-weight-bold text--secondary caption ml-2 mr-1">
                {{
                  videoObject.durationString ? videoObject.durationString : ""
                }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="!hideLessons">
            <v-col cols="auto">
              <CategoryChip
                v-for="lesson in videoObject.lessons"
                v-bind:key="lesson"
                class="mt-1 mr-1"
                :categoryKey="lesson"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-hover>
</template>

<script>
import CategoryChip from "./CategoryChip";

export default {
  name: "VideoChip",
  props: {
    video: {
      type: Object,
      required: false,
    },
    videoName: {
      type: String,
      required: false,
    },
    hideLessons: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 500,
    },
    minWidth: {
      type: Number,
      required: false,
      default: 270,
    },
    width: {
      type: Number,
      required: false,
    },
    progress: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    CategoryChip,
  },
  data() {
    return {
      videoObject: {},
      errorVideo: {
        displayName: this.$vuetify.lang.t("$vuetify.videos.was_deleted"),
      },
      hasError: false,
    };
  },
  mounted() {
    if (this.video) {
      this.videoObject = this.video;
    } else {
      if (this.videoName) {
        this.setVideo(this.videoName);
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    noChips() {
      return (
        !this.videoObject.lessons ||
        this.videoObject.lessons.length === 0 ||
        this.hideLessons
      );
    },
    alreadyWatched() {
      return (
        this.userData.watched && !!this.userData.watched[this.videoObject.name]
      );
    },
    userData() {
      return this.$store.getters.userData;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
  },
  watch: {
    video: function (newVal) {
      if (newVal) {
        this.hasError = false;
        this.videoObject = newVal;
      }
    },
    videoName: function (newVal) {
      if (newVal) {
        this.hasError = false;
        this.setVideo(newVal);
      }
    },
  },
  methods: {
    setVideo(name) {
      if (this.videoMetaData[name]) {
        this.videoObject = this.videoMetaData[name];
      } else {
        this.hasError = true;
        this.videoObject = this.errorVideo;
      }
    },
    actionClick(toRun) {
      if (!this.hasError) {
        toRun(this.videoObject);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.v-icon {
  font-size: 29px;
}

.video-name {
  line-height: 100%;
  transform: translateY(-3px);
  display: inline-block;
}

.video-chip {
  cursor: pointer;
  overflow: hidden;
}

.v-chip {
  opacity: 0.8;
  height: 23px;
}
</style>
