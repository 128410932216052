import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "firebase/analytics";
import store from "./store/store";
import router from "./router";
import "./registerServiceWorker";
import VideoBackground from 'vue-responsive-video-background-player'

var firebaseConfig = {
    apiKey: "AIzaSyBYF4cj8lZ1ovdDySryTpj3DGQEjlQZ0fw",
    authDomain: "ulli-deine-videos.firebaseapp.com",
    databaseURL: "https://ulli-deine-videos-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ulli-deine-videos",
    storageBucket: "ulli-deine-videos.appspot.com",
    messagingSenderId: "629123188848",
    appId: "1:629123188848:web:2777cb79b452279fd34cb5",
    measurementId: "G-YZFCNZF43B",
};
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = true;
Vue.component('video-background', VideoBackground);

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
