import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c(VAppBar,{attrs:{"app":"","clipped-left":"","color":"primary"}},[(_vm.user)?_c(VAppBarNavIcon,{on:{"click":function($event){return _vm.toggleDrawer()}}}):_vm._e(),_c(VProgressLinear,{attrs:{"absolute":"","color":"secondary","active":_vm.globalLoadingState,"bottom":"","indeterminate":true,"height":"5"}}),_c(VImg,{staticClass:"ml-n4",attrs:{"aspect-ratio":"16:9","max-width":"130","src":require('../../assets/udrive_logo_neg2_3-f_rgb_gruen.svg')},on:{"click":_vm.toHome}}),(_vm.user)?_c(VSpacer):_vm._e(),(_vm.user)?_c(VAutocomplete,{ref:"search",staticClass:"mx-3 my-2",attrs:{"solo":this.$vuetify.theme.dark,"solo-inverted":!this.$vuetify.theme.dark,"dark":!this.$vuetify.theme.dark,"hide-details":"","dense":"","rounded":"","flat":"","item-value":"name","items":_vm.videosAsArray,"item-text":"displayName","append-icon":"","clearable":_vm.$vuetify.breakpoint.mdAndUp,"no-data-text":_vm.$vuetify.lang.t('$vuetify.appBar.not_found'),"single-line":"","prepend-inner-icon":"mdi-magnify","label":_vm.$vuetify.breakpoint.mdAndUp
        ? _vm.$vuetify.lang.t('$vuetify.appBar.search_long')
        : _vm.$vuetify.lang.t('$vuetify.appBar.search')},on:{"change":_vm.onSearchSelected},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VSpacer):_vm._e(),(!_vm.user)?_c(VSpacer):_vm._e(),(_vm.showTokenButton)?_c(VBtn,{attrs:{"rounded":"","elevation":"3","color":"accent","dark":""},on:{"click":_vm.showToken}},[_vm._v(" get Token ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }