import { db, storage } from "./firebase";
import { masterPlaylistAsBase64 } from "./../util/masterPlaylist";
import { updateLinkedVideos } from "./linkHelpers";

// let openFunc = null;
// let origFetch = null;

// function changeURL(url) {
//     //example input: http://localhost:9199/ulli-deine-videos.appspot.com//videos/obi_-_lehmbrock/(firebase)480p.m3u8
//     if (url.includes("(firebase)")) {
//         const emulatorMode = location && location.hostname === "localhost";
//         if (url.startsWith("http://localhost:9199/ulli-deine-videos.appspot.com/")) {
//             url = url.substring("http://localhost:9199/ulli-deine-videos.appspot.com/".length, url.length);
//         } else if (url.startsWith("https://storage.googleapis.com/ulli-deine-videos.appspot.com/")) {
//             url = url.substring("https://storage.googleapis.com/ulli-deine-videos.appspot.com/".length, url.length);
//         }
//         let functionUrl = "https://europe-west1-ulli-deine-videos.cloudfunctions.net/changeURL/";
//         if (location && location.hostname === "localhost") {
//             functionUrl = "http://localhost:5001/ulli-deine-videos/europe-west1/changeURL/";
//         }
//         url = functionUrl + "chunk/?video=" + url;
//         if (emulatorMode) {
//             url = url + "&use_localhost=true";
//         }
//     }
//     return url;
// }

// export function registerXhrInterceptor() {
//     console.log("XHR Intercepted");
//     openFunc = XMLHttpRequest.prototype.open;
//     XMLHttpRequest.prototype.open = function (method, url, ...rest) {
//         console.log("Request: " + url);
//         url = changeURL(url);
//         //console.log(await changeURL(url));
//         return openFunc.call(this, method, url, ...rest);
//     };

//     origFetch = window.fetch;
// }

// export function unregisterXhrInterceptor() {
//     console.log("Turn off XHR Intercepted");
//     if (openFunc) {
//         XMLHttpRequest.prototype.open = openFunc;
//     }
// }

export async function getDownloadUrl(path) {
    // Create a reference to the file we want to download
    var starsRef = storage.ref(path);

    // Get the download URL
    try {
        const url = await starsRef.getDownloadURL();
        return url;
    } catch (error) {
        console.error(error);
        return error.code;
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // switch (error.code) {
        //     case 'storage/object-not-found':
        //         // File doesn't exist
        //         break;

        //     case 'storage/unauthorized':
        //         // User doesn't have permission to access the object
        //         break;

        //     case 'storage/canceled':
        //         // User canceled the upload
        //         break;

        //     case 'storage/unknown':
        //         // Unknown error occurred, inspect the server response
        //         break;
        // }
    }
}

export async function uploadBlobs(videoName, blobs) {
    const promises = [];

    try {
        blobs.forEach((blob) => {
            const path = "videos/" + videoName + "/" + blob.name;
            promises.push(storage.ref().child(path).put(blob.blob));
        });
        //add master playlist
        promises.push(
            storage
                .ref()
                .child("videos/" + videoName + "/master.m3u8")
                .putString(masterPlaylistAsBase64, "base64")
        );

        await Promise.all(promises);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function listVideos() {
    try {
        var ret = [];
        ret = await storage.ref().child("videos/").listAll();
        return ret.prefixes;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function deleteVideo(videoName, vueInstance) {
    try {
        //1st list all files in the folder
        const children = await storage
            .ref()
            .child("videos/" + videoName + "/")
            .listAll();

        //2nd delete all children
        const promises = [];
        children.items.forEach(async (child) => {
            promises.push(storage.ref().child(child.fullPath).delete());
        });
        await Promise.all(promises);

        //3rd get the actual metadata
        const result = await db.ref("/videos/" + videoName).once("value");
        let videoMetaData = null;
        if (result) {
            videoMetaData = result.val();
        }

        //4th delete links
        if (videoMetaData) {
            await updateLinkedVideos(videoName, videoMetaData.lessons, [], vueInstance);
        }

        //3rd delete metadaten
        await db.ref("/videos/" + videoName).set({});

        //5th delete video views
        await db.ref("/videoViews/" + videoName).set({});

        //6th delete must watches
        await db.ref("/mustWatches/" + videoName).set({});
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
}
