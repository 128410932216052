import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";

import Player from "./components/student/Player.vue";
import Login from "./components/login/Login.vue";
import LoginAdmin from "./components/login/LoginAdmin.vue";
import ManageStudents from "./components/admin/ManageStudents.vue";
import Admin from "./components/admin/Admin.vue";
import Student from "./components/student/Student.vue";
import VideoUpload from "./components/admin/VideoUpload";
import Lessons from "./components/admin/Lessons";
import ManageVideos from "./components/admin/ManageVideos";
import Home from "./components/student/Home";
import History from "./components/student/History";
import AllVideosLesson from "./components/student/AllVideosLesson.vue";
import VideosOfCategory from "./components/student/VideosOfCategory";
import AllVideos from "./components/student/AllVideos.vue";
import MustWatches from "./components/admin/MustWatches.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            beforeEnter: (to, from, next) => next("/s"),
            path: "",
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            beforeEnter: (to, from, next) => {
                if (!store.state.user) {
                    next();
                } else {
                    if (store.state.user.admin) {
                        next("/a");
                        return;
                    }
                    next("/");
                }
            },
        },
        {
            path: "/admin",
            name: "loginAdmin",
            component: LoginAdmin,
            beforeEnter: (to, from, next) => {
                if (!store.state.user) {
                    next();
                } else {
                    if (store.state.user.admin) {
                        next("/a");
                        return;
                    }
                    next("/");
                }
            },
        },
        {
            path: "/s",
            component: Student,
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next();
                } else {
                    next("/login");
                }
            },
            children: [
                {
                    beforeEnter: (to, from, next) => next("/s/home"),
                    path: "",
                    name: "student",
                },
                {
                    path: "home",
                    name: "home",
                    component: Home,
                },
                {
                    path: "player/:video",
                    name: "player",
                    component: Player,
                },
                {
                    path: "history",
                    name: "history",
                    component: History,
                },
                {
                    path: "categories",
                    name: "allVideosLesson",
                    component: AllVideosLesson,
                },
                {
                    path: "all",
                    name: "allVideos",
                    component: AllVideos,
                },
                {
                    path: "category/:categoryId",
                    name: "videosOfCategory",
                    component: VideosOfCategory,
                },
            ],
        },
        {
            path: "/a",
            component: Admin,
            beforeEnter: (to, from, next) => {
                if (store.state.user && store.state.user.admin) {
                    next();
                } else if (store.state.user && !store.state.user.admin) {
                    next();
                } else {
                    next("/login");
                }
            },
            children: [
                {
                    beforeEnter: (to, from, next) => next("/a/students"),
                    path: "",
                },
                {
                    path: "students",
                    name: "admin.students",
                    component: ManageStudents,
                    beforeEnter: (to, from, next) => {
                        if(store.state.user.allowed && store.state.user.allowed.includes("admin.students")){
                            next()
                        }else{
                            next("/s/")
                        }
                    },
                },
                {
                    path: "upload",
                    name: "admin.upload",
                    component: VideoUpload,
                    beforeEnter: (to, from, next) => {
                        if(store.state.user.allowed && store.state.user.allowed.includes("admin.upload")){
                            next()
                        }else{
                            next("/s/")
                        }
                    },
                },
                {
                    path: "manageVideos",
                    name: "admin.manageVideos",
                    component: ManageVideos,
                    beforeEnter: (to, from, next) => {
                        if(store.state.user.allowed && store.state.user.allowed.includes("admin.manageVideos")){
                            next()
                        }else{
                            next("/s/")
                        }
                    },
                },
                {
                    path: "categories",
                    name: "admin.categories",
                    component: Lessons,
                    beforeEnter: (to, from, next) => {
                        if(store.state.user.allowed && store.state.user.allowed.includes("admin.categories")){
                            next()
                        }else{
                            next("/s/")
                        }
                    },
                },
                {
                    path: "must-watches",
                    name: "admin.mustWatches",
                    component: MustWatches,
                    beforeEnter: (to, from, next) => {
                        if(store.state.user.allowed && store.state.user.allowed.includes("admin.mustWatches")){
                            next()
                        }else{
                            next("/s/")
                        }
                    },
                },
            ],
        },
    ],
});

export default router;
