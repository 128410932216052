import Vue from "vue";
import Vuex from "vuex";

import snackbar from "./module.snackbar";
import encoder from "./module.encode";
import appCache from "./module.appCache";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: "NOT-YET-LOADED",
        splashCount: 1,
        globalLoadingStateCount: 0,
        drawer: {
            isExpanded: true,
        },
        cookieDialogisShown: false,
    },
    mutations: {
        SET_USER: (state, value) => {
            state.user = value;
        },
        increaseGlobalLoadingState: (state) => {
            state.globalLoadingStateCount += 1;
        },
        decreaseGlobalLoadingState: (state) => {
            state.globalLoadingStateCount -= 1;
        },
        increaseSplashCount: (state) => {
            state.splashCount += 1;
        },
        decreaseSplashCount: (state) => {
            state.splashCount -= 1;
        },
        hideSplash: (state) => {
            state.splashCount = 0;
        },
        setDrawerExpanded(state, isExpanded) {
            state.drawer.isExpanded = isExpanded;
        },
        toggleDrawerExpanded(state) {
            state.drawer.isExpanded = !state.drawer.isExpanded;
        },
        showCookieDialog: (state) => {
            state.cookieDialogisShown = true;
        },
        hideCookieDialog: (state) => {
            state.cookieDialogisShown = false;
        },
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        globalLoadingState: (state) => {
            return state.globalLoadingStateCount > 0;
        },
        showSplash: (state) => {
            return state.splashCount > 0;
        },
        drawer: (state) => {
            return state.drawer;
        },
        isCookieDialogShown: (state) => {
            return state.cookieDialogisShown;
        },
    },
    actions: {
        setUser: (context, userToSet) => {
            context.commit("SET_USER", userToSet);
        },
    },
    modules: {
        snackbar,
        encoder,
        appCache,
    },
});

export default store;
