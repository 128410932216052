<template>
  <v-container class="lessons">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <div class="text-h3 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.manageVideos.header") }}
          </div>
          <div class="text--secondary">
            {{ $vuetify.lang.t("$vuetify.manageVideos.description") }}
          </div>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :label="$vuetify.lang.t('$vuetify.manageVideos.search')"
                single-line
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <v-data-table
                :items="videos"
                :headers="tableHeaders"
                :loading="loadingState.loadingVideos"
                :no-data-text="tableEmpty"
                :search="search"
                :itemsPerPage="100"
                :footer-props="{
                  'items-per-page-options': [50, 100, 500, -1],
                }"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mr-2" @click="openEditDialog(item)">
                          <v-icon
                            :disabled="loadingState.deletingVideo"
                            v-on="on"
                            color="blue"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{
                        $vuetify.lang.t("$vuetify.manageVideos.edit", item.name)
                      }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mr-2" @click="previewVideo(item)">
                          <v-icon v-on="on">mdi-play</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $vuetify.lang.t("$vuetify.manageVideos.preview")
                      }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="deleteItem(item.name)">
                          <v-icon
                            :disabled="loadingState.deletingVideo"
                            v-on="on"
                            color="error"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{
                        $vuetify.lang.t(
                          "$vuetify.manageVideos.delete",
                          item.name
                        )
                      }}</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.isVisible`]="{ item }">
                  <div>
                    {{
                      item.isVisible
                        ? $vuetify.lang.t("$vuetify.yes")
                        : $vuetify.lang.t("$vuetify.no")
                    }}
                  </div>
                </template>
                <template v-slot:[`item.lessons`]="{ item }">
                  <CategoryChip
                    v-for="lesson in item.lessons"
                    v-bind:key="lesson"
                    :showAll="true"
                    class="mb-1 mr-1"
                    :categoryKey="lesson"
                  />
                  <v-tooltip
                    bottom
                    v-if="
                      (item && !item.lessons && item.isVisible) ||
                      (item &&
                        Array.isArray(item.lessons) &&
                        item.lessons.size === 0 &&
                        item.isVisible)
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip text v-on="on" color="amber darken-2">
                        <v-avatar left>
                          <v-icon>mdi-alert-outline</v-icon>
                        </v-avatar>
                        Keine
                        <v-icon right small>mdi-help-circle</v-icon></v-chip
                      >
                    </template>
                    <div style="width: 500px">
                      {{
                        $vuetify.lang.t(
                          "$vuetify.manageVideos.table.tooltip.empty"
                        )
                      }}
                    </div>
                  </v-tooltip>
                </template>
                <template v-slot:[`header.name`]="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div style="display: inline" v-on="on">
                        <span> {{ header.text }}</span>
                        <v-icon right small>mdi-help-circle</v-icon>
                      </div>
                    </template>
                    <span
                      >{{
                        $vuetify.lang.t(
                          "$vuetify.manageVideos.table.tooltip.name_1"
                        )
                      }}
                      <br />
                      {{
                        $vuetify.lang.t(
                          "$vuetify.manageVideos.table.tooltip.name_2"
                        )
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`header.displayName`]="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div style="display: inline" v-on="on">
                        <span> {{ header.text }}</span>
                        <v-icon right small>mdi-help-circle</v-icon>
                      </div>
                    </template>
                    <span>{{
                      $vuetify.lang.t(
                        "$vuetify.manageVideos.table.tooltip.display"
                      )
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`header.isVisible`]="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div style="display: inline" v-on="on">
                        <span> {{ header.text }}</span>
                        <v-icon right small>mdi-help-circle</v-icon>
                      </div>
                    </template>
                    <span>{{
                      $vuetify.lang.t(
                        "$vuetify.manageVideos.table.tooltip.visible"
                      )
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <VideoEditDialog
      :show.sync="editDialog.show"
      :video.sync="editDialog.videoToEdit"
      :changed.sync="editDialog.changed"
    />
    <div
      v-if="preview.show"
      :class="
        'video-container ' +
        (preview.isTop ? 'video-container__top' : 'video-container__bottom')
      "
    >
      <v-btn
        fab
        small
        absolute
        top
        left
        @click="preview.isTop = !preview.isTop"
      >
        <v-icon>{{
          preview.isTop ? "mdi-chevron-double-down" : "mdi-chevron-double-up"
        }}</v-icon>
      </v-btn>
      <v-btn fab small absolute top right @click="preview.show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <HlsVideo
        v-if="preview.url"
        :url="preview.url"
        :playbackState.sync="preview.playbackState"
        :quality.sync="preview.quality"
      >
      </HlsVideo>
    </div>
  </v-container>
</template>

<script>
import VideoEditDialog from "./../dialogs/EditVideo";
import { listVideos, deleteVideo } from "./../../firebase/storage";
import { db } from "./../../firebase/firebase";
import CategoryChip from "../appComponents/CategoryChip.vue";
import HlsVideo from "../appComponents/HlsVideo.vue";

export default {
  name: "manageVideos",
  components: { VideoEditDialog, CategoryChip, HlsVideo },
  data() {
    return {
      videos: [],
      tableHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.name"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.displayName"),
          value: "displayName",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.duration"),
          value: "durationString",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.views"),
          value: "views",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.lessons"),
          value: "lessons",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manageVideos.table.visible"),
          value: "isVisible",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      tableEmpty: this.$vuetify.lang.t("$vuetify.manageVideos.empty"),
      search: "",
      loadingState: {
        loadingVideos: false,
        deletingVideo: false,
      },
      editDialog: {
        show: false,
        videoToEdit: {},
        changed: false,
      },
      preview: {
        show: false,
        isTop: true,
        url: null,
        playbackState: {
          isPlaying: false,
          duration: -1,
          currentTime: -1,
          hasEnded: false,
          watchTime: 0,
        },
        quality: {
          pool: [],
          used: null,
          forcedByUser: -1,
        },
      },
    };
  },
  mounted() {
    this.loadVideos();
  },
  watch: {
    "editDialog.changed": function name(newVal, oldVal) {
      if (!oldVal && newVal) {
        //we have a different video, replace the old one
        for (var i = 0; i < this.videos.length; i++) {
          if (this.editDialog.videoToEdit.name === this.videos[i].name) {
            this.videos[i] = this.editDialog.videoToEdit;
            break;
          }
        }
      }
    },
  },
  methods: {
    async loadVideos() {
      this.setLoading("loadingVideos");
      const results = await Promise.all([
        listVideos(),
        db.ref("videos/").once("value"),
        db.ref("videoViews/").once("value"),
      ]);
      const videos = results[0];
      const videosMeta = results[1].val();
      const videoViews = results[2].val();
      if (!Array.isArray(videos) || !videosMeta) {
        //error
        this.$store.dispatch(
          "alertError",
          this.$vuetify.lang.t("$vuetify.manageVideos.list_error")
        );
        this.tableEmpty = this.$vuetify.lang.t(
          "$vuetify.manageVideos.list_error"
        );
        this.removeLoading("loadingVideos");
        return;
      }
      const ret = [];
      videos.forEach((videoPrefix) => {
        const metaData = videosMeta ? videosMeta[videoPrefix.name] : null;
        const views = videoViews ? videoViews[videoPrefix.name] : null;
        let combined = {};
        combined["name"] = videoPrefix.name;
        if (metaData) {
          combined = Object.assign(combined, metaData);
        } else {
          combined["isVisible"] = false;
        }
        if (views) {
          combined["views"] = views;
        } else {
          combined["views"] = 0;
        }
        ret.push(combined);
      });
      this.videos = ret;
      this.removeLoading("loadingVideos");
    },
    async deleteItem(videoName) {
      const result = await this.$confirm(
        this.$vuetify.lang.t("$vuetify.manageVideos.confirm_delete", videoName)
      );
      if (result) {
        this.setLoading("deletingVideo");
        const ret = await deleteVideo(videoName, this);
        if (!ret) {
          //error
          this.$store.dispatch(
            "alertError",
            this.$vuetify.lang.t(
              "$vuetify.manageVideos.delete_error",
              videoName
            )
          );
          this.removeLoading("deletingVideo");
          return;
        }
        this.$store.dispatch(
          "alertSuccess",
          this.$vuetify.lang.t("$vuetify.manageVideos.deleted", videoName)
        );
        this.loadVideos();
        this.removeLoading("deletingVideo");
      }
    },
    openEditDialog(video) {
      console.log("Test")
      this.editDialog.videoToEdit = video;
      this.editDialog.changed = false;
      this.editDialog.show = true;
    },
    setLoading(set) {
      this.loadingState[set] = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading(set) {
      this.loadingState[set] = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
    previewVideo(item) {
      this.preview.url =
        "https://(firebase)videos/" + item.name + "/master.m3u8";
      this.preview.show = true;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.video-container {
  background-color: cyan;
  position: fixed;
  padding-top: 0px;
  right: 20px;
  height: 200px;
  max-height: 200px;
  width: 355px;
}

.video-container__top {
  top: 90px;
}

.video-container__bottom {
  bottom: 70px;
}
</style>