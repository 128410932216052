import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.cat.isHidden || _vm.showAll)?_c(VTooltip,{attrs:{"disabled":!(_vm.cat && _vm.cat.description),"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g(_vm._b({staticClass:"py-1 px-0",style:((_vm.cat && _vm.cat.isHidden) ? "opacity: 0.3;" : ""),attrs:{"dark":_vm.dark,"light":_vm.light,"color":_vm.color,"active-class":"chip"}},'v-chip',attrs,false),on),[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('div',[_c(VIcon,{attrs:{"left":"","size":"18"}},[_vm._v(" "+_vm._s(_vm.cat && _vm.cat.icon ? _vm.cat.icon : "mdi-car")+" ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.cat ? _vm.cat.text ? _vm.cat.text : _vm.$vuetify.lang.t( "$vuetify.manageVideos.table.lesson_error" ) : ""))])],1)])],1)],1)],1)]}}],null,false,2486235205)},[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.cat ? (_vm.cat.description ? _vm.cat.description : "") : ""))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }