<template >
  <div class="nextVideo-side" :style="'max-width: ' + maxWith + ';'">
    <div v-if="recommendations && recommendations.length > 0">
      <div class="text-subtitle-1">
        {{ $vuetify.lang.t("$vuetify.home.recommended.sub_title") }}
      </div>
      <VideoChip
        class="mb-1"
        v-for="video in recommendations"
        v-bind:key="video"
        :videoName="video"
        :minWidth="300"
        :onClick="playVideo"
      ></VideoChip>
      <br />
    </div>
    <div v-if="nextVideos.length > 0">
      <div class="text-subtitle-1">
        {{ $vuetify.lang.t("$vuetify.videos.next_videos") }}
      </div>
      <VideoChip
        class="mb-1"
        v-for="video in nextVideos"
        v-bind:key="video.name"
        :videoName="video"
        :minWidth="300"
        :onClick="playVideo"
      ></VideoChip>
    </div>
  </div>
</template>

<script>
import VideoChip from "./VideoChip";
import { getNextNVideo } from "../../util/playlist";

export default {
  name: "NextNVideos",
  props: {
    videoCount: {
      type: Number,
      required: true,
    },
    videoName: {
      type: String,
      required: false,
    },
    maxWith: {
      type: String,
      required: true,
    },
    autoPlayVideo: {
      type: String,
      required: true,
    },
  },
  components: {
    VideoChip,
  },
  data() {
    return {
      nextVideos: [],
    };
  },
  mounted() {
    if (this.videoName) {
      this.update(this.videoName);
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userData() {
      return this.$store.getters.userData;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    recommendations() {
      if (this.user.recommendations && this.user.recommendations.length > 0) {
        return this.user.recommendations;
      }
      return [];
    },
  },
  watch: {
    videoName: function (newVal) {
      if (newVal) {
        this.$nextTick(function () {
          this.update(newVal);
        });
      }
    },
  },
  methods: {
    update(videoName) {
      //update next videos
      let videosToGet = 0;
      if (this.videoCount - this.recommendations.length > 1) {
        videosToGet = this.videoCount - this.recommendations.length;
      }

      if (videosToGet > 0) {
        let onlyUnseen = true;
        if (
          this.userData.watched &&
          Object.keys(this.userData.watched).length >=
            Object.keys(this.videoMetaData).length
        ) {
          onlyUnseen = false;
        }
        this.nextVideos = getNextNVideo(
          this.$store.getters.playlist,
          videoName,
          videosToGet,
          onlyUnseen,
          this.$store.getters.userData
        );
      } else {
        this.nextVideos = [];
      }

      //update autoplay video
      if (this.recommendations.length > 0){
        this.$emit("update:autoPlayVideo", this.recommendations[0]);
      }else{
        if(this.nextVideos.length > 0){
          this.$emit("update:autoPlayVideo", this.nextVideos[0]);
        }else{
          const videos = Object.values(this.videoMetaData)
          const video = videos[Math.floor(Math.random() * videos.length)]
          this.$emit("update:autoPlayVideo", video.name);
        }
      }
    },
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
    playVideoName(video) {
      this.$router.push({ name: "player", params: { video: video } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.nextVideo-side {
  height: 100%;
  min-width: 200px;
  overflow-y: auto;
}
</style>
