<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    width="650px"
    overlay-opacity="0.8"
    transition="dialog-transition"
    class="cookie-dialog"
  >
      <v-container class="pt-0 pl-0 pr-0">
    <v-card>
        <v-row no-gutters>
          <v-container class="pa-0">
            <v-sheet class="cookie-header" color="primary">
              <v-row dark no-gutters justify="center" align="center">
                <v-col
                  v-if="!$vuetify.breakpoint.smAndDown"
                  class="text-h3 pr-2"
                  cols="auto"
                >
                  🍪
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col>
                      <div
                        :class="
                          $vuetify.breakpoint.smAndDown
                            ? 'text-h5 font-weight-light'
                            : 'text-h4 font-weight-light'
                        "
                      >
                        {{
                          $vuetify.breakpoint.smAndDown
                            ? $vuetify.lang.t("$vuetify.cookie.title_small")
                            : $vuetify.lang.t("$vuetify.cookie.title1")
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="!$vuetify.breakpoint.smAndDown">
                    <v-col>
                      <div class="text-h5 font-weight-light">
                        {{ $vuetify.lang.t("$vuetify.cookie.title2") }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        </v-row>
        <v-row no-gutters class="pa-2">
          <v-col>
            <div
              :class="
                'text--secondary ' +
                ($vuetify.breakpoint.smAndDown ? 'small-text' : '')
              "
            >
              {{ $vuetify.lang.t("$vuetify.cookie.description") }}<br />
              {{ $vuetify.lang.t("$vuetify.cookie.link1") }}
              <a
                href="https://www.verbraucherzentrale.de/wissen/digitale-welt/datenschutz/cookies-kontrollieren-und-verwalten-11996"
                target="_blank"
              >
                {{ $vuetify.lang.t("$vuetify.cookie.link2") }}
              </a>
              {{ $vuetify.lang.t("$vuetify.cookie.link3") }}
              <a
                href="https://www.ulli-deinefahrschule.de/impressum/"
                target="_blank"
              >
                {{ $vuetify.lang.t("$vuetify.footer.legalNotice") }} </a
              >.
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-2">
          <v-col>
            <v-expansion-panels :popout="$vuetify.breakpoint.smAndUp">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        {{
                          $vuetify.lang.t("$vuetify.cookie.essentials_title")
                        }}
                      </v-col>
                      <v-col cols="auto" class="ml-auto mr-6">
                        <v-switch
                          v-model="essentials"
                          disabled
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    :class="
                      'text--secondary ' +
                      ($vuetify.breakpoint.smAndDown ? 'small-text' : '')
                    "
                  >
                    {{ $vuetify.lang.t("$vuetify.cookie.essentials_desc") }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        {{
                          $vuetify.lang.t("$vuetify.cookie.analytics_title")
                        }}</v-col
                      >
                      <v-col cols="auto" class="ml-auto mr-6">
                        <v-switch
                          v-model="analytics"
                          @click.stop="!analytics"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    :class="
                      'text--secondary ' +
                      ($vuetify.breakpoint.smAndDown ? 'small-text' : '')
                    "
                  >
                    {{ $vuetify.lang.t("$vuetify.cookie.analytics_desc") }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2 pa-2">
          <v-alert
            :value="doReloadOnSave"
            transition="slide-y-reverse-transition"
            border="left"
            dense
            text
            type="info"
            >{{ $vuetify.lang.t("$vuetify.cookie.reloadInfo") }}</v-alert
          >
        </v-row>
        <v-row no-gutters class="pa-2">
          <v-col cols="12" sm="auto" class="ml-auto">
            <v-btn
              :block="!$vuetify.breakpoint.smAndUp"
              text
              @click="declineAndClose"
            >
              {{ $vuetify.lang.t("$vuetify.cookie.disable") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-btn
              :block="!$vuetify.breakpoint.smAndUp"
              color="primary"
              @click="close"
            >
              {{ $vuetify.lang.t("$vuetify.cookie.go") }}
            </v-btn>
          </v-col>
        </v-row>
    </v-card>
      </v-container>
  </v-dialog>
</template>


<script>
export default {
  name: "cookie-dialog",
  components: {},
  props: {},
  data() {
    return {
      show: false,
      doReloadOnSave: false,
      essentials: true,
      analytics: true,
      settings: {
        lastOpend: null,
        analytics: {
          permission: true,
          lastChangeAt: null,
        },
      },
    };
  },
  watch: {
    cookieDialogIsShown: function (newVal) {
      this.show = newVal;
      if (newVal) {
        this.onOpened();
      }
    },
    analytics: function (newVal) {
      if (!newVal) {
        var isActive = localStorage.getItem("analyticsIsCurrentlyEnabled");
        if (isActive && isActive == "true") {
          this.doReloadOnSave = true;
        }
      } else {
        this.doReloadOnSave = false;
      }
    },
  },
  methods: {
    init() {},
    onOpened() {
      var settingsString = window.localStorage.getItem("cookieSetting");
      if (settingsString) {
        var settings = JSON.parse(settingsString);
        settings.lastOpend = Date.now();
        this.settings = settings;
        this.analytics = settings.analytics.permission;
      } else {
        //opened Dialog for the first time
        this.analytics = true;
        this.settings = {
          lastOpend: Date.now(),
          analytics: {
            permission: true,
            lastChangeAt: null,
          },
        };
      }
    },
    declineAndClose() {
      (this.analytics = false), this.close();
    },
    close() {
      this.saveData();
      if (this.doReloadOnSave) {
        window.location.reload();
      } else {
        this.$store.commit("hideCookieDialog");
      }
    },
    saveData() {
      if (this.analytics !== this.settings.analytics.permission) {
        this.settings.analytics.lastChangeAt = Date.now();
      }
      this.settings.analytics.permission = this.analytics;
      localStorage.setItem("cookieSetting", JSON.stringify(this.settings));
    },
  },
  computed: {
    cookieDialogIsShown() {
      return this.$store.getters.isCookieDialogShown;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.cookie-header {
  padding: 10px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.small-text {
  font-size: 13px;
}
</style>