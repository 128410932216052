<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-3">
          <v-row align="center">
            <v-col cols="auto">
              <span
                :class="
                  ($vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') +
                  ' font-weight-light'
                "
              >
                {{ video ? video.displayName : "Kein video...." }}
              </span>
            </v-col>
            <v-col
              v-for="lesson in video && !$vuetify.breakpoint.mobile
                ? video.lessons
                : []"
              v-bind:key="lesson"
              cols="auto"
            >
              <category-chip :categoryKey="lesson" />
            </v-col>
          </v-row>
          <v-row align="stretch" dense>
            <v-col>
              <v-row>
                <v-col v-if="useHlsJsPlayer">
                  <HlsVideo
                    :url="videoUrl"
                    :quality.sync="quality"
                    :playbackState.sync="playbackState"
                  >
                  </HlsVideo>
                </v-col>
                <v-col v-else>
                  <HlsVideoNative
                    :url="videoUrl"
                    :playbackState.sync="playbackState"
                  >
                  </HlsVideoNative>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-n4">
                <v-col cols="auto" v-if="video && video.description">
                  <span class="text--secondary">
                    {{ video.description }}
                  </span>
                </v-col>
                <v-col cols="auto" v-if="video && video.description">
                  <div class="divider"></div>
                </v-col>
                <v-col cols="auto" v-if="useHlsJsPlayer">
                  <v-menu top v-model="isOpen" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon large v-bind="attrs" v-on="on" elevation="5">
                        <v-icon v-if="isOpen"> mdi-close </v-icon>
                        <v-icon v-else> mdi-movie-open-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="5" class="px-4 pt-4">
                      <span>{{
                        $vuetify.lang.t("$vuetify.videos.resolution")
                      }}</span>
                      <v-radio-group v-model="qualityChooser">
                        <v-radio
                          v-for="(quality, index) in availableQuality"
                          :key="index"
                          :label="quality.text"
                          :value="quality"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <div class="divider"></div>
                </v-col>
                <v-col cols="auto">
                  <v-switch v-model="autoPlay.enabled">
                    <template v-slot:label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs" style="display: inline">
                            <span>{{
                              $vuetify.lang.t("$vuetify.videos.autoPlay")
                            }}</span>
                            <v-icon right> mdi-help-circle</v-icon>
                          </div>
                        </template>
                        {{ $vuetify.lang.t("$vuetify.videos.autoPlay_help") }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-col>
                <v-col v-if="autoPlay.enabled">
                  <v-row dense>
                    <v-col>
                      <span class="ml-3 text-body-2 text-secondary">{{
                        autoPlayVideo
                          ? $vuetify.lang.t("$vuetify.videos.next")
                          : $vuetify.lang.t("$vuetify.videos.no_next")
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <VideoChip
                        v-if="autoPlayVideo"
                        :videoName="autoPlayVideo"
                        :minWidth="300"
                        :progress="autoPlay.progress"
                        :onClick="playVideo"
                        hideLessons
                      ></VideoChip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer />
              </v-row>
              <v-row>
                <v-col v-if="$vuetify.breakpoint.mobile">
                  <NextNVideosList
                    ref="nextListMobile"
                    :videoCount="8"
                    :videoName="video && video.name ? video.name : ''"
                    maxWith="100%"
                    :autoPlayVideo.sync="autoPlayVideo"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="ml-2" v-if="!$vuetify.breakpoint.mobile">
              <NextNVideosList
                ref="nextList"
                :videoCount="8"
                :videoName="video && video.name ? video.name : ''"
                maxWith="370px"
                :autoPlayVideo.sync="autoPlayVideo"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "./../../firebase/firebase";
import HlsVideo from "../appComponents/HlsVideo.vue";
import HlsVideoNative from "../appComponents/HlsVideoNative";
import VideoChip from "./../appComponents/VideoChip";
import CategoryChip from "./../appComponents/CategoryChip";
import NextNVideosList from "./../appComponents/NextNVideosList.vue";
import { getNextNVideo } from "../../util/playlist";

export default {
  name: "Player",
  components: {
    HlsVideo,
    HlsVideoNative,
    VideoChip,
    CategoryChip,
    NextNVideosList,
  },
  data() {
    return {
      videoUrl: "",
      video: null,
      autoPlayVideo: "",
      quality: {
        pool: [],
        used: null,
        forcedByUser: -1,
      },
      playbackState: {
        isPlaying: false,
        duration: -1,
        currentTime: -1,
        hasEnded: false,
        watchTime: 0,
      },
      autoQuality: {
        level: -1,
        text: this.$vuetify.lang.t("$vuetify.videos.resolution_auto"),
      },
      qualityChooser: null,
      markedAsWatched: false,
      isOpen: false,
      nextVideos: [],
      autoPlay: {
        enabled: localStorage.getItem("autoPlay") === "true" ? true : false,
        interval: null,
        counter: 0,
        intervallStepInMS: 100,
        waitInS: 5,
        progress: 0,
      },
    };
  },
  beforeDestroy() {
    this.resetAutoPlay();
  },
  mounted() {
    this.qualityChooser = this.autoQuality;
    this.setVideo();
  },
  computed: {
    availableQuality: function () {
      const ret = [];
      for (var i = 0; i < this.quality.pool.length; i++) {
        const quali = this.quality.pool[i];
        if (quali.width && quali.height) {
          quali["level"] = i;
          quali["text"] = this.$vuetify.lang.t(
            "$vuetify.videos.resolution_chooser_text",
            quali.width,
            quali.height
          );
          ret.push(quali);
        }
      }
      ret.push(this.autoQuality);
      return ret;
    },
    user() {
      return this.$store.getters.user;
    },
    userData() {
      return this.$store.getters.userData;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    useHlsJsPlayer() {
      const mediaSource = (window.MediaSource =
        window.MediaSource || window.WebKitMediaSource);
      return mediaSource && typeof mediaSource.isTypeSupported === "function";
    },
    recommendations() {
      if (this.user.recommendations && this.user.recommendations.length > 0) {
        return this.user.recommendations;
      }
      return [];
    },
  },
  watch: {
    "$route.params.video": function name() {
      this.setVideo();
    },
    "quality.used": function (newVal) {
      var text = this.$vuetify.lang.t("$vuetify.videos.resolution_auto");
      if (this.quality.forcedByUser === -1 && this.quality.pool[newVal].width) {
        text +=
          " (" +
          this.$vuetify.lang.t(
            "$vuetify.videos.resolution_chooser_text",
            this.quality.pool[newVal].width,
            this.quality.pool[newVal].height
          ) +
          ")";
      }
      this.autoQuality.text = text;
    },
    video: function (newVal) {
      this.videoUrl =
        "https://(firebase)videos/" + newVal.name + "/master.m3u8";
    },
    availableQuality: function (newVal) {
      this.autoQuality = newVal[newVal.length - 1];
    },
    qualityChooser: function (newVal) {
      this.quality.forcedByUser = newVal.level;
    },
    videoUrl: function () {
      if (this.quality.forcedByUser === -1) {
        this.qualityChooser = this.autoQuality;
      }
      this.markedAsWatched = false;
      this.resetAutoPlay();
      let onlyUnseen = true;
      if (
        this.userData.watched &&
        Object.keys(this.userData.watched).length >=
          Object.keys(this.videoMetaData).length
      ) {
        onlyUnseen = false;
      }
      this.nextVideos = getNextNVideo(
        this.$store.getters.playlist,
        this.video.name,
        8,
        onlyUnseen,
        this.$store.getters.userData
      );
      this.$nextTick(function () {
        this.$vuetify.goTo(0);
      });
    },
    "playbackState.watchTime": function (newVal, oldVal) {
      if (newVal > oldVal && this.playbackState.duration > 10) {
        if (newVal > this.playbackState.duration * 0.4) {
          //if the user watched at least 40% of the video, we add it to the user history and increment the views count
          this.removeFromRecommendations();
          if (!this.markedAsWatched) {
            this.markedAsWatched = true;
            this.incrementVideoViews();
            this.addToViewedVideos();
          }
        }
      }
    },
    "autoPlay.enabled": function (newVal) {
      localStorage.setItem("autoPlay", newVal ? "true" : "false");
      if (!newVal) {
        this.resetAutoPlay();
      } else {
        if (this.playbackState.hasEnded) {
          this.autoPlayGo();
        }
      }
    },
    "playbackState.hasEnded": function (newVal) {
      if (newVal && this.autoPlay.enabled) {
        this.autoPlayGo();
      }
    },
  },
  methods: {
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
    async incrementVideoViews() {
      await db.ref("videoViews/" + this.video.name).transaction((views) => {
        if (views) {
          views++;
        } else {
          views = 1;
        }
        return views;
      });
    },
    async addToViewedVideos() {
      const obj = {
        video: this.video.name,
        timestamp: new Date().getTime(),
      };
      await db
        .ref("userData/" + this.user.uuid + "/watched/" + this.video.name)
        .set(obj);

      this.$store.commit("addOneVideoToHistory", obj);
    },
    onAutoPlayInterval() {
      if (
        this.autoPlay.counter >=
        this.autoPlay.waitInS / (this.autoPlay.intervallStepInMS / 1000)
      ) {
        //go to next video
        if (this.autoPlayVideo) {
          this.playVideo(this.videoMetaData[this.autoPlayVideo]);
        }
        this.resetAutoPlay();
      } else {
        this.autoPlay.counter = this.autoPlay.counter + 1;
        this.autoPlay.progress =
          (((this.autoPlay.intervallStepInMS / 1000) * this.autoPlay.counter) /
            this.autoPlay.waitInS) *
          100;
      }
    },
    resetAutoPlay() {
      clearInterval(this.autoPlay.interval);
      this.autoPlay.interval = null;
      this.autoPlay.counter = 0;
      this.autoPlay.progress = 0;
    },
    autoPlayGo() {
      this.autoPlay.counter = 0;
      this.autoPlay.interval = setInterval(
        this.onAutoPlayInterval,
        this.autoPlay.intervallStepInMS
      );
    },
    setVideo() {
      if (
        this.$route.params &&
        this.$route.params.video &&
        this.videoMetaData[this.$route.params.video]
      ) {
        this.video = this.videoMetaData[this.$route.params.video];
      } else {
        console.error(
          "No video name found! Please give a videoname inside the params of the $route."
        );
      }
    },
    async removeFromRecommendations() {
      const index = this.recommendations.indexOf(this.video.name);
      if (index > -1) {
        const newArray = [];
        this.recommendations.forEach((videoName) => {
          if (videoName != this.video.name) newArray.push(videoName);
        });
        this.user.recommendations = newArray;
        db.ref("students/" + this.user.uuid + "/recommendations").set(newArray);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  height: 40px;
  width: 1px;
  background: rgba(102, 94, 94, 0.63);
}
</style>
