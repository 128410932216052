<template>
  <v-app dark>
    <AppDrawer v-if="!splash && user"></AppDrawer>
    <AppBar app absolute v-if="!splash"></AppBar>
    <Encoder></Encoder>

    <v-main>
      <router-view v-if="!splash"></router-view>
      <Splash v-else></Splash>
      <AppSnackbar></AppSnackbar>
    </v-main>
    <AppFooter />
    <CookieSetting />
    <v-snackbar bottom :value="updateExists" :timeout="-1" color="secondary">
      <v-row align="center">
        <v-col cols="auto">
          <v-icon x-large>mdi-reload-alert</v-icon>
        </v-col>
        <v-col>
          <span class="text-h4">
            {{ $vuetify.lang.t("$vuetify.swUpdate.text0") }}
          </span>
          <br />
          <span>
            {{ $vuetify.lang.t("$vuetify.swUpdate.text1") }}
          </span>
        </v-col>
        <v-col cols="auto">
          <v-btn outlined color="primary" @click="refreshApp">
            {{ $vuetify.lang.t("$vuetify.swUpdate.reload") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "./components/appComponents/AppBar";
import Splash from "./components/appComponents/Splash";
import AppDrawer from "./components/appComponents/AppDrawer";
import Encoder from "./components/videoUpload/Encoder";
import AppSnackbar from "./components/appComponents/AppSnackbar";
import CookieSetting from "./components/dialogs/CookieSetting";
import AppFooter from "./components/appComponents/AppFooter";
import { init, initAnalytics } from "./firebase/firebase";
import update from "./mixins/update";

export default {
  name: "App",

  components: {
    AppBar,
    AppSnackbar,
    Splash,
    AppDrawer,
    Encoder,
    CookieSetting,
    AppFooter,
  },
  mixins: [update],

  data: () => ({}),
  beforeDestroy() {},
  async mounted() {
    document.title = this.$vuetify.lang.t("$vuetify.application_name");
    window.localStorage.setItem("analyticsIsCurrentlyEnabled", "false");
    this.handleCookieSettings();

    init(this);
  },
  methods: {
    handleCookieSettings() {
      var settingsString = window.localStorage.getItem("cookieSetting");
      if (settingsString != null) {
        var settings = JSON.parse(settingsString);
        if (settings.analytics.permission) {
          this.enableAnalytics();
        }
      } else {
        this.$store.commit("showCookieDialog");
      }
    },
    enableAnalytics() {
      window.localStorage.setItem("analyticsIsCurrentlyEnabled", "true");
      initAnalytics();
    },
  },
  watch: {
    user: function (newVal, oldVal) {
      if (oldVal === "NOT-YET-LOADED") {
        this.$store.commit("decreaseSplashCount");
      }
      if (!newVal) {
        this.$router.push({ name: "login" });
      }
    },
    cookieDialogIsShown: function (newVal) {
      this.show = newVal;
      if (!newVal) {
        //on cookie window closed
        var settingsString = localStorage.getItem("cookieSetting");
        if (settingsString != null) {
          var settings = JSON.parse(settingsString);
          if (settings.analytics.permission) {
            var currentLyEnabled = localStorage.getItem(
              "analyticsIsCurrentlyEnabled"
            );
            if (currentLyEnabled != null && currentLyEnabled !== "true") {
              this.enableAnalytics();
            }
          }
          //if analytics get disabled to hole page gets reloaded, no need to handle it here
        }
      }
    },
  },
  computed: {
    splash() {
      return this.$store.getters.showSplash;
    },
    user() {
      return this.$store.getters.user;
    },
    cookieDialogIsShown() {
      return this.$store.getters.isCookieDialogShown;
    },
  },
};
</script>

<style lang="scss">
@import "./styles/variables.scss";

html {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: var(--v-background-base);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 10px;
  }
}
</style>


