<template >
  <v-tooltip
    :disabled="!(cat && cat.description)"
    bottom
    v-if="!cat.isHidden || showAll"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        :dark="dark"
        v-bind="attrs"
        v-on="on"
        class="py-1 px-0"
        :light="light"
        :color="color"
        active-class="chip"
        :style='(cat && cat.isHidden) ? "opacity: 0.3;" : ""'
      >
        <v-container>
          <v-row no-gutters>
            <v-col cols="auto">
              <div>
                <v-icon left size="18">
                  {{ cat && cat.icon ? cat.icon : "mdi-car" }}
                </v-icon>
                <span class="text-caption">
                  {{
                    cat
                      ? cat.text
                        ? cat.text
                        : $vuetify.lang.t(
                            "$vuetify.manageVideos.table.lesson_error"
                          )
                      : ""
                  }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-chip>
    </template>
    <span class="text-caption">
      {{ cat ? (cat.description ? cat.description : "") : "" }}</span
    >
  </v-tooltip>
</template>

<script>
export default {
  name: "CategoryChip",
  props: {
    category: {
      type: Object,
      required: false,
    },
    dark: {
      type: Boolean,
      required: false,
    },
    light: {
      type: Boolean,
      required: false,
    },
    categoryKey: {
      type: String,
      required: false,
    },
    showAll: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      cat: {},
    };
  },
  mounted() {
    if (this.category) {
      this.cat = this.category;
    } else {
      if (this.categoryKey) {
        this.cat = this.lessons[this.categoryKey];
      }
    }
  },
  computed: {
    lessons() {
      return this.$store.getters.lessons;
    },
    color() {
      if (
        this.cat &&
        this.cat.color &&
        this.cat.color.dark &&
        this.cat.color.light
      ) {
        return this.isDark ? this.cat.color.dark : this.cat.color.light;
      } else {
        return this.isDark ? "#555555" : "#e0e0e0";
      }
    },
    isDark() {
      if (this.light) return false;
      if (this.dark) return true;
      return this.$vuetify.theme.dark;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.v-chip {
  opacity: 0.8;
  height: 23px;
}
</style>
