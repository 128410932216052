<template>
  <v-container class="lessons">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <div class="text-h3 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.lessons.header") }}
          </div>
          <div class="text--secondary mb-3">
            {{ $vuetify.lang.t("$vuetify.lessons.description") }}
          </div>
          <v-row
            align="center"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'lesson-list'"
          >
            <v-col cols="auto">
              <div class="headline">
                {{ $vuetify.lang.t("$vuetify.lessons.all") }}
              </div>
            </v-col>
            <v-col
              class="ml-auto"
              :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'"
            >
              <v-btn
                rounded
                block
                color="primary"
                @click="openEditDialog(null)"
                :loading="loadingState.newLesson"
                class="mt-2"
              >
                <v-icon left> mdi-plus </v-icon>
                {{ $vuetify.lang.t("$vuetify.lessons.button.new") }}
              </v-btn>
            </v-col>
          </v-row>
          <div
            :class="$vuetify.breakpoint.smAndDown ? '' : 'lesson-list'"
            v-if="all.lessons.length > 0"
          >
            <v-row>
              <v-col>
                <v-list flat>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, index) in all.lessons"
                      :ripple="false"
                      :key="item.text"
                      inactive
                      two-line
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-on="on"
                                v-bind="attrs"
                                style="display: inline"
                              >
                                <span>
                                  {{
                                    $vuetify.lang.t(
                                      "$vuetify.lessons.linkedVideoCounter",
                                      item.linkedVideos
                                        ? item.linkedVideos.length
                                        : 0
                                    )
                                  }}
                                </span>
                                <v-icon small class="mb-1">
                                  mdi-help-circle
                                </v-icon>
                              </div>
                            </template>
                            {{
                              $vuetify.lang.t(
                                "$vuetify.lessons.listLinkes",
                                item.text
                              )
                            }}
                            <br />
                            <span
                              v-for="video in item.linkedVideos"
                              v-bind:key="video"
                            >
                              {{ video }}
                              <br />
                            </span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              @click="changeOrder(index, index - 1)"
                              :disabled="index === 0"
                            >
                              <v-icon>mdi-arrow-up-bold</v-icon>
                            </v-btn>
                          </template>
                          {{
                            $vuetify.lang.t(
                              "$vuetify.lessons.button.up",
                              item.text
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              @click="changeOrder(index, index + 1)"
                              :disabled="index === all.lessons.length - 1"
                            >
                              <v-icon>mdi-arrow-down-bold</v-icon>
                            </v-btn>
                          </template>
                          {{
                            $vuetify.lang.t(
                              "$vuetify.lessons.button.down",
                              item.text
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              :disabled="!item.linkedVideos"
                              @click="openDialog(item)"
                            >
                              <v-icon color="blue"
                                >mdi-swap-vertical-bold</v-icon
                              >
                            </v-btn>
                          </template>
                          {{
                            $vuetify.lang.t(
                              "$vuetify.lessons.button.linkedOrder",
                              item.text
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              @click="openEditDialog(item)"
                            >
                              <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          {{
                            $vuetify.lang.t(
                              "$vuetify.lessons.button.edit",
                              item.text
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              @click="deleteItem(index)"
                            >
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          {{
                            $vuetify.lang.t(
                              "$vuetify.lessons.button.delete",
                              item.text
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </div>
          <div class="text--secondary mt-2" v-else>
            {{
              loadingState.loadingLessons
                ? $vuetify.lang.t("$vuetify.lessons.loading")
                : loadingError
                ? $vuetify.lang.t("$vuetify.lessons.error")
                : $vuetify.lang.t("$vuetify.lessons.empty")
            }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ChangeOrderDialog
      :show.sync="dialog.show"
      :lesson="dialog.lesson"
      :returnList.sync="dialog.returnList"
    >
    </ChangeOrderDialog>
    <EditCategory
      :show.sync="editCategoryDialog.show"
      :category.sync="editCategoryDialog.category"
      :status.sync="editCategoryDialog.status"
    >
    </EditCategory>
  </v-container>
</template>

<script>
import { deleteAllLessonLinks } from "../../firebase/linkHelpers";
import { db } from "./../../firebase/firebase";
import { generateKey } from "./../../util/nanoid";
import ChangeOrderDialog from "./../dialogs/ChangeLinkedVideosOrder";
import EditCategory from "./../dialogs/EditCategory";

export default {
  name: "lessons",
  components: {
    ChangeOrderDialog,
    EditCategory,
  },
  mounted() {
    this.loadData();
  },
  data: function () {
    return {
      all: {
        lessons: [],
      },
      isValid: false,
      loadingError: false,
      loadingState: {
        loadingLessons: false,
        newLesson: false,
      },
      dialog: {
        show: false,
        lesson: {},
        returnList: [],
      },
      editCategoryDialog: {
        show: false,
        category: {},
        status: "",
      },
    };
  },
  watch: {
    "editCategoryDialog.show": function name(newVal, oldVal) {
      if (oldVal && !newVal) {
        //edit category closed
        if (this.editCategoryDialog.status === "changed") {
          this.updateLesson(this.editCategoryDialog.category);
        }
        if (this.editCategoryDialog.status === "created") {
          this.createLesson(this.editCategoryDialog.category);
        }
      }
    },
  },
  methods: {
    changeOrder(from, to) {
      this.setLoading("loadingLessons");
      this.all.lessons.splice(to, 0, this.all.lessons.splice(from, 1)[0]);
      this.updateIndexes();
      this.removeLoading("loadingLessons");
    },
    async loadData() {
      this.setLoading("loadingLessons");
      try {
        var temp = (await db.ref("lessons/").once("value")).val();

        // convert to array and sort by index
        if (temp) {
          temp = Object.values(temp);
          temp.sort((a, b) => a.index - b.index);
        } else {
          temp = [];
        }

        this.all.lessons = temp;
      } catch (error) {
        console.error("Error during loading lessons");
        console.error(error);
        this.loadingError = true;
        this.$store.dispatch(
          "alertError",
          this.$vuetify.lang.t("$vuetify.lessons.error")
        );
      } finally {
        this.removeLoading("loadingLessons");
      }
    },
    async createLesson(lesson) {
      this.setLoading("newLesson");
      const newLesson = {
        text: lesson.text,
        icon: lesson.icon,
        color: lesson.color,
        key: generateKey(),
        index: this.all.lessons.length,
        linkedVideos: [],
        createdBy: this.user.name,
      };
      if (lesson.showInDrawer) newLesson["showInDrawer"] = true
      if (lesson.isHidden) newLesson["isHidden"] = true
      if (lesson.isHiddenInAllCategories) newLesson["isHiddenInAllCategories"] = true
      if (lesson.parent && lesson.showInDrawer) newLesson["parent"] = lesson.parent
      else delete newLesson.parent

      await db.ref("lessons/" + newLesson.key).set(newLesson);
      this.all.lessons.push(newLesson);

      this.updateAppCache();
      this.removeLoading("newLesson");
    },
    async updateLesson(lesson) {
      this.setLoading("newLesson");
      const lessonToChange = this.all.lessons[lesson.index];

      lessonToChange["text"] = lesson.text;
      lessonToChange["icon"] = lesson.icon;
      lessonToChange["color"] = lesson.color;
      if (lesson.showInDrawer) lessonToChange["showInDrawer"] = true
      else delete lessonToChange.showInDrawer
      if (lesson.isHidden) lessonToChange["isHidden"] = true
      else delete lessonToChange.isHidden
      if (lesson.isHiddenInAllCategories) lessonToChange["isHiddenInAllCategories"] = true
      else delete lessonToChange.isHiddenInAllCategories
      if (lesson.parent && lesson.showInDrawer) lessonToChange["parent"] = lesson.parent
      else delete lessonToChange.parent

      await db.ref("lessons/" + lessonToChange.key).set(lessonToChange);

      this.updateAppCache();
      this.removeLoading("newLesson");
    },
    async deleteItem(index) {
      const result = await this.$confirm(
        this.$vuetify.lang.t(
          "$vuetify.lessons.confirm_delete",
          this.all.lessons[index].text
        )
      );
      if (result) {
        this.setLoading("loadingLessons");
        await deleteAllLessonLinks(this.all.lessons[index]);
        await db.ref("lessons/" + this.all.lessons[index].key).set({});
        this.all.lessons.splice(index, 1);
        this.updateIndexes();
        this.removeLoading("loadingLessons");
      }
    },
    updateIndexes() {
      for (let i = 0; i < this.all.lessons.length; i++) {
        if (this.all.lessons[i].index !== i) {
          this.all.lessons[i].index = i;
          db.ref("lessons/" + this.all.lessons[i].key).update(
            this.all.lessons[i]
          );
        }
      }

      this.updateAppCache();
    },
    updateAppCache() {
      const ret = {};
      //array to object
      for (let i = 0; i < this.all.lessons.length; i++) {
        ret[this.all.lessons[i].key] = this.all.lessons[i];
      }
      this.$store.commit("setLessons", ret);
    },
    setLoading(set) {
      this.loadingState[set] = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading(set) {
      this.loadingState[set] = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
    openDialog(item) {
      this.dialog.lesson = item;
      this.dialog.returnList = [];
      this.dialog.show = true;
    },
    openEditDialog(item) {
      this.editCategoryDialog.category = item ? item : {};
      this.editCategoryDialog.status = "false";
      this.editCategoryDialog.show = true;
    },
  },
  computed: {
    newLessonNameRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.length >= 3) ||
          this.$vuetify.lang.t("$vuetify.validation.atLeastChar", 3),
        (v) =>
          (v && v.length <= 50) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 50),
        (v) =>
          (v &&
            !v.includes(".") &&
            !v.includes("/") &&
            !v.includes(",") &&
            !v.includes(":")) ||
          this.$vuetify.lang.t("$vuetify.validation.notAllowed"),
      ];
    },
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";

.lessons {
  .lesson-list {
    width: 60%;
  }
}
</style>