
const state = {
    lessons: {},
    videoMetadataAll: {},
    videoMetaDataOnlyVisible: {},
    userData: {},
    playList: [],
    history: [],
    mustWatches: [],
}

const mutations = {
    setLessons: (state, lessons) => {
        if (lessons) {
            state.lessons = lessons;
        }else {
            state.lesson = {}
        }
    },
    updateOneLesson: (state, lesson) => {
        if (lesson) {
            state.lessons[lesson.key] = lesson
        }
    },
    setVideoMetaData: (state, metaData) => {
        if (metaData) {
            state.videoMetadataAll = metaData;
            state.videoMetaDataOnlyVisible = {}
            for (const videoName in metaData) {
                if (metaData[videoName].isVisible) {
                    state.videoMetaDataOnlyVisible[videoName] = metaData[videoName]
                }
            }
        }else{
            state.videoMetaData = {}
            state.videoMetaDataOnlyVisible = {}
        }
    },
    updateOneVideo: (state, videoData) => {
        state.videoMetadataAll[videoData.name] = videoData;
        delete state.videoMetaDataOnlyVisible[videoData.name]
        if (videoData.isVisible) {
            state.videoMetaDataOnlyVisible[videoData.name] = videoData;
        }
    },
    setUserData: (state, userData) => {
        if (userData) {
            state.userData = userData
            if (userData.watched) {
                const temp = Object.values(userData.watched);
                //newest first
                temp.sort((a, b) => b.timestamp - a.timestamp);
                state.history = temp
            }
        }else {
            state.userData = {}
            state.history = []
        }
    },
    addOneVideoToHistory: (state, data) => {
        if (!state.userData.watched) {
            state.userData = { watched: {} }
        }
        state.userData.watched[data.video] = data
        const temp = Object.values(state.userData.watched);
        temp.sort((a, b) => b.timestamp - a.timestamp);
        state.history = temp
        //state.history.unshift(data)
    },
    setPlaylist: (store, playListArray) => {
        if (playListArray) {
            store.playList = playListArray
        }else {
            store.playList = []
        }
    },
    setMustWatches: (store, mustWatches) => {
        if (mustWatches){
            store.mustWatches = mustWatches
        }else {
            store.mustWatches = []
        }
    }
}


const getters = {
    lessons: state => {
        return state.lessons
    },
    videoMetaData: (state, filterOnlyVisible = true) => {
        if (!filterOnlyVisible) {
            return state.videoMetadata
        }
        return state.videoMetaDataOnlyVisible
    },
    userData: state => {
        return state.userData
    },
    playlist: state => {
        return state.playList
    },
    history: state => {
        return state.history
    },
    mustWatches: state => {
        return state.mustWatches
    }
}

export default {
    state,
    mutations,
    getters
}