import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticStyle:{"align-self":"flex-end"},attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","align":"center"}},[_c(VImg,{attrs:{"contain":"","max-width":"500","src":this.$vuetify.theme.dark
            ? require('../../assets/udrive_logo_neg1_3-f_rgb_gray.svg')
            : require('../../assets/udrive_logo_pos_3-f_rgb.svg')}})],1)],1),_c(VRow,{staticClass:"mt-2",staticStyle:{"align-self":"flex-start"},attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","align":"center"}},[_c(VProgressCircular,{attrs:{"size":100,"width":10,"color":"primary","indeterminate":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }