import { arrayRemove } from '../util/util';
import {  db, } from './firebase';
 
/**
 * Updates the linked videos of the lessons
 * @param {string} videoName the unique name of the video that lesson has changed
 * @param {string[]} oldLessonsKeys all old Lessons, nullable
 * @param {string[]} newLessonsKeys all new Lessons, nullable,
 * @param {object} vueInstance the vue instance, needed to update to appCache
 */
export async function updateLinkedVideos(videoName, oldLessonsKeys, newLessonsKeys, vueInstance) {
    if (!oldLessonsKeys) oldLessonsKeys = []
    if (!newLessonsKeys) newLessonsKeys = []

    // using object instead of array to prevent duplicates
    const allUpdatedLessons = {}
    
    //1st get all lessons
    const allLessons = (await db.ref("lessons/").once("value")).val()

    //2st remove all old links (that are not also in the new ones)
    for(let i  = 0; i < oldLessonsKeys.length; i++) {
        //also in newLessons? -> ignore it
        if (!newLessonsKeys.includes(oldLessonsKeys[i])){
            arrayRemove(allLessons[oldLessonsKeys[i]].linkedVideos, videoName, true)
            allUpdatedLessons[oldLessonsKeys[i]] = true;
        }
    }

    //3rd add the new ones (that are not also in the old ones)
    for(let i  = 0; i < newLessonsKeys.length; i++) {
        //also in the oldLessons? -> ignore it
        if (!oldLessonsKeys.includes(newLessonsKeys[i])){
            if(!allLessons[newLessonsKeys[i]].linkedVideos){
                allLessons[newLessonsKeys[i]].linkedVideos = []
            }
            allLessons[newLessonsKeys[i]].linkedVideos.push(videoName)
            allUpdatedLessons[newLessonsKeys[i]] = true;
        }
    }

    //4th update db and appCache
    for (var x in allUpdatedLessons) {
        if(allLessons[x].linkedVideos){
            allLessons[x].linkedVideos = allLessons[x].linkedVideos.filter(n => n)
            await db.ref("lessons/"+x+"/linkedVideos").set(allLessons[x].linkedVideos)
            vueInstance.$store.getters.lessons[x].linkedVideos = allLessons[x].linkedVideos
        }
    }
}

/**
 * deletes all occurrences of the given lesson in all videos
 * @param {*} lesson 
 */
export async function deleteAllLessonLinks(lesson){
    if( !lesson.linkedVideos){
        lesson.linkedVideos = []
    }

    for(let i = 0; i < lesson.linkedVideos.length; i++){
        //get lesson array
        const allLessons = (await db.ref("videos/"+lesson.linkedVideos[i]+"/lessons").once("value")).val()

        //remove this lesson
        arrayRemove(allLessons, lesson.key, true)

        //save inside the db
        await db.ref("videos/"+lesson.linkedVideos[i]+"/lessons").set(allLessons)
    }
}