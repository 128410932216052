import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VSheet,{staticClass:"video-chip rounded-br-xl",attrs:{"shaped":"","outlined":"","width":_vm.width,"min-width":_vm.minWidth,"max-height":_vm.maxWidth,"v-ripple":!_vm.hasError,"elevation":_vm.hasError ? 0 : hover ? 8 : 0},on:{"click":function($event){return _vm.actionClick(_vm.onClick)}}},[(_vm.progress)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VProgressLinear,{attrs:{"value":_vm.progress}})],1)],1):_vm._e(),_c(VRow,{staticClass:"pa-1",attrs:{"align":"center","no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"left":"","right":""}},[_vm._v(_vm._s(_vm.hasError ? "mdi-alert-circle-outline" : _vm.alreadyWatched ? "mdi-movie-open-check-outline" : "mdi-movie-open-outline"))])],1)],1),(!_vm.noChips)?_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold text--secondary caption"},[_vm._v(" "+_vm._s(_vm.videoObject.durationString ? _vm.videoObject.durationString : "")+" ")])])],1):_vm._e()],1),_c(VCol,[_c(VRow,{attrs:{"no-gutters":"","align":"start","justify":"center"}},[_c(VCol,[_c('span',{staticClass:"text-body-2 video-name"},[_vm._v(" "+_vm._s(_vm.videoObject.displayName)+" ")])]),(_vm.noChips)?_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold text--secondary caption ml-2 mr-1"},[_vm._v(" "+_vm._s(_vm.videoObject.durationString ? _vm.videoObject.durationString : "")+" ")])]):_vm._e()],1),(!_vm.hideLessons)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},_vm._l((_vm.videoObject.lessons),function(lesson){return _c('CategoryChip',{key:lesson,staticClass:"mt-1 mr-1",attrs:{"categoryKey":lesson}})}),1)],1):_vm._e()],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }