import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"elevation-8 pa-4"},[_c('div',{class:(_vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.history.header"))+" ")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.history.description"))+" ")]),_c(VRow,{attrs:{"justify":"space-around","no-gutters":""}},[_c(VCol,{staticClass:"mt-6",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 9 : undefined}},[_vm._l((_vm.displayedVideos),function(item){return _c(VRow,{key:item.video,attrs:{"align":"center","dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(new Date(item.timestamp).toLocaleString(undefined, { dateStyle: "short", }) + " " + new Date(item.timestamp).toLocaleString(undefined, { timeStyle: "short", }))+" ")])]),_c(VCol,[_c('VideoChip',{staticClass:"ml-3",attrs:{"videoName":item.video,"onClick":_vm.playVideo}})],1)],1)}),(_vm.history.length === 0)?_c('div',{staticClass:"empty-state-box text--secondary mt-5"},[_c('span',{staticClass:"text-h1 mt-4"},[_vm._v("👀")]),_c('span',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.history_empty_1"))+" ")]),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.history_empty_2"))+" ")])]):_vm._e()],2)],1),_c(VRow,[_c(VCol,[_c(VPagination,{attrs:{"length":_vm.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }