import { func } from "./firebase";

/**
 * calls a cloud function
 * @param {String} the name of the cloud function
 * @param {*} params of the cloud function
 * @returns the promis of the function, call result.data to get the result
 */
export async function execCloudFunction(name, params) {
    const httpsCallable = func.httpsCallable(name);
    return httpsCallable(params);
}
