<template>
  <div class="video-container">
    <video
      ref="video"
      controls
      autoplay
      :src="!!playURL ? playURL : undefined"
      class="video"
    ></video>
  </div>
</template>

<script>
//import { getDownloadUrl } from "../../firebase/storage";
import { logUserOut, isTokenValid } from "../../firebase/authStudent";
import { getToken } from "../../firebase/firebase";
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: "",
    },
    /*  playbackState: {
        isPlaying: false,
        duration: -1,
        currentTime: -1,
        hasEnded: false,
        watchTime: 0}, */
    playbackState: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.url !== "") {
      this.stripFirebasePath();
      this.setup();
    }

    //interval that count the watch time, if a video is played
    this.interval = setInterval(this.updateWatchTime, 1000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  data: function () {
    return {
      lastTimeUpdate: 0,
      canPlayThrough: false,
      firebaseVideo: "",
      interval: null,
      playURL: "",
    };
  },
  watch: {
    url: async function () {
      this.checkToken();

      if (this.url !== "") {
        this.stripFirebasePath();
        await this.setup();
      }

      this.lastTimeUpdate = 0;
      this.canPlayThrough = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    functionUrl() {
      if (location && location.hostname === "localhost") {
        return "http://localhost:5001/ulli-deine-videos/europe-west1/changePlaylist/";
      } else {
        return "https://europe-west1-ulli-deine-videos.cloudfunctions.net/changePlaylist/";
      }
    },
  },
  methods: {
    async setup() {
      this.$store.commit("increaseGlobalLoadingState");
      const vm = this;
      let video = this.$refs["video"];
      this.playURL =
        this.functionUrl +
        "playlist/master/" +
        this.firebaseVideo +
        "?token=" +
        (await getToken()) +
        (location && location.hostname === "localhost"
          ? "&use_localhost=true"
          : "");

      console.log(this.playURL)

      // save volume setting in local storage
      if (localStorage.getItem("volume")) {
        video.volume = localStorage.getItem("volume");
      }

      //add event listener
      video.onvolumechange = function () {
        localStorage.setItem("volume", video.volume);
      };
      video.onplay = function () {
        vm.playbackState.isPlaying = true;
        vm.playbackState.hasEnded = false;
        vm.updatePlaybackState();
        vm.$store.commit("decreaseGlobalLoadingState");
      };
      video.onpause = function () {
        vm.playbackState.isPlaying = false;
        vm.updatePlaybackState();
      };
      video.onended = function () {
        if (vm.canPlayThrough) {
          vm.playbackState.hasEnded = true;
          vm.updatePlaybackState();
        }
      };
      video.ondurationchange = function () {
        if (video.duration !== vm.playbackState.duration) {
          vm.playbackState.duration = video.duration;
          vm.updatePlaybackState();
        }
      };
      video.oncanplaythrough = function () {
        vm.canPlayThrough = true;
      };
      video.ontimeupdate = function () {
        //limit update rate to max. 2hz
        if (Math.abs(video.currentTime - vm.lastTimeUpdate) > 0.5) {
          vm.lastTimeUpdate = video.currentTime;
          vm.playbackState.currentTime = video.currentTime;
          vm.updatePlaybackState();
        }
      };

      //this.playURL = masterUrl;
      vm.resetplaybackState();
    },
    stripFirebasePath() {
      if (this.url.includes("(firebase)")) {
        const crop = this.url.substring(
          this.url.indexOf(")") + 1,
          this.url.length
        );
        if (crop.lastIndexOf("/") > 0) {
          this.firebaseVideo = crop.substring(0, crop.lastIndexOf("/"));
        }
        if (this.firebaseVideo.startsWith("videos/")) {
          this.firebaseVideo = this.firebaseVideo.substring(
            7,
            this.firebaseVideo.length
          );
        }
      }
    },
    updateWatchTime() {
      if (this.playbackState.isPlaying) {
        this.playbackState.watchTime += 1;
        this.updatePlaybackState();
      }
    },
    updatePlaybackState() {
      this.$emit("update:playbackState", this.playbackState);
    },
    resetplaybackState() {
      this.playbackState.isPlaying = false;
      this.playbackState.duration = -1;
      this.playbackState.currentTime = -1;
      this.playbackState.hasEnded = false;
      this.playbackState.watchTime = 0;
      this.updatePlaybackState();
    },
    checkToken() {
      if (!this.user.admin) {
        isTokenValid().then((result) => {
          if (!result) {
            logUserOut();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.video-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>