import { auth, db, setIsDuringSignIn, vueInstance } from "./firebase";
import { execCloudFunction } from "./cloudFunction";

/**
 * get the linked user object from the current logged in anonymous firebase account
 * Due to performance reasons we check the user at the client side. Every 2 days the validation musst me performed from the server side.
 * @returns: LINKED_ACC_NOT_FOUND: no account linked, or the linked one could not be found
 *           NOT_SIGNED_IN: currently is no firebase account signed in
 *           or the user object
 */
export async function getUser() {
    const user = auth.currentUser;
    let ret = null;

    if (!user) {
        //not signed in
        console.warn("getUser() call without a login!");
        return "NOT_SIGNED_IN";
    }

    //get token with custom claims
    await user.getIdToken(true);
    const token = await user.getIdTokenResult();
    try {
        //get user
        const userSnapshot = await db.ref("students/" + token.claims.userUUID).once("value");
        ret = userSnapshot.val();
        if (ret) {
            delete ret.code;
        } else {
            return "LINKED_ACC_NOT_FOUND";
        }
    } catch (error) {
        console.log(error);
        return "LINKED_ACC_NOT_FOUND";
    }

    //set lastLogin attribute in students/
    await db.ref("students/" + token.claims.userUUID).update({ lastLogin: new Date().getTime() });

    //check if the user have to validated on the server side
    if (token.claims.validUntil - new Date().getTime() < 1000 * 60 * 60 * 5) {
        //the user did not visited the website at least 7 days (small offset due to timezones)
        //but he is still allowed to visit the website -> let's validate the user now! and wait for it
        console.log("user not valid anymore, let's validate him");
        try {
            await execCloudFunction("validateAccount", {}).then(() => {
                user.getIdToken(true);
            });
        } catch (error) {
            console.error(error);
            console.error(error.message);
            return error.message;
        }
        await user.getIdToken(true);
    } else if (token.claims.validUntil - new Date().getTime() < 1000 * 60 * 60 * 24 * 5) {
        //the user did not visited the website at least 5 days
        //but he is still allowed to visit the website -> let's validate the user now! don't wait for the result bc the user is still valid
        console.log("user valid > 5 days, let's revalidate him");
        try {
            execCloudFunction("validateAccount", {}).then(() => {
                user.getIdToken(true);
            });
        } catch (error) {
            console.error(error);
            console.error(error.message);
            return error.message;
        }
    } else {
        console.log("user is more than 5 days valid");
    }
    return ret;
}

/**
 * logs the current user out and try to login a new user
 * calls the getUser method
 * @see getUser()
 * @param {string} codeInput the code that the user typed in
 * @returns: PW_NOT_FOUND: if the code was not found
 *           DB_ERROR: error during the request
 *           AUTH_ERROR: error during linking anonymous firebase account wih our user object
 *           LINKED_ACC_NOT_FOUND: no account linked, or the linked one could not be found
 *           or the user object
 */
export async function loginUser(codeInput) {
    setIsDuringSignIn(true);

    // make sure a user is logged in
    if (!auth.currentUser) {
        await auth.signInAnonymously();
    }

    try {
        const result = await execCloudFunction("loginUser", { pw: codeInput });
        //get token with custom claims
        const user = auth.currentUser;
        await user.getIdToken(true);
        await user.getIdTokenResult();
        setIsDuringSignIn(false);
        return result.data;
    } catch (error) {
        setIsDuringSignIn(false);
        console.error(error.message);
        return error.message;
    }
}

export async function isTokenValid() {
    const user = auth.currentUser;

    if (!user) {
        //not signed in
        return false;
    }

    try {
        await user.getIdToken(true);
        const token = await user.getIdTokenResult();

        var date = new Date();
        var nowUTC = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        if (token.claims.validUntil - nowUTC > 1000) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error during token check");
        console.error(error);
        return false;
    }
}

export async function logUserOut() {
    await auth.signOut();
    vueInstance.$store.dispatch("setUser", null);
}
