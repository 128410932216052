import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import { getUser } from "./authStudent";
import { calculatePlaylist } from "../util/playlist";

export let vueInstance = null;
export let db = null;
export let auth = null;
export let func = null;
export let storage = null;
export let isDuringSignIn = false;
export let DB_serverValue = null;

export function setIsDuringSignIn(newVal) {
    isDuringSignIn = newVal;
}

export function initAnalytics() {
    console.log("enable google analytics");
    firebase.analytics();
}

export function init(givenVueInstance) {
    vueInstance = givenVueInstance;
    db = firebase.database();
    auth = firebase.auth();
    storage = firebase.storage();
    func = firebase.app().functions("europe-west1");
    DB_serverValue = firebase.database.ServerValue;

    // if running on localhost, connect emulators
    if (location.hostname === "localhost") {
        db.useEmulator("localhost", 9000);
        auth.useEmulator("http://localhost:9099/");
        func.useEmulator("localhost", 5001);
        storage.useEmulator("localhost", 9199);
    }

    //register auth listener
    auth.onAuthStateChanged(async function (user) {
        if (user) {
            // User is signed in Anonymous -> get user object
            if (!isDuringSignIn && user.isAnonymous) {
                const userObject = await getUser();
                if (userObject && typeof userObject !== "string") {
                    //valid student user!
                    await loadAppCache(vueInstance, userObject.uuid);
                    vueInstance.$store.dispatch("setUser", userObject);
                } else if (userObject === "LINKED_ACC_NOT_FOUND") {
                    vueInstance.$emit("setUser", null);
                    //vueInstance.$store.dispatch("alertWarning", vueInstance.$vuetify.lang.t("$vuetify.login.notActiveAnymore"))
                    await auth.currentUser.delete();
                }
            } else if (!user.isAnonymous) {
                //a admin signed in
                console.log("user signed in as admin!");
                const adminObject = {
                    admin: true,
                    name: user.displayName,
                    email: user.email,
                    uuid: "admin-" + user.uid,
                    allowed: JSON.parse(await loadAllowedPaths(user.email)),
                };
                await loadAppCache(vueInstance, adminObject.uuid);
                vueInstance.$store.dispatch("setUser", adminObject);
                if (vueInstance.$route.path === "/admin") {
                    vueInstance.$router.push({ name: "admin.students" });
                }
            }
        } else {
            // No user is signed in.
            console.log("user signed out!");
            vueInstance.$store.dispatch("setUser", null);
        }
    });
}

async function loadAllowedPaths(adminEmail) {
    var email = adminEmail.replace("@", "(");
    email = email.replace(".", ")");
    return await (await db.ref("trustedEmails/" + email + "/").once("value")).val();
}

export async function getToken() {
    try {
        return auth.currentUser.getIdToken();
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function loadAppCache(vueInstance, uuid) {
    //load app cache
    vueInstance.$store.commit("increaseSplashCount");
    try {
        const results = await Promise.all([
            db.ref("videos/").once("value"),
            db.ref("lessons/").once("value"),
            db.ref("userData/" + uuid).once("value"),
            db.ref("mustWatches/").once("value"),
        ]);
        vueInstance.$store.commit("setVideoMetaData", results[0].val());
        vueInstance.$store.commit("setLessons", results[1].val());
        vueInstance.$store.commit("setUserData", results[2].val());
        vueInstance.$store.commit("setPlaylist", calculatePlaylist(vueInstance));
        vueInstance.$store.commit("setMustWatches", results[3].val());
        console.log("app cache loaded!");
    } catch (error) {
        console.error(error);
        vueInstance.$store.dispatch("alertError", vueInstance.$vuetify.lang.t("$vuetify.appCache_error"));
    } finally {
        vueInstance.$store.commit("decreaseSplashCount");
    }
}
