import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(Vuetify);



/* 
    import language files and merge our own translations with the existing vuetify ones.
    Note: is it possible to override the vuetify ones!
*/
import en_vuetify from "vuetify/es5/locale/en";
import de_vuetify from "vuetify/es5/locale/de";
import lang from "./../lang";
const en = Object.assign({}, en_vuetify, lang.messages.en);
const de = Object.assign({}, de_vuetify, lang.messages.de);
const locales = { en, de };

/* 
    For now hard-code german as language until english is fully supported!
*/
// let browserLang = String(navigator.language)
//     .toLowerCase()
//     .trim();
// if (browserLang.startsWith("de-")) {
//     browserLang = "de";
// } else if (browserLang.startsWith("en-")) {
//     browserLang = "en";
// }
let browserLang = "de";

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: locales,
    current: browserLang,
  },
  theme: {
    options: { customProperties: true },
    dark: localStorage.getItem("darkmode") === "true",
    themes: {
      light: {
        primary: '#57A432',
        secondary: '#373736',
        background: '#FFFFFF'
      },
      dark: {
        primary: '#458328',
        secondary: '#9a9a9a',
        background: '#121212'
      },
    },
  },
});

/* 
    configure vuetify confirm
    for usage see: https://github.com/yariksav/vuetify-confirm#usage
*/
Vue.use(VuetifyConfirm, {
  vuetify,
  icon: "mdi-alert",
  title: locales[browserLang].are_you_sure,
  buttonFalseText: locales[browserLang].cancel,
  buttonTrueText: locales[browserLang].yes,
  buttonTrueFlat: false,
  buttonTrueColor: 'warning'
});

export default vuetify;
