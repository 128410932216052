export default {
    messages: {
        de: {
            /** General **/
            application_name: "U - Drive",
            driving_school: " Ulli - deine Fahrschule",
            save: "Speichern",
            back: "Zurück",
            yes: "Ja",
            no: "Nein",
            cancel: "Abbrechen",
            search: "Suche",
            are_you_sure: "Bist du sicher?",
            logout: "Abmelden",
            never: "Noch nie",
            validation_failed: "Bitte fülle das gesamte Formular aus und versuche es erneut.",
            create: "Erstellen",
            update: "Aktualisieren",
            NOT_SIGNED_IN: "Hierfür musst du angemeldet sein 🙄.",
            ADMIN_NEEDED: "Das dürfen leider nur Admins, sorry 😎",
            api_error_unknown: "Unbekannter Serverfehler!",
            appCache_error: "Daten konnten nicht geladen werden, versuche es später erneut 🙄",
            request_error: "Anfrage konnte nicht gesendet werden! Bitte prüfe deine Internetverbindung.",
            footer: {
                privacy: "Datenschutz",
                legalNotice: "Impressum",
                cookie: "Cookie-Einstellungen",
            },
            validation: {
                notEmpty: "Darf nicht leer sein!",
                atLeastChar: "Mindestens {0} Zeichen benötigt!",
                maxChar: "Maximal {0} Zeichen erlaubt!",
                exactChar: "Muss genau {0} Zeichen enthalten!",
                maxFileSize: "Datei Größe darf {0} nicht überschreiten!",
                notAllowed: "Darf keine Sonderzeichen enthalten!",
            },
            login: {
                logout: "Abmelden",
                action: "Einloggen",
                instructor: "Login für Fahrlehrer",
                student: "Login für Fahrschüler:innen",
                as_student: "Als Fahrschüler:in einloggen",
                as_instructor: "Als Fahrlehrer einloggen",
                error_wrong_code: "Unbekannter Logincode",
                error_wrong_credentials: "Fehlerhafte Logindaten",
                info_student: "Du bekommst von deinem Fahrlehrer einen Zugangscode (Anmeldecode). Damit kannst du dich hier einloggen.",
                info_instructor:
                    "Sollten du als Fahrlehrer noch keinen Zugang besitzen, so wenden dich an Ole, der dir dann einen Zugang erstellen kann.",
                code: "Logincode",
                email: "E-Mail",
                password: "Passwort",
                toManyAttempts:
                    "Du hast dich scheinbar mehrmals falsch angemeldet. Falls du deinen Code vergessen hast, solltest du deine:n Fahrlehrer:in kontaktieren.",
                toManyAttemptsAdmin:
                    "Du hast dich scheinbar mehrmals falsch angemeldet. Falls du dein Passwort vergessen hast, kannst du dich bei Ole melden.",
                notActiveAnymore: "Sorry, dein Account wurde deaktiviert. Bist du noch fahrschüler? Wenn ja, melde dich bite bei der Fahrschule.",
                validation: {
                    code_empty: "Bitte einen gültigen Logincode eingeben",
                    code_length: "Ein Logincode hat genau 6 Zeichen",
                    email_empty: "Bitte eine E-Mail angeben",
                    email_format: "Bitte eine gültige E-Mail angeben",
                    password_empty: "Bitte ein Passwort angeben",
                    password_length: "Ein Passwort hat mindestens 6 Zeichen",
                },
            },
            drawer: {
                logged_in_as: "Eingeloggt als",
                darkMode: "Darkmode",
                heading: {
                    admin: "Admin Bereich",
                    student: "Aus Sicht der Fahrschüler:innen",
                    nav: "Navigation",
                    categories: "Kategorien",
                },
                admin: {
                    manageStudents: "Fahrschüler:innen verwalten",
                    upload: "Videos hochladen",
                    manageVideos: "Videos verwalten",
                    lessons: "Kategorien/Rei­hen­fol­ge verwalten",
                    mustWatches: "Must Watches verwalten",
                },
                students: {
                    videos: "Videos",
                    home: "Starseite",
                    history: "Verlauf",
                    all_videos_lesson: "Alle Kategorien",
                    all_videos: "Alle Videos",
                },
                settings: {
                    on: "Darkmode",
                    off: "Lightmode",
                    language: {
                        de: "Deutsch",
                        en: "Englisch",
                    },
                },
            },
            appBar: {
                search: "Suche",
                search_long: "Alle Videos durchsuchen",
                not_found: "Leider keine Videos gefunden...",
            },
            manageStudents: {
                header: "Fahrschüler:innen verwalten",
                students: "Alle bestehenden Accounts",
                new: {
                    header: "Neuen Account erstellen",
                    create: "Erstellen",
                    name: "Name des Fahrschülers",
                    code: "Logincode",
                    codeChanged:
                        "Ich hoffe, du weißt was du tust. Logincodes sollten nicht manuell bearbeitet werden. Nur in Ausnahmefällen, wenn z. B. zufällig der Code xxSEX1 generiert wurde. Und denke immer daran, dass man niemals von einem Logincode auf den anderen schließen können darf. Also nicht einfach eine Zahl hochzählen!",
                    UUID_EXISTS: "Die Schüler-ID gibt es bereits. Bitte versuche es erneut.",
                    LOGIN_CODE_EXISTS: "Den LoginCode gibt es bereits!",
                    created: 'Der Account "{0}" wurde erfolgreich erstellt und die Willkommensnachricht ist kopiert.',
                    welcomeMessage: 
`Lieber {0},
wir sind froh 😃 und auch ein kleines bisschen stolz 💪 dir heute die Zugangsdaten von unseren Fahrschul-Video-App mitteilen zu können 😃 

Mit dieser App wirst du im Zusammenspiel mit unserem Workbook Praxis in Zukunft noch entspannter dein Ziel, den Führerschein, erreichen 🚙

Die App beinhaltet folgende Funktionen:
✅ über 150 Videos von den wichtigsten Stellen aus Rotenburg
✅ die Strecke deiner nächsten Fahrstunde
✅ die komplette Erklärung für die Technikfragen vor der praktischen Prüfung
✅ alle Grund- bzw. Parkaufgaben

Wir sind überzeugt, dir damit das perfekte Tool für eine noch bessere Ausbildung an die Hand zugeben 😄

https://u-drive.app/


Deine Zugangsdaten: {1}`
                },
                all: {
                    name: "Name (bearbeitbar)",
                    createdAt: "Erstellt am",
                    lastLogin: "Letzter Seitenaufruf",
                    empty: "Noch kein Account angelegt 😲  Erstelle doch gleich einen!",
                    delete: 'Account "{0}" löschen',
                    editRecommendations: "Vorschläge für {0} bearbeiten",
                    confirm_delete: "Nach dem löschen kann {0} nicht mehr auf die Videos zugreifen, willst du das?",
                    delete_err_not_fount: "Der Account konnte nicht gefunden werden.",
                    delete_success: "{0}'s Account wurde gelöscht.",
                    edit: "Name bearbeiten",
                },
            },
            hls: {
                fatal_network: "Fataler Netzwerkfehler während der Videowiedergabe. 🙄",
                fatal_media: "Fataler Medienfehler während der Videowiedergabe. 🙄",
                fatal: "Fataler Fehler während der Videowiedergabe. 🙄",
            },
            native: {},
            videos: {
                resolution: "Auflösung",
                was_deleted: "Gelöschtes Video 🙄",
                resolution_chooser_text: "{0} x {1}",
                resolution_auto: "Automatisch",
                next_videos: "Weitere Videos:",
                autoPlay: "auto Play",
                next: "Als nächstes",
                no_next: "Kein nächstes Video verfügbar",
                autoPlay_help: "Wenn aktiviert, startet nach einer Wiedergabe automatisch das nächste Video.",
            },
            upload: {
                new: {
                    header: "Neues Video Hochladen",
                    warning:
                        "Das Hochladen über die Webseite dauert sehr lange! Ein 5 min Video kann 2,5 Stunden brauchen, da die Videos im Browser encodiert werden. Solltest du lange oder mehrere Videos hochladen wollen, gib sie lieber Ole, der hat bessere Tools dafür 😉",
                    name: "Name des Videos",
                    name_hint: "Bitte keinen Sonderzeichen verwenden",
                    name_help:
                        "Dieser Name ist für Fahrschüler nicht sichtbar. Der Anzeigename kann später unter 'Videos verwalten' festgelegt werden. Dieser Name dient zu eindeutigen identifikation innerhalb des Video-Speichers.",
                    header_process: "In Bearbeitung",
                    choose: "Video auswählen",
                    go: "Hochladen",
                    not_compatible:
                        "Dein Browser unterstützt scheinbar keine SharedArrayBuffer, damit das hochladen nicht möglich. Am Desktop funktionieren Chrome und Edge, alternativ kannst du die Videos auch Ole geben und der lädt sie hoch.",
                    progress: {
                        header: "Fortschritt",
                        steps: "Schritt {0} von {1}: {2}",
                        step: {
                            0: "Vorbereiten",
                            1: "Lokales encodieren, dieser Schritt dauert...",
                            2: "Ergebnisse einsammeln",
                            3: "Daten hochladen",
                        },
                        info: "Während der Verarbeitung des Videos kannst du die Seite weiterverwenden. Aber bitte schließe nicht den Browser bzw. den Tab",
                    },
                },
            },
            encoding: {
                error: "Fataler Fehler während der Videoverarbeitung, vorgang Abgebrochen.",
                done: 'Super! Das Video "{0}" wurde erfolgreich encodiert und hochgeladen.',
            },
            lessons: {
                header: "Kategorien",
                all: "Bestehende Fahrsunden",
                description:
                    "Hier kannst du Kategorien verwalten. Zu Kategorien können Videos zugewiesen werden. Die Reihenfolge ist wichtig, da die Videos in dieser Reihenfolge den Fahrschülern vorgeschlagen werden.",
                confirm_delete:
                    'Möchtest du die Kategorie "{0}" wirklich löschen? Bestehende Verknüpfungen zu Videos werden ebenfalls gelöscht, willst du fortfahren?',
                new: "Name der neuen Kategorie",
                empty: "Noch keine Kategorie angelegt...",
                new_name: "Neuer Name",
                loading: "Lade Kategorien...",
                error: "Fehler beim laden der Kategorien 🙄",
                linkedVideoCounter: "Mit {0} Videos verknüpft",
                listLinkes: 'Folgende Videos wurden mit "{0}" verknüpft: ',
                button: {
                    new: "Neue Kategorie erstelle",
                    up: '"{0}" eine Ebene nach "oben" verschieben',
                    down: '"{0}" eine Ebene nach "unten" verschieben',
                    linkedOrder: "Passe die Reihenfolge der verknüpften Videos an",
                    edit: '"{0}" bearbeiten.',
                    delete: '"{0}" löschen, Verknüpfungen zu Videos werden ebenfalls entfernt.',
                },
            },
            manageVideos: {
                header: "Videos verwalten",
                description: "Hier kannst du festlegen welche Videos sichtbar sind, wie sie heißen und mit welcher Kategorie sie verknüpft sind.",
                empty: "Oh Oh noch keine Videos hochgeladen, da eine Videoplattform ohne Videos wenig Sinn hat, lade doch direkt eins hoch.",
                delete: 'Video "{0}" löschen',
                edit: 'Video "{0}" bearbeiten',
                preview: "Preview abspielen",
                delete_error: 'Fehler beim Löschen des Videos "{0}"',
                deleted: 'Video "{0}" wurde erfolgreich gelöscht.',
                list_error: "Fehler beim laden der Videoliste",
                confirm_delete: 'Möchtest du das Video "{0}" wirklich löschen? Diese aktion ist unwiderruflich, fortfahren?',
                search: "In allen Videos suchen",
                table: {
                    name: "Videoname",
                    displayName: "Anzeigename",
                    duration: "Länge",
                    uploadedFrom: "Hochgeladen von",
                    uploadedAt: "Hochgeladen am",
                    views: "Aufrufe",
                    lessons: "Kategorien",
                    visible: "Sichtbar",
                    lesson_error: "Fehler 🙄",
                    tooltip: {
                        name_1: "Der Videoname dient als eindeutige Kennung innerhalb des Videospeichers, er darf keine Sonderzeichen enthalten.",
                        name_2: "Fahrschüler:innen werden diesen Namen nicht sehen.",
                        display: "Dies ist der Name unter dem das Video zu sehen sein wird, hier darfst du alle Zeichen verwenden, auch smileys 😜",
                        visible: "Gibt an ob das Video für Fahrschüler:innen sichtbar ist.",
                        empty: 'Achtung Videos ohne verknüpfter Kategorie werden den Fahrschülern nicht vorgeschlagen, da sie nicht in der automatischen playlist aufgenommen werden. Kurz: Dieses Video wird nur unter "alle Videos" zu finden sein!',
                    },
                },
            },
            editVideo: {
                header: 'Video "{0}" bearbeiten',
                visibility: "Sichtbarkeit für Fahrschüler:innen",
                confirm_hide: 'Fahrschuler:innen können das Video "{0}" nicht mehr anschauen, fortfahren?',
                validation: "Darf nicht leer sein, wenn das Video Sichtbar ist!",
                lessons: "Verknüpfte Kategorien",
                description: "Beschreibung",
            },
            home: {
                greeting: "Hallo {0}",
                next_1: "Einfach weiterschauen?",
                next_2: "Dies ist dein nächstes Video:",
                stats: {
                    1: "Du hast dir",
                    2: "von",
                    3: "Videos angeschaut, also",
                },
                must_watch: "Must Watch",
                must_sub_title: "Diese Videos solltest du dir nicht entgehen lassen",
                history: "Verlauf",
                history_sub_title: "Die letzten Videos, die du dir angesehen hast",
                next: "So geht es weiter",
                next_sub_title: "Vorgeschlagene Videos, basierend auf deinem Verlauf",
                show_all: "Alle anzeigen",
                next_empty: "Super! Du hast dir alle Videos angeschaut",
                history_empty_1: "Ziemlich leer hier...",
                history_empty_2: "Schau dir doch gleich dein erstes Video an",
                recommended: {
                    title: "Nur für dich",
                    sub_title: "Persönliche Vorschläge von deinem Fahrlehrer",
                },
            },
            changeOrderDialog: {
                header: "Reihenfolge der Videos ändern",
                description:
                    "Hier kannst du die Reihenfolge der Videos innerhalb einer Kategorie ändern. Dies ist sinnvoll, da die Videos in dieser Reihenfolge den Fahrschühlern vorgeschlagen werden.",
            },
            history: {
                header: "Verlauf",
                description:
                    "Hier siehst du wann du welches Video das letzte mal gesehen hast. Solltest du dir Videos mehrmals ansehen haben, wird lediglich der letzte Aufruf angezeigt.",
            },
            allVideosLesson: {
                header: "Kategorien",
                description: "Hier siehst du welche Videos zu welcher Kategorie zugeordnet sind.",
            },
            allVideos: {
                header: "Alle Videos",
                description:
                    "Hier findest du alle Videos in alphabetischer oder empfohlener Reihenfolge. Mit einem Harken markierte Videos hast du dir bereits angesehen.",
                sort: {
                    sortBy: "Sortieren nach",
                    alphabetically: "Alphabetischer Reihenfolge",
                    playlist: "Empfohlene Reihenfolge",
                },
            },
            mustWatches: {
                header: "Must Watches bearbeiten",
                description:
                    'Hier kannst festlegen welche Videos den Fahrschülern auf der Startseite unter der Rubrik "Must Watch" angezeigt werden.',
                add: "Videos hinzufügen",
                change: "Reihenfolge ändern",
                delete: "Video aus Must Watches entfernen",
            },
            cookie: {
                title1: "Auch wir verwenden Cookies,",
                title_small: "mhh, Cookies! 🍪",
                title2: "auch wir müssen fragen ob das OK ist!",
                description:
                    "Diese Webseite verwendet Cookies und andere Technologien, um deine Daten zu verarbeiten. Bist du damit einverstanden? Du kannst deine Einstellungen jederzeit unter 'Cookie-Einstellungen' ändern.",
                link1: "Weiter Informationen über Cookies gibt es  ",
                link2: "hier",
                link3: "oder im",
                disable: "Ablehnen und weiter",
                analytics_desc:
                    "Wir nutzen den Google-Dienst Google Analytics for Firebase, um zu verstehen, wie die Webseite genutzt wird und möglichst schnell Fehler beheben zu können. Google hat dabei Zugriff auf gewisse personbezogene Daten wie z.B. deine IP-Adresse. Diese werden auf Google-Serven verarbeitet, die sich außerhalb der EU befinden können. Solltest du diesen Punkt deaktivieren, wird dein verhalten auf dieser Seite nicht von Google analysiert.",
                analytics_title: "Google Analytics",
                essentials_desc:
                    "Daten, die verarbeitet werden müssen, damit der Betrieb der Webseite gewährleistet ist. Z.B. die Cookie-Einstellungen, die du gleich setzten wirst, Sitzungskodes oder ob du den Darkmode aktivierst hast.",
                essentials_title: "Essenziell",
                go: "Speichern und weiter",
                reloadInfo: "Seite wird nach dem speichern neu geladen, damit die Änderungen wirksam werden.",
            },
            editCategory: {
                title: "Kategorie {0} bearbeiten",
                title_new: "Neue Kategorie erstellen",
                text: "Name der Kategorie",
                description: "Beschreibung",
                preview: "Vorschau:",
                color: {
                    light: "Farbe im Light-Mode",
                    light_t: "Light-Mode:",
                    dark: "Farbe im Dark-Mode",
                    dark_t: "Dark-Mode:",
                },
                parent: {
                    title: "(optional) Oberkategorie: ",
                    name: "Name",
                },
                icon: "Symbol",
                icon_help1: "Möglichen Symbole findest du ",
                icon_help2: '. Schreibe in dieses Feld "mdi-" + den Namen des Icons.',
                icon_validation: 'Der Icon Name muss mit "mdi-" beginnen!',
                setting: {
                    title: "Einstellungen",
                    showInDrawer: "Diese Kategorie in der Navigation anzeigen",
                    isHidden: "Diese Kategorie NICHT am Video anzeigen",
                    isHiddenInAllCategories: 'Diese Kategorie NICHT in "Kategorien" listen',
                },
            },
            swUpdate: {
                text0: "Hey, super Nachrichten!",
                text1: "Eine neue Version von Ulli - deine Videos wurde heruntergeladen. Nach dem neu laden kannst du sie nutzen.",
                reload: "Neu laden",
            },
            editUserRecommendations: {
                title: "Vorschläge für {0} bearbeiten",
                selected: "Ausgewählt",
                all: "Alle Videos",
                select: "Video auswählen",
                delete: "Video aus Vorschlägen entfernen",
                empty: "Noch kein Video ausgewählt",
            },
        },
        en: {
            //todo translate
        },
    },
};
