<template>
  <v-navigation-drawer v-model="drawer.isExpanded" app clipped width="320">
    <v-list dense>
      <template v-for="(item, i) in drawerItems">
        <v-row v-if="item.heading" :key="i" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
          </v-col>
        </v-row>
        <v-divider v-else-if="item.divider" :key="i" class="my-2" />
        <v-list-group v-else-if="item.isParent" :key="i">
          <template v-slot:activator>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </template>
          <template v-slot:prependIcon>
            <v-list-item-action>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-action>
          </template>
          <v-list-item
            v-for="(subItem, ii) in item.items"
            :key="ii"
            link
            @click="goTo(subItem.routeName, subItem.params)"
          >
            <v-list-item-action>
              <v-icon color="primary">{{ subItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="i"
          link
          @click="goTo(item.routeName, item.params)"
        >
          <v-list-item-action>
            <v-progress-circular
              indeterminate
              v-if="item.loading"
              color="primary"
              width="3"
              size="20"
            ></v-progress-circular>

            <v-icon v-else color="primary">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider dark class="mb-3" />
      <v-container>
        <v-row v-if="user" align="center">
          <v-col cols="auto">
            <v-avatar size="46" color="primary">
              <span
                style="font-size: 22px"
                v-text="user.name[0] + user.name[1]"
              ></span>
            </v-avatar>
          </v-col>
          <v-col>
            <v-row>
              <span class="text--secondary caption">
                {{ $vuetify.lang.t("$vuetify.drawer.logged_in_as") }}
              </span>
            </v-row>
            <v-row>
              <span class="text--primary font-weight-medium caption">
                {{ user.admin ? user.email : user.name }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn rounded outlined v-on="on" @click="logout">
                  <v-icon>mdi-run</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.logout") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row align="center">
              <v-col cols="auto">
                {{ $vuetify.lang.t("$vuetify.drawer.darkMode") }}
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn-toggle
                  v-model="darkThemeSelector"
                  rounded
                  mandatory
                  color="primary"
                  dense
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on">
                        <v-icon>mdi-lightbulb</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t("$vuetify.drawer.settings.off")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on">
                        <v-icon>mdi-lightbulb-off</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t("$vuetify.drawer.settings.on")
                    }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { logUserOut } from "../../firebase/authStudent";

export default {
  data() {
    return {
      darkThemeSelector: localStorage.getItem("darkmode") === "true" ? 1 : 0,
      drawerItems: [],
      drawerItemAdmin: [
        { heading: this.$vuetify.lang.t("$vuetify.drawer.heading.admin") },
        {
          icon: "mdi-account-group",
          text: this.$vuetify.lang.t("$vuetify.drawer.admin.manageStudents"),
          routeName: "admin.students",
        },
        {
          icon: "mdi-video-account",
          loading: false,
          text: this.$vuetify.lang.t("$vuetify.drawer.admin.manageVideos"),
          routeName: "admin.manageVideos",
        },
        {
          icon: "mdi-shape",
          text: this.$vuetify.lang.t("$vuetify.drawer.admin.lessons"),
          routeName: "admin.categories",
        },
        {
          icon: "mdi-movie-open-star-outline",
          text: this.$vuetify.lang.t("$vuetify.drawer.admin.mustWatches"),
          routeName: "admin.mustWatches",
        },
        { divider: true },
        { heading: this.$vuetify.lang.t("$vuetify.drawer.heading.student") },
      ],
      drawerItemsStudent_1: [
        { heading: this.$vuetify.lang.t("$vuetify.drawer.heading.nav") },
        {
          icon: "mdi-home-account",
          text: this.$vuetify.lang.t("$vuetify.drawer.students.home"),
          routeName: "home",
        },
        {
          icon: "mdi-filmstrip-box-multiple",
          text: this.$vuetify.lang.t("$vuetify.drawer.students.all_videos"),
          routeName: "allVideos",
        },
        /*        {
          icon: "mdi-shape",
          text: this.$vuetify.lang.t(
            "$vuetify.drawer.students.all_videos_lesson"
          ),
          routeName: "allVideosLesson",
        },*/
        {
          icon: "mdi-history",
          text: this.$vuetify.lang.t("$vuetify.drawer.students.history"),
          routeName: "history",
        },
        { heading: this.$vuetify.lang.t("$vuetify.drawer.heading.categories") },
      ],
      dynamicItems: [], // <--- the automatic items (categories) are placed here
      drawerItemsStudent_2: [],
    };
  },
  mounted() {
    this.dynamicDrawerItems();
  },
  computed: {
    drawer() {
      return this.$store.getters.drawer;
    },
    user() {
      return this.$store.getters.user;
    },
    categories() {
      const temp = Object.values(this.$store.getters.lessons);
      temp.sort((a, b) => a.index - b.index);
      return temp;
    },
    isJobRunning() {
      return this.$store.getters.isJobRunning;
    },
    allowedAdminRoutes(){
      const user = this.user
      const ret = []
      if(user){
        this.drawerItemAdmin.forEach((item) => {
          if (item.routeName){
            if(user.allowed && user.allowed.includes(item.routeName)){
              ret.push(item)
            }
          }else{
            //divider and stuff should be allowed
            ret.push(item)
          }
        })
      }
      return ret
    }
  },
  watch: {
    isJobRunning: function (newVal) {
      this.drawerItems[2].loading = newVal;
    },
    darkThemeSelector: function (val) {
      if (val) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("darkmode", "true");
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("darkmode", "false");
      }
    },
    categories: {
      deep: true,
      handler() {
        this.dynamicDrawerItems();
      },
    },
  },
  methods: {
    goTo(routeName, params) {
      this.$router.push({ name: routeName, params: params });
    },
    async logout() {
      await logUserOut();
    },
    setItems() {
      if (this.user.admin) {
        this.drawerItems = [
          ...this.allowedAdminRoutes,
          ...this.drawerItemsStudent_1,
          ...this.dynamicItems,
          ...this.drawerItemsStudent_2,
        ];
      } else {
        this.drawerItems = [
          ...this.drawerItemsStudent_1,
          ...this.dynamicItems,
          ...this.drawerItemsStudent_2,
        ];
      }
    },
    dynamicDrawerItems() {
      const ret = [];
      if (this.categories) {
        const parents = new Map();
        const toShow = this.categories.filter((category) => {
          return category.showInDrawer;
        });
        for (const category of toShow) {
          const navItem = {
            icon: category.icon ? category.icon : "mdi-car-sports",
            text: category.text,
            routeName: "videosOfCategory",
            params: { categoryId: category.key },
          };

          if (category.parent && category.parent.name) {
            if (parents.get(category.parent.name)) {
              parents.get(category.parent.name).push(navItem);
            } else {
              parents.set(category.parent.name, [navItem]);
              ret.push({
                icon: category.parent.icon
                  ? category.parent.icon
                  : "mdi-car-sports",
                text: category.parent.name,
                isParent: true,
              });
            }
          } else {
            ret.push(navItem);
          }
        }
        for (const category of ret) {
          if (category.isParent) {
            category["items"] = parents.get(category.text);
          }
        }
      }
      this.dynamicItems = ret;
      this.setItems();
    },
  },
};
</script>

<style lang="scss" >
@import "./../../styles/variables.scss";

.v-list-item__action {
  margin-right: 20px !important;
}
.v-list-group__header__prepend-icon{
  margin-right: 15px !important;
}
</style>

