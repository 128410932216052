import { auth } from './firebase'
import { logUserOut } from './authStudent'

/**
 * try to log in a user
 * @param {string} email user input
 * @param {string} pw passwort user input 
 * @returns error string or user, possible returns:
 *          "PW_NOT_FOUND" -> wrong password
 *          "UNKNOWN_ERROR" -> some error
 */
export async function loginAdmin(email, pw) {
    //make sure nobody is signed in
    if (auth.currentUser) {
        await logUserOut();
    }

    var user = null
    try {
        user = await auth.signInWithEmailAndPassword(email, pw);
    } catch (error) {
        if (error && (error.code === "auth/wrong-password" || error.code === "auth/user-not-found")) {
            return "PW_NOT_FOUND"
        } else {
            console.log(error)
        }
        return "UNKNOWN_ERROR"
    }

    return user
}