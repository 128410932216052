<template>
  <v-dialog
    v-model="show"
    scrollable
    :persistent="hasChanges"
    max-width="1000px"
    @click:outside="softClose"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="elevation-8">
      <v-container class="lessons">
        <v-row justify="center" align="center">
          <v-col>
            <div class="text-h4 font-weight-light">
              {{ $vuetify.lang.t("$vuetify.changeOrderDialog.header") }}
            </div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-n5">
          <v-col>
            <div class="text--secondary">
              {{ $vuetify.lang.t("$vuetify.changeOrderDialog.description") }}
            </div>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-list flat>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in localVideoList"
                  :ripple="false"
                  :key="index"
                  inactive
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="changeOrder(index, index - 1)"
                          :disabled="index === 0"
                        >
                          <v-icon>mdi-arrow-up-bold</v-icon>
                        </v-btn>
                      </template>
                      {{ $vuetify.lang.t("$vuetify.lessons.button.up", item) }}
                    </v-tooltip>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="changeOrder(index, index + 1)"
                          :disabled="index === localVideoList.length - 1"
                        >
                          <v-icon>mdi-arrow-down-bold</v-icon>
                        </v-btn>
                      </template>
                      {{
                        $vuetify.lang.t("$vuetify.lessons.button.down", item)
                      }}
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-btn text @click="close">
              {{ $vuetify.lang.t("$vuetify.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              outlined
              color="primary"
              :loading="saving"
              :disabled="!hasChanges"
              @click="saveOrder"
            >
              <v-icon left>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { calculatePlaylist } from '../../util/playlist';
import { db } from "./../../firebase/firebase";

export default {
  name: "change-linked-video-order",
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    returnList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      hasChanges: false,
      localVideoList: [],
    };
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        //dialog opened
        this.init();
      }
    },
  },
  methods: {
    init() {
      this.hasChanges = false;
      this.saving = false;
      if (this.lesson.linkedVideos) {
        this.localVideoList = this.lesson.linkedVideos;
      } else {
        this.localVideoList = [];
      }
    },
    changeOrder(from, to) {
      this.onChange();
      this.localVideoList.splice(to, 0, this.localVideoList.splice(from, 1)[0]);
    },
    async saveOrder() {
      this.saving = true;

      await db
        .ref("lessons/" + this.lesson.key + "/linkedVideos")
        .set(this.localVideoList);
      const newLesson = this.lesson;
      newLesson.linkedVideos = this.localVideoList;
      this.$store.commit("updateOneLesson", newLesson);
      this.$emit("update:returnList", this.localVideoList);
      this.$store.commit("setPlaylist", calculatePlaylist(this));

      this.saving = false;
      this.close();
    },
    onChange() {
      this.hasChanges = true;
    },
    softClose() {
      if (!this.hasChanges) {
        this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
    },
  },
  computed: {},
};
</script>
