<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <div :class="($vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'">
            {{ $vuetify.lang.t("$vuetify.allVideos.header") }}
          </div>
          <div class="text--secondary">
            {{ $vuetify.lang.t("$vuetify.allVideos.description") }}
          </div>
          <v-row justify="space-around" class="mt-5">
            <v-col :cols="$vuetify.breakpoint.lgAndUp ? 9 : undefined">
              <v-row>
                <v-col>
                  <v-select
                    :items="sortItems"
                    v-model="sortBy"
                    :label="$vuetify.lang.t('$vuetify.allVideos.sort.sortBy')"
                    outlined
                    hide-details
                    item-value="key"
                    item-text="text"
                    class="mx-3"
                    prepend-inner-icon="mdi-sort"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon left>
                        {{ item.icon }}
                      </v-icon>
                      {{ item.text }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="videos">
                <v-col>
                  <VideoChip
                    v-for="video in displayedVideos"
                    v-bind:key="onlVideoNames ? video : video.name"
                    :videoName="onlVideoNames ? video : video.name"
                    :onClick="playVideo"
                    class="ma-3"
                  ></VideoChip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-pagination v-model="page" :length="length" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoChip from "./../appComponents/VideoChip";

export default {
  name: "AllVideos",
  components: {
    VideoChip,
  },
  data() {
    return {
      sortItems: [
        {
          key: "alphabetically",
          text: this.$vuetify.lang.t("$vuetify.allVideos.sort.alphabetically"),
          icon: "mdi-sort-alphabetical-ascending",
        },
        {
          key: "playlist",
          text: this.$vuetify.lang.t("$vuetify.allVideos.sort.playlist"),
          icon: "mdi-sort-bool-ascending-variant",
        },
      ],
      itemPerPage: 50,
      page: 1,
      sortBy: "alphabetically",
      videos: null,
      onlVideoNames: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    playlist() {
      return this.$store.getters.playlist;
    },
    sortedVideos() {
      const videos = Object.values(this.videoMetaData);
      videos.sort((a, b) => a.displayName.localeCompare(b.displayName));
      return videos;
    },
    displayedVideos() {
      if (this.videos) {
        const start = (this.page - 1) * this.itemPerPage;
        return this.videos.slice(start, start + this.itemPerPage);
      } else return [];
    },
    length() {
      if (this.videos) {
        const addOne = this.videos.length % this.itemPerPage != 0;
        const pageSize = Math.floor(this.videos.length / this.itemPerPage);
        if (addOne) return pageSize + 1;
        else return pageSize;
      } else return 1;
    },
  },
  watch: {
    sortBy: function (newVal) {
      if (newVal === "alphabetically") {
        this.videos = this.sortedVideos;
        this.onlVideoNames = false;
      }
      if (this.sortBy === "playlist") {
        this.videos = this.playlist;
        this.onlVideoNames = true;
      }
    },
  },
  methods: {
    init() {
      this.videos = this.sortedVideos;
    },
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
  },
};
</script>


