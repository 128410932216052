<template>
  <v-dialog
    v-model="show"
    :persistent="hasChanges"
    max-width="1000px"
    @click:outside="softClose"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="elevation-8 pa-3">
      <v-container>
        <v-row justify="center">
          <v-col class="text-h4 font-weight-light">
            {{
              isNew
                ? $vuetify.lang.t("$vuetify.editCategory.title_new")
                : $vuetify.lang.t(
                    "$vuetify.editCategory.title",
                    category ? category.text : ""
                  )
            }}
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close('canceled')">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="formCategory" v-model="isValid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                v-model="localCategory.text"
                @change="onChange"
                hide-details="auto"
                prepend-icon="mdi-shape"
                :rules="nameRules"
                :label="$vuetify.lang.t('$vuetify.editCategory.text')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                v-model="localCategory.description"
                @change="onChange"
                hide-details="auto"
                prepend-icon="mdi-text"
                :rules="descriptionRules"
                :label="$vuetify.lang.t('$vuetify.editCategory.description')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="6" cols="12">
              <v-row>
                <v-col>
                  <div>
                    {{ $vuetify.lang.t("$vuetify.editCategory.setting.title") }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    class="mt-0"
                    v-model="localCategory.showInDrawer"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.editCategory.setting.showInDrawer'
                      )
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense class="mb-3" v-if="localCategory.showInDrawer">
                <v-col cols="12" class="py-0">
                  <span>
                    {{$vuetify.lang.t('$vuetify.editCategory.parent.title')}}
                  </span>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field
                    hide-details="auto"
                    v-model="localCategory.parent.icon"
                    @change="onChange"
                    :rules="iconRulesParent"
                    :label="$vuetify.lang.t('$vuetify.editCategory.icon')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="8" class="py-0">
                  <v-text-field
                    v-model="localCategory.parent.name"
                    @change="onChange"
                    hide-details="auto"
                    :rules="nameRulesParent"
                    :label="$vuetify.lang.t('$vuetify.editCategory.text')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    class="mt-0"
                    v-model="localCategory.isHidden"
                    :label="
                      $vuetify.lang.t('$vuetify.editCategory.setting.isHidden')
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    class="mt-0"
                    v-model="localCategory.isHiddenInAllCategories"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.editCategory.setting.isHiddenInAllCategories'
                      )
                    "
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" cols="12">
              <v-row align="center">
                <v-col>
                  <div>
                    {{ $vuetify.lang.t("$vuetify.editCategory.icon") }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col>
                  <v-row>
                    <v-col cols="auto">
                      <v-icon class="mt-2" x-large>
                        {{ localCategory.icon }}
                      </v-icon>
                    </v-col>
                    <v-col>
                      <v-text-field
                        hide-details="auto"
                        v-model="localCategory.icon"
                        @change="onChange"
                        :rules="iconRules"
                        label="icon name"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="text--secondary text-body-2">
                        {{
                          $vuetify.lang.t("$vuetify.editCategory.icon_help1")
                        }}
                        <a
                          href="https://materialdesignicons.com/"
                          target="_blank"
                        >
                          {{ $vuetify.lang.t("$vuetify.cookie.link2") }}
                        </a>
                        {{
                          $vuetify.lang.t("$vuetify.editCategory.icon_help2")
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" cols="12">
              <v-row>
                <v-col cols="6">
                  <div>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.editCategory.color.light",
                        localCategory.color
                      )
                    }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.editCategory.color.dark",
                        localCategory.color
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <v-color-picker
                    dot-size="20"
                    canvas-height="100"
                    :hide-inputs="$vuetify.breakpoint.smAndDown"
                    mode="hexa"
                    @update:color="onChange"
                    v-model="localCategory.color.light"
                  ></v-color-picker>
                </v-col>
                <v-col cols="6">
                  <v-color-picker
                    dot-size="20"
                    canvas-height="100"
                    :hide-inputs="$vuetify.breakpoint.smAndDown"
                    mode="hexa"
                    @update:color="onChange"
                    v-model="localCategory.color.dark"
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" cols="12">
              <v-row align="center">
                <v-col>
                  <div>
                    {{ $vuetify.lang.t("$vuetify.editCategory.preview") }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text--secondary text-body-2 mr-3">
                    {{ $vuetify.lang.t("$vuetify.editCategory.color.light_t") }}
                  </span>
                  <category-chip light :category="localCategory" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text--secondary text-body-2 mr-3">
                    {{ $vuetify.lang.t("$vuetify.editCategory.color.dark_t") }}
                  </span>
                  <category-chip dark :category="localCategory" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-4">
          <v-spacer />
          <v-col cols="auto">
            <v-btn text @click="close('canceled')">
              {{ $vuetify.lang.t("$vuetify.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn outlined color="primary" @click="saveCategory">
              <v-icon left>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryChip from "../appComponents/CategoryChip.vue";
export default {
  name: "edit-video",
  components: {
    CategoryChip,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: "",
    },
    category: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      hasChanges: false,
      isValid: false,
      saving: false,
      localCategory: {
        key: null,
        text: "",
        description: "",
        icon: "mdi-car-sports",
        showInDrawer: false,
        isHidden: false,
        isHiddenInAllCategories: false,
        color: {
          light: "#e0e0e0",
          dark: "#555555",
        },
        parent: {
          icon: "",
          name: "",
        }
      },
    };
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        //dialog opened
        this.init();
      }
    },
  },
  methods: {
    init() {
      //copy the given values to the local category
      if (this.category) {
        Object.keys(this.localCategory).forEach((key) => {
          if (this.category[key]) {
            this.localCategory[key] = this.category[key];
          }
        });
      }

      //set defaults
      if (!this.localCategory.text) this.localCategory.text = "";
      if (!this.localCategory.description) this.localCategory.description = "";
      if (!this.localCategory.icon) this.localCategory.icon = "mdi-car-sports";
      if (!this.localCategory.showInDrawer)
        this.localCategory.showInDrawer = false;
      if (!this.localCategory.isHidden) this.localCategory.isHidden = false;
      if (!this.localCategory.isHiddenInAllCategories)
        this.localCategory.isHiddenInAllCategories = false;
      if (!this.localCategory.color)
        this.localCategory.color = {
          light: "#e0e0e0",
          dark: "#555555",
        };
      if (!this.localCategory.parent){
        this.localCategory = {
          icon: "",
          name: "",
        }
      }

      if (this.$refs.formCategory) {
        this.$refs.formCategory.resetValidation();
      }
      this.$nextTick(function () {
        this.hasChanges = false;
      });
    },
    async saveCategory() {
      if (this.$refs.formCategory.validate()) {
        const result = Object.assign({}, this.category, this.localCategory);
        this.$emit("update:category", result);
        this.$emit("update:changed", true);

        this.saving = false;
        this.close(this.isNew ? "created" : "changed");
      }
    },
    onChange() {
      this.hasChanges = true;
    },
    softClose() {
      if (!this.hasChanges) {
        this.close("canceled");
      }
    },
    close(status) {
      this.$emit("update:status", status);
      this.$emit("update:show", false);
      this.reset();
    },
    reset() {
      this.localCategory.key = null;
      this.localCategory.text = "";
      this.localCategory.description = "";
      this.localCategory.icon = "mdi-car-sports";
      this.localCategory.showInDrawer = false;
      this.localCategory.isHidden = false;
      this.localCategory.isHiddenInAllCategories = false;
      this.localCategory.color = {
        light: "#e0e0e0",
        dark: "#555555",
      };
      this.localCategory.parent = {
        icon: "",
        name: "",
      }
    },
  },
  computed: {
    isNew() {
      return !this.localCategory.key;
    },
    lessons() {
      return this.$store.getters.lessons;
    },
    nameRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.length >= 3) ||
          this.$vuetify.lang.t("$vuetify.validation.atLeastChar", 3),
        (v) =>
          (v && v.length <= 30) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 30),
      ];
    },
    nameRulesParent() {
      return [
        (v) =>
          (v.length <= 30) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 30),
      ];
    },
    descriptionRules() {
      return [
        (v) =>
          v.length <= 500 ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 500),
      ];
    },
    iconRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.startsWith("mdi-")) ||
          this.$vuetify.lang.t("$vuetify.editCategory.icon_validation"),
      ];
    },
    iconRulesParent() {
      return [
        (v) => {
          if (!v) return true
          else {
            if (!v.startsWith("mdi-")) {
              return this.$vuetify.lang.t("$vuetify.editCategory.icon_validation")
            }else return true
          }
        }
      ];
    },
  },
};
</script>


