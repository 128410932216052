<template>
  <router-view></router-view>
</template>

<script>

export default {
  data: () => ({ }),
  methods: {  },
  mounted() {  },
};
</script>