<template >
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <span>
          {{ $vuetify.lang.t("$vuetify.editUserRecommendations.selected") }}
        </span>
        <v-list
          flat
          dense
          :max-height="$vuetify.breakpoint.smAndDown ? 130 : 400"
        >
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in selected"
              :ripple="false"
              :key="index"
              inactive
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ getVideoObject(item).displayName }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="changeOrder(index, index - 1)"
                      :disabled="index === 0"
                    >
                      <v-icon>mdi-arrow-up-bold</v-icon>
                    </v-btn>
                  </template>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.lessons.button.up",
                      getVideoObject(item).displayName
                    )
                  }}
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="changeOrder(index, index + 1)"
                      :disabled="index === selected.length - 1"
                    >
                      <v-icon>mdi-arrow-down-bold</v-icon>
                    </v-btn>
                  </template>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.lessons.button.down",
                      getVideoObject(item).displayName
                    )
                  }}
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="deleteItem(index)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  {{ $vuetify.lang.t("$vuetify.mustWatches.delete") }}
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-if="selected.length == 0" class="text--secondary">
          {{ $vuetify.lang.t("$vuetify.editUserRecommendations.empty") }}
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <span>
          {{ $vuetify.lang.t("$vuetify.editUserRecommendations.all") }}
        </span>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :label="$vuetify.lang.t('$vuetify.manageVideos.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-virtual-scroll :items="filteredVideos" height="400" item-height="30">
          <template v-slot:default="{ item }">
            <v-list-item dense :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.displayName }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs" @click="select(item)">
                      <v-icon color="blue"> mdi-movie-plus-outline </v-icon>
                    </v-btn>
                  </template>
                  {{
                    $vuetify.lang.t("$vuetify.editUserRecommendations.select")
                  }}
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PlaylistPicker",
  props: {
    playlist: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      selected: [],
      search: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    videos() {
      return Object.values(this.$store.getters.videoMetaData).sort(function(a, b){
          if(a.displayName < b.displayName) { return -1; }
          if(a.displayName > b.displayName) { return 1; }
          return 0;
        });
    },
    filteredVideos() {
      if (this.search && this.search.trim()) {
        return this.videos.filter((video) =>
          video.displayName
            .toLowerCase()
            .includes(this.search.trim().toLowerCase())
        ).sort(function(a, b){
          if(a.displayName < b.displayName) { return -1; }
          if(a.displayName > b.displayName) { return 1; }
          return 0;
        })
      }
      return this.videos
    },
  },
  watch: {
    playlist: function () {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.playlist) {
        this.selected = this.playlist.map((video) => {
          if (typeof video === "string" || video instanceof String) {
            return video;
          } else {
            return video.name;
          }
        });
      } else {
        this.selected = [];
      }
    },
    async changeOrder(from, to) {
      this.selected.splice(to, 0, this.selected.splice(from, 1)[0]);
      await this.save();
    },
    getVideoObject(name){
      return this.videoMetaData[name]
    },
    async deleteItem(index) {
      this.selected.splice(index, 1);
      await this.save();
    },
    async select(newItem) {
      if (!this.selected.some(e => e === newItem.name)) {
        this.selected.push(newItem.name);
        await this.save();
      }
    },
    async save() {
      this.$emit("update:playlist", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.v-list {
  overflow-y: auto;
}
.v-list-item {
  max-height: 0px;
}
</style>
