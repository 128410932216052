import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"dark":""}},[(!_vm.splash && _vm.user)?_c('AppDrawer'):_vm._e(),(!_vm.splash)?_c('AppBar',{attrs:{"app":"","absolute":""}}):_vm._e(),_c('Encoder'),_c(VMain,[(!_vm.splash)?_c('router-view'):_c('Splash'),_c('AppSnackbar')],1),_c('AppFooter'),_c('CookieSetting'),_c(VSnackbar,{attrs:{"bottom":"","value":_vm.updateExists,"timeout":-1,"color":"secondary"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-reload-alert")])],1),_c(VCol,[_c('span',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.swUpdate.text0"))+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.swUpdate.text1"))+" ")])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.refreshApp}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.swUpdate.reload"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }