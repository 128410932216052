
const state = {
    snackbar: {
        color: "info",
        text: "",
        multiline: false,
        show: false,
        timeout: 7000
    },
    intervall: null,
    progress: 0,
    isPaused:  false,
}

const mutations = {
    SET_SNACKBAR: (state, snackbar) => {
        let wait = 0;

        if (state.snackbar.show) {
            wait = 500;
            state.snackbar.show = false;
        }

        setTimeout(() => {
            state.snackbar.color = snackbar.color;
            state.snackbar.text = snackbar.text;
            state.snackbar.multiline = state.snackbar.text.length > 70;
            state.snackbar.show = true;
            //reset progress
            clearInterval(state.intervall)
            state.progress = 0
            state.intervall = setInterval(() => {
                if(!state.isPaused){
                    state.progress += 1.0
                }
                if (state.progress > 100) {
                    //reset progress
                    clearInterval(state.intervall)
                    state.progress = 0
                    state.snackbar.show = false;
                }
            }, state.snackbar.timeout / 100);
        }, wait);
    },
    CLOSE_SNACKBAR: (state) => {
        state.snackbar.show = false
        state.snackbar.text = ""
        state.snackbar.multiline = false
    },
    PAUSE_SNACKBAR: (state) => {
        state.isPaused = true
    },
    UN_PAUSE_SNACKBAR: (state) => {
        state.isPaused = false
    }
}

const actions = {
    alertSuccess: (context, text) => {
        context.commit("SET_SNACKBAR", {
            color: "success",
            text: text
        });
    },
    alertWarning: (context, text) => {
        context.commit("SET_SNACKBAR", {
            color: "warning",
            text: text
        });
    },
    alertError: (context, text) => {
        context.commit("SET_SNACKBAR", {
            color: "error",
            text: text
        });
    },
    alertInfo: (context, text) => {
        context.commit("SET_SNACKBAR", {
            color: "info",
            text: text
        });
    },
    closeSnackbar: (context) => {
        context.commit("CLOSE_SNACKBAR")
    },
    pauseSnackbarTimer: (context) => {
        context.commit("PAUSE_SNACKBAR")
    },
    unpauseSnackbarTimer: (context) => {
        context.commit("UN_PAUSE_SNACKBAR")
    }
}

const getters = {
    snackbar: state => {
        return state.snackbar;
    },
    snackbarProgress: state => {
        return state.progress;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}