<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <p class="text-h3 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.manageStudents.header") }}
          </p>
          <p class="headline">
            {{ $vuetify.lang.t("$vuetify.manageStudents.new.header") }}
          </p>
          <v-alert
            :value="newAccount.loginCode !== newAccount.lastGeneratedCode"
            transition="slide-y-reverse-transition"
            border="left"
            text
            type="warning"
            >{{
              $vuetify.lang.t("$vuetify.manageStudents.new.codeChanged")
            }}</v-alert
          >
          <v-form
            ref="formStudent"
            v-model="newAccount.isValid"
            lazy-validation
          >
            <v-row>
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : null">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.manageStudents.new.name')"
                  v-model="newAccount.name"
                  :rules="newAccountNameRules"
                  :hide-details="$vuetify.breakpoint.mobile ? 'auto' : false"
                  :dense="$vuetify.breakpoint.mobile"
                  :disabled="loadingState.newStudent"
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.manageStudents.new.code')"
                  v-model="newAccount.loginCode"
                  :rules="newAccountCodeRules"
                  :hide-details="$vuetify.breakpoint.mobile ? 'auto' : false"
                  :dense="$vuetify.breakpoint.mobile"
                  prepend-inner-icon="mdi-lock"
                  append-outer-icon="mdi-refresh"
                  :disabled="loadingState.newStudent"
                  :append-icon="newAccount.showCode ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="newAccount.showCode = !newAccount.showCode"
                  @click:append-outer="getNewCode"
                  :type="newAccount.showCode ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
                <v-btn
                  rounded
                  block
                  color="primary"
                  @click="createAccount"
                  :loading="loadingState.newStudent"
                >
                  <v-icon left> mdi-account-plus </v-icon>
                  {{ $vuetify.lang.t("$vuetify.manageStudents.new.create") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-card class="mt-3">
            <v-card-title>
              <span class="headline">
                {{ $vuetify.lang.t("$vuetify.manageStudents.students") }}
              </span>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="all.search"
                prepend-inner-icon="mdi-magnify"
                :label="$vuetify.lang.t('$vuetify.search')"
                single-line
                clearable
                hide-details
              ></v-text-field>
            </v-card-title>
              <v-data-table
                :search="all.search"
                :items="all.students"
                :headers="all.tableHeaders"
                :loading="loadingState.all"
                :no-data-text="
                  $vuetify.lang.t('$vuetify.manageStudents.all.empty')
                "
                  :footer-props="{
                    itemsPerPageOptions: [20, 100, -1],
                  }"
              >
              <template v-slot:[`item.name`]="{ item }">
                <v-edit-dialog
                  large
                  @save="onEditFieldSuccess(item)"
                  @open="onEditFieldOpen(item)"
                  :save-text="$vuetify.lang.t('$vuetify.save')"
                  :cancel-text="$vuetify.lang.t('$vuetify.cancel')"
                >
                  {{ item.name }}
                  <template v-slot:input>
                    <div class="mt-4 text-subtitle-1">
                      {{ $vuetify.lang.t("$vuetify.manageStudents.all.edit") }}
                    </div>
                    <v-text-field
                      v-model="all.editText"
                      :label="$vuetify.lang.t('$vuetify.lessons.new_name')"
                      :rules="newAccountNameRules"
                      class="mt-2"
                      autofocus
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="showEditRecommendationDialog(item)"
                    >
                      <v-badge
                        :content="
                          item.recommendations
                            ? item.recommendations.length
                            : ''
                        "
                        :value="
                          item.recommendations &&
                          item.recommendations.length != 0
                            ? true
                            : false
                        "
                        color="primary"
                        overlap
                      >
                        <v-icon
                          large
                          :disabled="loadingState.deleteStudent"
                          color="blue"
                          >mdi-video-account</v-icon
                        >
                      </v-badge>
                    </v-btn>
                  </template>
                  <span>{{
                    $vuetify.lang.t(
                      "$vuetify.manageStudents.all.editRecommendations",
                      item.name
                    )
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      right
                      :disabled="loadingState.deleteStudent"
                      v-on="on"
                      large
                      color="error"
                      @click="deleteItem(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>{{
                    $vuetify.lang.t(
                      "$vuetify.manageStudents.all.delete",
                      item.name
                    )
                  }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{
                  new Date(item.createdAt).toLocaleString(undefined, {
                    dateStyle: "short",
                  }) +
                  " " +
                  new Date(item.createdAt).toLocaleString(undefined, {
                    timeStyle: "short",
                  })
                }}</span>
              </template>
              <template v-slot:[`item.lastLogin`]="{ item }">
                <span>{{
                  item.lastLogin
                    ? new Date(item.lastLogin).toLocaleString(undefined, {
                        dateStyle: "short",
                      }) +
                      " " +
                      new Date(item.lastLogin).toLocaleString(undefined, {
                        timeStyle: "short",
                      })
                    : $vuetify.lang.t("$vuetify.never")
                }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <edit-user-recommendations
      :show.sync="editUserRecommendationsDialog.show"
      :student.sync="editUserRecommendationsDialog.student"
    />
  </v-container>
</template>

<script>
import { generateNewCodes } from "./../../util/nanoid";
import { execCloudFunction } from "./../../firebase/cloudFunction";
import { loadStudents } from "./../../firebase/manageStudents";
import EditUserRecommendations from "./../dialogs/EditUserRecommendations";
import { db } from "./../../firebase/firebase";

export default {
  name: "ManageStudents",
  components: { EditUserRecommendations },
  data() {
    return {
      newAccount: {
        name: "",
        isValid: false,
        loginCode: "",
        lastGeneratedCode: "",
        showCode: true,
        uuid: "",
      },
      all: {
        students: [],
        search: "",
        tableHeaders: [
          {
            text: this.$vuetify.lang.t("$vuetify.manageStudents.all.name"),
            value: "name",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.manageStudents.new.code"),
            value: "code",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.manageStudents.all.createdAt"),
            value: "createdAt",
            hideOnMobile: true,
            sort: (a, b) => {
              return a - b;
            },
          },
          {
            text: this.$vuetify.lang.t("$vuetify.manageStudents.all.lastLogin"),
            value: "lastLogin",
            hideOnMobile: true,
            sort: (a, b) => {
              if (!a) a = 0;
              if (!b) b = 0;
              return a - b;
            },
          },
          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ],
        editText: "",
      },
      loadingState: {
        newStudent: false,
        all: false,
        deleteStudent: false,
      },
      editUserRecommendationsDialog: {
        student: null,
        show: false,
      },
    };
  },
  mounted() {
    this.getNewCode();
    this.loadData();
  },
  watch: {
    "editUserRecommendationsDialog.show": function name(newVal, oldVal) {
      if (oldVal && !newVal) {
        // edit student recommendations dialog closed,
        // remove focus from the button, otherwise the tooltip would be still visible
        this.$nextTick(function () {
          document.activeElement.blur();
        });
      }
    },
  },
  methods: {
    async createAccount() {
      if (!this.$refs.formStudent.validate()) {
        return;
      }
      this.setLoading("newStudent");

      try {
        await execCloudFunction("createStudent", this.newAccount);
        this.$store.dispatch(
          "alertSuccess",
          this.$vuetify.lang.t(
            "$vuetify.manageStudents.new.created",
            this.newAccount.name
          )
        );
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(this.$vuetify.lang.t(
            "$vuetify.manageStudents.new.welcomeMessage",
            this.newAccount.name,
            this.newAccount.lastGeneratedCode
          ));
        }
        this.newAccount.name = "";
        this.$refs.formStudent.reset();
        this.loadData();
      } catch (error) {
        if (error.message && error.message.startsWith("$vuetify.")) {
          console.error("During creating Student: " + error.message);
          this.$store.dispatch(
            "alertError",
            this.$vuetify.lang.t(error.message)
          );
        } else {
          this.$store.dispatch(
            "alertError",
            this.$vuetify.lang.t("$vuetify.api_error_unknown")
          );
        }
        return error.message;
      } finally {
        this.getNewCode();
        this.removeLoading("newStudent");
      }
    },
    getNewCode() {
      const result = generateNewCodes();
      this.newAccount.lastGeneratedCode = result.loginCode;
      this.newAccount.loginCode = this.newAccount.lastGeneratedCode;
      this.newAccount.uuid = result.uuid;
    },
    async loadData() {
      this.setLoading("all");
      const ret = await loadStudents();
      const accs = [];
      for (let key in ret) {
        accs.push(ret[key]);
      }
      this.all.students = accs;
      this.removeLoading("all");
    },
    setLoading(set) {
      this.loadingState[set] = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading(set) {
      this.loadingState[set] = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
    async deleteItem(item) {
      const result = await this.$confirm(
        this.$vuetify.lang.t(
          "$vuetify.manageStudents.all.confirm_delete",
          item.name
        )
      );
      if (result) {
        this.setLoading("deleteStudent");
        try {
          await execCloudFunction("deleteStudent", { uuid: item.uuid });
          this.$store.dispatch(
            "alertSuccess",
            this.$vuetify.lang.t(
              "$vuetify.manageStudents.all.delete_success",
              item.name
            )
          );
          this.loadData();
        } catch (error) {
          if (error.message && error.message.startsWith("$vuetify.")) {
            console.error("During createing Student: " + error.message);
            this.$store.dispatch(
              "alertError",
              this.$vuetify.lang.t(error.message)
            );
          } else {
            this.$store.dispatch(
              "alertError",
              this.$vuetify.lang.t("$vuetify.api_error_unknown")
            );
          }
          return error.message;
        } finally {
          this.removeLoading("deleteStudent");
        }
      }
    },
    onEditFieldOpen(student) {
      this.all.editText = student.name;
    },
    async onEditFieldSuccess(student) {
      this.setLoading("all");
      await db.ref("students/" + student.uuid + "/name").set(this.all.editText);
      student.name = this.all.editText;
      this.removeLoading("all");
    },
    showEditRecommendationDialog(student) {
      this.editUserRecommendationsDialog.student = student;
      this.editUserRecommendationsDialog.show = true;
    },
  },
  computed: {
    newAccountNameRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.length >= 2) ||
          this.$vuetify.lang.t("$vuetify.validation.atLeastChar", 2),
        (v) =>
          (v && v.length <= 40) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 40),
      ];
    },
    newAccountCodeRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.length == 6) ||
          this.$vuetify.lang.t("$vuetify.validation.exactChar", 6),
      ];
    },
  },
};
</script>
