const state = {
    currentJob: {
        file: null,
        videoName: "",
        progress: {
            currentStep: 0,
            steps: 4,
            progress: null
        }
    },
    isJobRunning: false,
    isJobSet: false
}

const mutations = {
    /**job: {file: File-Object, videoName: string} */
    setNewJob: (state, job) => {
        if (!state.isJobRunning && !state.isJobSet) {
            state.currentJob.file = job.file;
            state.currentJob.videoName = job.videoName;
            state.isJobSet = true
        }else{
            console.warn("Ignored this 'setNewJob' call because there is already a job running");
        }
    },
    /**progress: {currentStep: number, steps: steps count (number), progress: percent-number} */
    setJobProgress: (state, progress) => {
        if (state.isJobRunning) {
            state.currentJob.progress = progress
        }else{
            console.warn("It makes no sense to set a progress if no job is running");
        }
    },
    setIsJobRunning: (state, isRunning) => {
        state.isJobRunning = isRunning
    },
    setIsJobSet: (state, isSet) => {
        state.isJobSet = isSet
    },
    finnishJob: (state) => {
        state.currentJob = {
            currentJob: {
                file: null,
                videoName: "",
                progress: {
                    currentStep: 0,
                    steps: 4,
                    progress: null
                }
            }
        }
        state.isJobSet = false
        state.isJobRunning = false
    }
}


const getters = {
    newJob: state => {
        return state.newJob
    },
    jobProgress: state => {
        return state.currentJob.progress
    },
    job: state => {
        return state.currentJob
    },
    isJobRunning: state => {
        return state.isJobRunning
    },
    isJobSet: state => {
        return state.isJobSet
    }
}

export default {
    state,
    mutations,
    getters
}