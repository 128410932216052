/* eslint-disable no-console */

import { register } from "register-service-worker";

register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
        console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
        // if the user visits the page for the very first time, the SW should be activated
        if (navigator.serviceWorker.controller) return;
        if (registration && registration.active) {
            console.log("activate SW now!");
            registration.active.postMessage({ msg: "activateMe" });
        }
    },
    registered() {
        console.log("Service worker has been registered.");
    },
    cached() {
        console.log("Content has been cached for offline use.");
    },
    updatefound() {
        console.log("New content is downloading.");
    },
    updated(registration) {
        console.log("New content is available; please refresh.");
        document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
    },
    offline() {
        console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
        console.error("Error during service worker registration:", error);
    },
});
