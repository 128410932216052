export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

/**
 * removes the given values of the given array, uses === as operator 
 * @param {*[]} arr the array
 * @param {*} objectToRemove the object that should be removed
 * @param {boolean} stopAtFirstHit only removes the first element
 */
export function arrayRemove(arr, objectToRemove, stopAtFirstHit = true) {
  if (!arr) {
    return []
  }
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === objectToRemove) {
      arr.splice(i, 1);
      if (stopAtFirstHit) {
        break
      }
    }
  }
  return arr
}