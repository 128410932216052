<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <div class="text-h3 font-weight-light">
            {{ category && category.text ? category.text : "" }}
          </div>
          <div v-if="category && category.description" class="text--secondary">
            {{ category && category.description ? category.description : "" }}
          </div>
          <v-row justify="space-around" class="mt-4">
            <v-col :cols="$vuetify.breakpoint.lgAndUp ? 9 : undefined">
              <v-select
                :items="sortItems"
                v-model="sortBy"
                :label="$vuetify.lang.t('$vuetify.allVideos.sort.sortBy')"
                outlined
                hide-details
                item-value="key"
                item-text="text"
                class="mx-3"
                prepend-inner-icon="mdi-sort"
              >
                <template v-slot:item="{ item }">
                  <v-icon left>
                    {{ item.icon }}
                  </v-icon>
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col :cols="$vuetify.breakpoint.lgAndUp ? 9 : undefined">
              <v-row v-if="category && category.linkedVideos">
                <v-col>
                  <VideoChip
                    v-for="video in displayedVideos"
                    v-bind:key="video"
                    :videoName="video"
                    :onClick="playVideo"
                    class="ma-3"
                  ></VideoChip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoChip from "./../appComponents/VideoChip";

export default {
  name: "VideosOfCategory",
  components: {
    VideoChip,
  },
  data() {
    return {
      category: null,
      sortItems: [
        {
          key: "alphabetically",
          text: this.$vuetify.lang.t("$vuetify.allVideos.sort.alphabetically"),
          icon: "mdi-sort-alphabetical-ascending",
        },
        {
          key: "playlist",
          text: this.$vuetify.lang.t("$vuetify.allVideos.sort.playlist"),
          icon: "mdi-sort-bool-ascending-variant",
        },
      ],
      sortBy: "playlist",
      displayedVideos: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    lessons() {
      return this.$store.getters.lessons;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    playlist() {
      return Object.values(this.category.linkedVideos);
    },
    sorted() {
      return Object.values(this.category.linkedVideos)
        .map((videKey) => this.videoMetaData[videKey])
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((video) => video.name);
    },
  },
  watch: {
    "$route.params.categoryId": function name() {
      this.init();
    },
    category: function cat() {
      this.displayedVideos = Object.values(this.category.linkedVideos);
      this.sortBy = "playlist";
    },
    sortBy: function sort(newVal) {
      if (newVal === "alphabetically") {
        this.displayedVideos = this.sorted;
      }
      if (newVal === "playlist") {
        this.displayedVideos = this.playlist;
      }
    },
  },
  methods: {
    init() {
      //this.videos = this.sortedVideos;
      if (
        this.$route.params &&
        this.$route.params.categoryId &&
        !!this.lessons[this.$route.params.categoryId]
      ) {
        this.category = this.lessons[this.$route.params.categoryId];
      } else {
        this.$router.push({ name: "home" });
      }
    },
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
  },
};
</script>


