<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" >
        <v-card class="elevation-8 pa-4">
          <p class="text-h3 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.upload.new.header") }}
          </p>
          <v-alert
            transition="slide-y-reverse-transition"
            border="left"
            dismissible
            v-if="browserIsCompatible"
            text
            type="warning"
            >{{ $vuetify.lang.t("$vuetify.upload.new.warning") }}</v-alert
          >
          <v-form
            ref="formNewVideo"
            v-model="isValid"
            lazy-validation
            v-if="browserIsCompatible"
          >
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '4'">
                <v-file-input
                  :label="$vuetify.lang.t('$vuetify.upload.new.choose')"
                  accept="video/*"
                  :hide-details="$vuetify.breakpoint.mobile ? 'auto' : false"
                  :dense="$vuetify.breakpoint.mobile"
                  show-size
                  chips
                  :rules="videoRules"
                  v-model="selectedFile"
                  :disabled="isJobRunning"
                  prepend-icon="mdi-video"
                ></v-file-input>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : null">
                <v-text-field
                  :hide-details="$vuetify.breakpoint.mobile ? 'auto' : false"
                  :dense="$vuetify.breakpoint.mobile"
                  :label="$vuetify.lang.t('$vuetify.upload.new.name')"
                  :hint="$vuetify.lang.t('$vuetify.upload.new.name_hint')"
                  v-model="selectedVideoName"
                  :rules="videoNameRules"
                  :disabled="isJobRunning"
                  prepend-icon="mdi-message-video"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom max-width="200">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle </v-icon>
                      </template>
                      {{ $vuetify.lang.t("$vuetify.upload.new.name_help") }}
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
                <v-btn
                  color="primary"
                  :disabled="isJobRunning"
                  @click="uploadVideo"
                  rounded
                  block
                >
                  <v-icon left> mdi-cloud-upload </v-icon>
                  {{ $vuetify.lang.t("$vuetify.upload.new.go") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-alert
            transition="slide-y-reverse-transition"
            border="left"
            v-else
            text
            type="error"
            >{{
              $vuetify.lang.t("$vuetify.upload.new.not_compatible")
            }}</v-alert
          >
          <v-row v-if="isJobRunning">
            <v-col>
              <v-card class="elevation-3 pa-4">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-progress-circular
                      indeterminate
                      size="40"
                      color="secondary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col>
                    <v-row>
                      <v-col class="title">
                        {{
                          $vuetify.lang.t("$vuetify.upload.new.progress.header")
                        }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-progress-linear
                          :value="jobProgress.progress"
                          :indeterminate="!jobProgress.progress"
                          color="secondary"
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <span class="text--secondary">{{
                          $vuetify.lang.t(
                            "$vuetify.upload.new.progress.steps",
                            jobProgress.currentStep,
                            jobProgress.steps,
                            $vuetify.lang.t(
                              "$vuetify.upload.new.progress.step." +
                                (jobProgress.currentStep - 1)
                            )
                          )
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "./../../firebase/firebase";

export default {
  mounted() {
    const vm = this;
    window.addEventListener("beforeunload", (event) => {
      if (vm.isJobRunning) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Older browsers supported custom message
        event.returnValue = "Videoverarbeitung läuft noch!";
        return "Videoverarbeitung läuft noch!";
      }
    });
  },
  data: function () {
    return {
      selectedFile: null,
      selectedVideoName: "",
      isValid: true,
      browserIsCompatible: typeof SharedArrayBuffer === "function",
      metadata: {
        duration: -1,
        durationString: "",
        isVisible: false,
        displayName: "",
        description: "",
        uploadedFrom: null,
        uploadedAt: null,
      },
    };
  },
  watch: {
    selectedFile: function (newVal) {
      const vm = this;
      if (newVal) {
        //set video name
        if (!this.selectedVideoName) {
          this.selectedVideoName = newVal.name.slice(
            0,
            newVal.name.lastIndexOf(".")
          );
        }

        //get video duration
        var video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          vm.metadata.duration = video.duration;
          vm.metadata.durationString =
            Math.round(video.duration / 60)
              .toString()
              .padStart(2, "0") +
            ":" +
            Math.round(video.duration % 60)
              .toString()
              .padStart(2, "0");
        };
        video.src = URL.createObjectURL(this.selectedFile);
      } else {
        this.selectedVideoName = "";
        this.metadata = {
          duration: -1,
          uploadedFrom: null,
          uploadedAt: null,
        };
      }
    },
  },
  methods: {
    uploadVideo() {
      if (!this.$refs.formNewVideo.validate()) {
        return;
      }
      this.$store.commit("setNewJob", {
        file: this.selectedFile,
        videoName: this.selectedVideoName,
      });

      //get and save meta data
      this.metadata.uploadedFrom = this.user.name;
      this.metadata.uploadedAt = new Date().getTime();
      db.ref("videos/" + this.selectedVideoName).update(this.metadata);
    },
  },
  computed: {
    videoNameRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          (v && v.length >= 5) ||
          this.$vuetify.lang.t("$vuetify.validation.atLeastChar", 5),
        (v) =>
          (v && v.length <= 150) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 150),
        (v) =>
          (v && !v.includes(".") && !v.includes(" ") && !v.includes("/")) ||
          this.$vuetify.lang.t("$vuetify.validation.notAllowed"),
      ];
    },
    videoRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.validation.notEmpty"),
        (v) =>
          !v ||
          v.size < 1000000000 ||
          this.$vuetify.lang.t("$vuetify.validation.maxFileSize", "1 GB"),
      ];
    },
    user() {
      return this.$store.getters.user;
    },
    jobProgress() {
      return this.$store.getters.jobProgress;
    },
    isJobRunning() {
      return this.$store.getters.isJobRunning;
    },
  },
};
</script>