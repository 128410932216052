import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticClass:"cookie-dialog",attrs:{"scrollable":"","persistent":"","width":"650px","overlay-opacity":"0.8","transition":"dialog-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VContainer,{staticClass:"pt-0 pl-0 pr-0"},[_c(VCard,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VContainer,{staticClass:"pa-0"},[_c(VSheet,{staticClass:"cookie-header",attrs:{"color":"primary"}},[_c(VRow,{attrs:{"dark":"","no-gutters":"","justify":"center","align":"center"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c(VCol,{staticClass:"text-h3 pr-2",attrs:{"cols":"auto"}},[_vm._v(" 🍪 ")]):_vm._e(),_c(VCol,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
                          ? 'text-h5 font-weight-light'
                          : 'text-h4 font-weight-light'},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? _vm.$vuetify.lang.t("$vuetify.cookie.title_small") : _vm.$vuetify.lang.t("$vuetify.cookie.title1"))+" ")])])],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"text-h5 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.title2"))+" ")])])],1):_vm._e()],1)],1)],1)],1)],1),_c(VRow,{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c(VCol,[_c('div',{class:'text--secondary ' +
              (_vm.$vuetify.breakpoint.smAndDown ? 'small-text' : '')},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.description"))),_c('br'),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.link1"))+" "),_c('a',{attrs:{"href":"https://www.verbraucherzentrale.de/wissen/digitale-welt/datenschutz/cookies-kontrollieren-und-verwalten-11996","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.link2"))+" ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.link3"))+" "),_c('a',{attrs:{"href":"https://www.ulli-deinefahrschule.de/impressum/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.footer.legalNotice"))+" ")]),_vm._v(". ")])])],1),_c(VRow,{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c(VCol,[_c(VExpansionPanels,{attrs:{"popout":_vm.$vuetify.breakpoint.smAndUp}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.essentials_title"))+" ")]),_c(VCol,{staticClass:"ml-auto mr-6",attrs:{"cols":"auto"}},[_c(VSwitch,{attrs:{"disabled":"","hide-details":""},model:{value:(_vm.essentials),callback:function ($$v) {_vm.essentials=$$v},expression:"essentials"}})],1)],1)]}}])}),_c(VExpansionPanelContent,[_c('div',{class:'text--secondary ' +
                    (_vm.$vuetify.breakpoint.smAndDown ? 'small-text' : '')},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.essentials_desc"))+" ")])])],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.analytics_title")))]),_c(VCol,{staticClass:"ml-auto mr-6",attrs:{"cols":"auto"}},[_c(VSwitch,{attrs:{"hide-details":""},on:{"click":function($event){$event.stopPropagation();!_vm.analytics}},model:{value:(_vm.analytics),callback:function ($$v) {_vm.analytics=$$v},expression:"analytics"}})],1)],1)]}}])}),_c(VExpansionPanelContent,[_c('div',{class:'text--secondary ' +
                    (_vm.$vuetify.breakpoint.smAndDown ? 'small-text' : '')},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.analytics_desc"))+" ")])])],1)],1)],1)],1),_c(VRow,{staticClass:"mt-2 pa-2",attrs:{"no-gutters":""}},[_c(VAlert,{attrs:{"value":_vm.doReloadOnSave,"transition":"slide-y-reverse-transition","border":"left","dense":"","text":"","type":"info"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.reloadInfo")))])],1),_c(VRow,{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VBtn,{attrs:{"block":!_vm.$vuetify.breakpoint.smAndUp,"text":""},on:{"click":_vm.declineAndClose}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.disable"))+" ")])],1),_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c(VBtn,{attrs:{"block":!_vm.$vuetify.breakpoint.smAndUp,"color":"primary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cookie.go"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }