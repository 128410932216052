import { customAlphabet } from "nanoid";

/*
    Let's imagine we generate 1 codes per hour,
    this would mean we could generate ~3 years codes until we reached a 1% chance of a collision.
    (With the given alphabet and codes that are 6 chars long [https://zelark.github.io/nano-id-cc/])
*/
const loginCodes = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyzüöä', 6);

// ok its not a real uuid, but this key is used as primary key for the student object
const uuid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 20);

/**
 * generates a new login code and a new uuid
 */
export function generateNewCodes(){
    return {loginCode: loginCodes(), uuid: "uuid-"+uuid()}
}

/**
 * generates a new primary key, the key is 20 chars long and has a tailing 'kp-'
 */
 export function generateKey(){
    return "pk-"+uuid()
}