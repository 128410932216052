import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"elevation-8 pa-4"},[_c('div',{staticClass:"text-h3 font-weight-light"},[_vm._v(" "+_vm._s(_vm.category && _vm.category.text ? _vm.category.text : "")+" ")]),(_vm.category && _vm.category.description)?_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.category && _vm.category.description ? _vm.category.description : "")+" ")]):_vm._e(),_c(VRow,{staticClass:"mt-4",attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 9 : undefined}},[_c(VSelect,{staticClass:"mx-3",attrs:{"items":_vm.sortItems,"label":_vm.$vuetify.lang.t('$vuetify.allVideos.sort.sortBy'),"outlined":"","hide-details":"","item-value":"key","item-text":"text","prepend-inner-icon":"mdi-sort"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)],1),_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 9 : undefined}},[(_vm.category && _vm.category.linkedVideos)?_c(VRow,[_c(VCol,_vm._l((_vm.displayedVideos),function(video){return _c('VideoChip',{key:video,staticClass:"ma-3",attrs:{"videoName":video,"onClick":_vm.playVideo}})}),1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }