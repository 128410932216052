import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":_vm.hasChanges,"max-width":"1400px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":_vm.softClose},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"elevation-8 pa-3"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{class:(_vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.editUserRecommendations.title", _vm.student ? _vm.student.name : ""))+" ")]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,[_c(VCol,[_c('span',[_c('PlaylistPicker',{attrs:{"playlist":_vm.localRecommendations},on:{"update:playlist":function($event){_vm.localRecommendations=$event}}})],1)])],1),_c(VRow,{staticClass:"mt-4"},[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cancel"))+" ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"loading":_vm.saving,"outlined":"","color":"primary"},on:{"click":_vm.save}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.save"))+" ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }