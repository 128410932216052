<template>
  <div class="video-container">
    <video ref="video" controls class="video"></video>
  </div>
</template>

<script>
import Hls from "hls.js";
import { getDownloadUrl } from "./../../firebase/storage";
import { logUserOut, isTokenValid } from "../../firebase/authStudent";
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: "",
    },
    /*  quality: {
        pool: [],
        used: null,
        forcedByUser: -1, },*/
    quality: {
      type: Object,
      required: false,
    },
    /*  playbackState: {
        isPlaying: false,
        duration: -1,
        currentTime: -1,
        hasEnded: false,
        watchTime: 0}, */
    playbackState: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.url !== "") {
      this.stripFirebasePath();
      this.setup();
    }

    //interval that count the watch time, if a video is played
    this.interval = setInterval(this.updateWatchTime, 1000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  data: function () {
    return {
      player: null,
      lastTimeUpdate: 0,
      canPlayThrough: false,
      firebaseVideoPath: "",
      interval: null,
    };
  },
  watch: {
    url: function () {
      if (this.player) {
        this.player.destroy();
      }
      this.checkToken();

      if (this.url !== "") {
        this.stripFirebasePath();
        this.setup();
      }

      this.lastTimeUpdate = 0;
      this.canPlayThrough = false;
    },
    "quality.forcedByUser": function (val) {
      console.log("new quality level forced by user: " + val);
      this.player.currentLevel = val;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    setup() {
      const vm = this;
      this.player = new Hls({
        fragLoadingMaxRetry: 1,
        levelLoadingMaxRetry: 1,
        maxBufferLength: 10, //in s, is the time between playback and buffer smaller than this, a new fragment will be loaded
        maxBufferSize: 11 * 1000 * 1000, //11mb, the go to buffer (sollwert)
        maxMaxBufferLength: 40, //in s, the upper maximum of the buffer length in s, the buffer will never be larger then this
        capLevelToPlayerSize: true,
        capLevelOnFPSDrop: true,
        startLevel: !vm.$vuetify.breakpoint.smAndDown ? 2 : undefined,
        //add a custom loader to replace firebase path with downloadable urls
        loader: function (config) {
          let loader = new Hls.DefaultConfig.loader(config);
          this.abort = () => loader.abort();
          this.destroy = () => loader.destroy();
          this.load = async function (context, config, callbacks) {
            let { url } = context;
            if (url.includes("(firebase)")) {
              var crop = url.substring(url.indexOf(")") + 1, url.length);
              if (!crop.startsWith("videos/")) {
                //we need to put videos/xxx/ in front the crop
                crop = vm.firebaseVideoPath + "/" + crop;
              }
              // we have a firebase url -> crop the path and replace it with the downloadable url
              const newUrl = await getDownloadUrl(crop);
              context.url = newUrl;
              url = newUrl;
            }
            loader.load(context, config, callbacks);
          };
        },
      });

      let video = this.$refs["video"];
      this.player.loadSource(this.url);
      this.player.attachMedia(video);
      this.player.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        video.play();
        vm.quality["pool"] = data.levels;
        vm.$emit("update:quality", vm.quality);
        if (vm.quality.forcedByUser !== -1) {
          if (data.levels.length > vm.quality.forcedByUser) {
            console.log(
              "reusing forced user level: " + vm.quality.forcedByUser
            );
            video.currentLevel = vm.quality.forcedByUser;
          }
        }
      });
      this.player.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        vm.quality["used"] = data.level;
        vm.$emit("update:quality", vm.quality);
      });

      // save volume setting in local storage
      if (localStorage.getItem("volume")) {
        video.volume = localStorage.getItem("volume");
      }

      //add event listener
      video.onvolumechange = function () {
        localStorage.setItem("volume", video.volume);
      };
      video.onplay = function () {
        vm.playbackState.isPlaying = true;
        vm.playbackState.hasEnded = false;
        vm.updatePlaybackState();
      };
      video.onpause = function () {
        vm.playbackState.isPlaying = false;
        vm.updatePlaybackState();
      };
      video.onended = function () {
        if (vm.canPlayThrough) {
          vm.playbackState.hasEnded = true;
          vm.updatePlaybackState();
        }
      };
      video.ondurationchange = function () {
        if (video.duration !== vm.playbackState.duration) {
          vm.playbackState.duration = video.duration;
          vm.updatePlaybackState();
        }
      };
      video.oncanplaythrough = function () {
        vm.canPlayThrough = true;
      };
      video.ontimeupdate = function () {
        //limit update rate to max. 2hz
        if (Math.abs(video.currentTime - vm.lastTimeUpdate) > 0.5) {
          vm.lastTimeUpdate = video.currentTime;
          vm.playbackState.currentTime = video.currentTime;
          vm.updatePlaybackState();
        }
      };

      vm.resetplaybackState();

      //error handling
      this.player.on(Hls.Events.ERROR, this.errorHandling);
    },
    errorHandling(event, data) {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.error(data.details);
            console.log("fatal network error encountered");
            this.$store.dispatch(
              "alertError",
              this.$vuetify.lang.t("$vuetify.hls.fatal_network")
            );
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log("fatal media error encountered, try to recover");
            this.$store.dispatch(
              "alertError",
              this.$vuetify.lang.t("$vuetify.hls.fatal_media")
            );
            this.player.recoverMediaError();
            break;
          default:
            // cannot recover
            this.player.destroy();
            this.$store.dispatch(
              "alertError",
              this.$vuetify.lang.t("$vuetify.hls.fatal")
            );
            break;
        }
      }
    },
    stripFirebasePath() {
      if (this.url.includes("(firebase)")) {
        const crop = this.url.substring(
          this.url.indexOf(")") + 1,
          this.url.length
        );
        if (crop.lastIndexOf("/") > 0) {
          this.firebaseVideoPath = crop.substring(0, crop.lastIndexOf("/"));
        }
      }
    },
    updateWatchTime() {
      if (this.playbackState.isPlaying) {
        this.playbackState.watchTime += 1;
        this.updatePlaybackState();
      }
    },
    updatePlaybackState() {
      this.$emit("update:playbackState", this.playbackState);
    },
    resetplaybackState() {
      this.playbackState.isPlaying = false;
      this.playbackState.duration = -1;
      this.playbackState.currentTime = -1;
      this.playbackState.hasEnded = false;
      this.playbackState.watchTime = 0;
      this.updatePlaybackState();
    },
    checkToken() {
      if (!this.user.admin) {
        isTokenValid().then((result) => {
          if (!result) {
            logUserOut();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/variables.scss";

.video-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>