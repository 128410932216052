import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,[_c(VCard,{staticClass:"elevation-8 pa-4"},[_c('div',{class:(_vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.allVideosLesson.header"))+" ")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.allVideosLesson.description"))+" ")]),_c(VRow,{attrs:{"justify":"space-around"}},_vm._l((_vm.filteredLessons),function(lesson,index){return _c(VCol,{key:index,attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 6 : undefined}},[_c(VCard,{attrs:{"outlined":"","min-width":"350"}},[_c(VCardTitle,{staticClass:"text-h5 font-weight-light"},[_vm._v(_vm._s(lesson.text))]),(lesson.description)?_c(VCardSubtitle,{staticClass:"text--primary font-weight-light"},[_vm._v(" "+_vm._s(lesson.description)+" ")]):_vm._e(),_c(VVirtualScroll,{attrs:{"items":lesson.linkedVideos,"item-height":"71","max-height":"800"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('VideoChip',{staticClass:"mx-3",attrs:{"videoName":item,"onClick":_vm.playVideo}})]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }