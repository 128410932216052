<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col>
        <v-card class="elevation-8 pa-4">
          <v-row>
            <v-col cols="auto">
              <span :class="($vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3') + ' font-weight-light'">
                {{ $vuetify.lang.t("$vuetify.home.greeting", user.name) }}
              </span>
            </v-col>
          </v-row>
          <v-row align="center" justify="space-around">
            <v-col cols="auto">
              <v-row align="center">
                <v-col cols="auto">
                  <span class="text-body-1"
                    >{{ $vuetify.lang.t("$vuetify.home.next_1") }} <br />
                    {{ $vuetify.lang.t("$vuetify.home.next_2") }}
                  </span>
                </v-col>
                <v-col v-if="nextVideo" cols="auto">
                  <VideoChip
                    :videoName="nextVideo"
                    :onClick="playVideo"
                  ></VideoChip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <span class="text-body-1">{{
                $vuetify.lang.t("$vuetify.home.stats.1")
              }}</span
              ><span class="text-h3 font-weight-light primary--text mx-2">{{
                stats.watched
              }}</span>
              <span class="text-body-1">{{
                $vuetify.lang.t("$vuetify.home.stats.2")
              }}</span>
              <span class="text-h3 font-weight-light primary--text mx-2">{{
                stats.all
              }}</span>
              <span class="text-body-1">{{
                $vuetify.lang.t("$vuetify.home.stats.3")
              }}</span>
              <span class="text-h3 font-weight-light primary--text ml-2">{{
                stats.percent
              }}</span>
              <span class="text-body-1">%</span>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col
              :cols="$vuetify.breakpoint.xlOnly ? 4 : undefined"
              v-if="recommendations && recommendations.length > 0"
            >
              <v-card
                outlined
                :min-width="$vuetify.breakpoint.lgAndUp ? 400 : 340"
                max-height="750"
                style="overflow-y: auto;"
              >
              <v-card-title class="text-h5 font-weight-light">{{
                  $vuetify.lang.t("$vuetify.home.recommended.title")
                }}</v-card-title>
                <v-card-subtitle>{{
                  $vuetify.lang.t("$vuetify.home.recommended.sub_title")
                }}</v-card-subtitle>
                <VideoChip
                  v-for="(videoName, index) in recommendations"
                  v-bind:key="index"
                  :videoName="videoName"
                  :onClick="playVideo"
                  class="ma-2"
                ></VideoChip>
              </v-card>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.xlOnly ? 4 : undefined"
              v-if="mustWatches && mustWatches.length > 0"
            >
              <v-card
                outlined
                :min-width="$vuetify.breakpoint.lgAndUp ? 400 : 340"
              >
                <v-card-title class="text-h5 font-weight-light">{{
                  $vuetify.lang.t("$vuetify.home.must_watch")
                }}</v-card-title>
                <v-card-subtitle>{{
                  $vuetify.lang.t("$vuetify.home.must_sub_title")
                }}</v-card-subtitle>
                <VideoChip
                  v-for="(videoName, index) in mustWatches"
                  v-bind:key="index"
                  :videoName="videoName"
                  :onClick="playVideo"
                  class="ma-2"
                ></VideoChip>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xlOnly ? 4 : undefined">
              <v-card
                outlined
                :min-width="$vuetify.breakpoint.lgAndUp ? 400 : 340"
              >
                <v-card-title class="text-h5 font-weight-light">{{
                  $vuetify.lang.t("$vuetify.home.next")
                }}</v-card-title>
                <v-card-subtitle>{{
                  $vuetify.lang.t("$vuetify.home.next_sub_title")
                }}</v-card-subtitle>
                <VideoChip
                  v-for="(videoName, index) in next6Videos"
                  v-bind:key="index"
                  :videoName="videoName"
                  :onClick="playVideo"
                  class="ma-2"
                ></VideoChip>

                <div
                  class="empty-state-box text--secondary mt-5"
                  v-if="next6Videos.length === 0"
                >
                  <span class="text-h1 mt-4">🥳</span>
                  <span class="mt-3">
                    {{ $vuetify.lang.t("$vuetify.home.next_empty") }}
                  </span>
                </div>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text>
                    {{ $vuetify.lang.t("$vuetify.home.show_all") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xlOnly ? 4 : undefined">
              <v-card
                outlined
                :min-width="$vuetify.breakpoint.lgAndUp ? 400 : 340"
              >
                <v-card-title class="text-h5 font-weight-light">{{
                  $vuetify.lang.t("$vuetify.home.history")
                }}</v-card-title>
                <v-card-subtitle>{{
                  $vuetify.lang.t("$vuetify.home.history_sub_title")
                }}</v-card-subtitle>
                <div
                  v-for="(watch, index) in last6History"
                  v-bind:key="index"
                  class="mx-3 my-2"
                >
                  {{
                    new Date(watch.timestamp).toLocaleString(undefined, {
                      dateStyle: "short",
                    }) +
                    " " +
                    new Date(watch.timestamp).toLocaleString(undefined, {
                      timeStyle: "short",
                    })
                  }}
                  <VideoChip
                    :videoName="watch.video"
                    :onClick="playVideo"
                  ></VideoChip>
                </div>
                <div
                  class="empty-state-box text--secondary mt-5"
                  v-if="last6History.length === 0"
                >
                  <span class="text-h1 mt-4">👀</span>
                  <span class="mt-3">
                    {{ $vuetify.lang.t("$vuetify.home.history_empty_1") }}
                  </span>
                  <span class="mt-1">
                    {{ $vuetify.lang.t("$vuetify.home.history_empty_2") }}
                  </span>
                </div>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="goTo('history')">
                    {{ $vuetify.lang.t("$vuetify.home.show_all") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getNextNVideo } from "../../util/playlist";
import VideoChip from "./../appComponents/VideoChip";

export default {
  name: "Home",
  components: {
    VideoChip,
  },
  data() {
    return {
      nextVideo: null,
      nextVideos: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    history() {
      return this.$store.getters.history;
    },
    last6History() {
      return this.$store.getters.history.slice(0, 6);
    },
    next6Videos() {
      return this.nextVideos.slice(0, 6);
    },
    playlist() {
      return this.$store.getters.playlist;
    },
    mustWatches() {
      return this.$store.getters.mustWatches;
    },
    lastVideo() {
      if (this.history.length > 0) {
        return this.history[0].video;
      } else {
        return null;
      }
    },
    userData() {
      return this.$store.getters.userData;
    },
    stats() {
      const all = Object.keys(this.videoMetaData).length;
      const watched = this.history.length < all ? this.history.length : all;
      return {
        all: all,
        watched: watched,
        percent: all === 0 ? 0 : Math.round((watched / all) * 100 * 100) / 100,
      };
    },
    recommendations() {
      if (this.user.recommendations && this.user.recommendations.length > 0) {
        return this.user.recommendations;
      }
      return [];
    },
  },
  watch: {},
  methods: {
    init() {
      this.nextVideos = getNextNVideo(
        this.playlist,
        this.lastVideo,
        5,
        true,
        this.userData
      );
      if (this.nextVideos.length === 0) {
        const result = getNextNVideo(
          this.playlist,
          this.lastVideo,
          2,
          false,
          this.userData
        );
        if (result.length > 0) {
          this.nextVideo = result[0];
        }
      } else {
        this.nextVideo = this.nextVideos[0];
      }
    },
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
</style>
