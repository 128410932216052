<template>
  <v-container class="fill-height">
    <v-row no-gutters justify="center" style="align-self: flex-end">
      <v-col cols="12" sm="10" md="8" align="center">
        <v-img
          contain
          max-width="500"
          :src="
            this.$vuetify.theme.dark
              ? require('../../assets/udrive_logo_neg1_3-f_rgb_gray.svg')
              : require('../../assets/udrive_logo_pos_3-f_rgb.svg')
          "
        ></v-img>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      class="mt-2"
      style="align-self: flex-start"
    >
      <v-col cols="12" sm="10" md="8" align="center">
        <v-progress-circular
          :size="100"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Splash",

  data: () => ({}),
};
</script>
