<template>
  <v-snackbar
    bottom
    right
    width="200"
    elevation="7"
    v-model="snackbar.show"
    :color="snackbar.color"
    timeout="-1"
    :multi-line="snackbar.multiline"
  >
    <div @mouseover="hover = true" @mouseleave="hover = false">
      <v-row class="mb-3">
        <v-progress-linear
          absolute
          reverse
          height="8"
          color="secondary"
          :value="snackbarProgress"
          class="top-rounded"
        ></v-progress-linear>
      </v-row>
      <v-row>
        <v-col>
          {{ snackbar.text }}
        </v-col>
        <v-col cols="auto">
          <v-btn text v-on:click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackbar",
  data: function () {
    return {
      hover: false,
    };
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    },
  },
  watch: {
    hover: function (newVal) {
      if(newVal){
          this.$store.dispatch("pauseSnackbarTimer")
      }else{
          this.$store.dispatch("unpauseSnackbarTimer")
      }
    },
  },
  computed: {
    snackbar() {
      return this.$store.getters.snackbar;
    },
    snackbarProgress() {
      return this.$store.getters.snackbarProgress;
    },
  },
};
</script>

<style lang="scss">
.top-rounded {
  top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>