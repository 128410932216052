<template>
  <v-container class="mt-5" style="align-content: center">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-img
          contain
          max-height="150"
          height="150"
          :src="
            this.$vuetify.theme.dark
              ? require('../../assets/udrive_logo_neg1_3-f_rgb_gray.svg')
              : require('../../assets/udrive_logo_pos_3-f_rgb.svg')
          "
        ></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-card class="elevation-10">
          <v-toolbar color="primary" flat>
            <v-toolbar-title>{{
              $vuetify.lang.t("$vuetify.login.instructor")
            }}</v-toolbar-title>
            <v-spacer />
            <v-btn @click="showInfo = !showInfo" icon large>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="formInstructor"
              v-model="isValid"
              lazy-validation
              v-on:submit.prevent
            >
              <v-alert
                :value="showInfo"
                transition="fade-transition"
                text
                type="info"
                >{{
                  $vuetify.lang.t("$vuetify.login.info_instructor")
                }}</v-alert
              >
              <v-alert
                :value="hasError"
                transition="slide-y-reverse-transition"
                dense
                text
                type="error"
                >{{ loginState.errorMessage }}</v-alert
              >
              <v-alert
                :value="hasToManyAttempts"
                transition="slide-y-reverse-transition"
                dense
                text
                type="info"
              >
                {{ $vuetify.lang.t("$vuetify.login.toManyAttemptsAdmin") }}
              </v-alert>
              <v-text-field
                id="email"
                :label="$vuetify.lang.t('$vuetify.login.email')"
                :rules="emailRules"
                v-model="userData.mail"
                required
                @keyup.enter="submit"
                autocomplete="email"
                prepend-icon="mdi-account"
                type="text"
              />
              <v-text-field
                id="password"
                v-model="userData.password"
                required
                clearable
                autocomplete="current-password"
                :label="$vuetify.lang.t('$vuetify.login.password')"
                @keyup.enter="submit"
                :rules="passwordRules"
                prepend-icon="mdi-lock"
                type="password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$router.push({ name: 'login' })"
              :disabled="loginState.isLoading"
              text
              >{{ $vuetify.lang.t("$vuetify.login.as_student") }}</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loginState.isLoading"
              @click="submit"
              >{{ $vuetify.lang.t("$vuetify.login.action") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { loginAdmin } from "./../../firebase/authAdmin";
export default {
  name: "LoginAdmin",
  data: () => ({
    isValid: false,
    showInfo: false,
    userData: {
      mail: "",
      password: "",
    },
    loginState: {
      isLoading: false,
      errorMessage: "",
      attemptCount: 0,
    },
  }),
  methods: {
    async submit() {
      if (!this.$refs.formInstructor.validate()) {
        return;
      }

      this.setLoading();
      await this.tryLogin();
    },
    async tryLogin() {
      const user = await loginAdmin(this.userData.mail, this.userData.password);
      this.removeLoading();
      if (user && typeof user !== "string") {
        //valid user!
      } else if (user === "PW_NOT_FOUND") {
        this.loginState.errorMessage = this.$vuetify.lang.t(
          "$vuetify.login.error_wrong_credentials"
        );
        this.loginState.attemptCount++;
      } else {
        this.$store.dispatch(
          "alertError",
          this.$vuetify.lang.t("$vuetify.api_error_unknown")
        );
      }
    },
    setLoading() {
      this.loginState.isLoading = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading() {
      this.loginState.isLoading = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
  },
  computed: {
    hasError() {
      return (
        !!this.loginState.errorMessage &&
        this.loginState.errorMessage.length > 0
      );
    },
    hasToManyAttempts() {
      return this.loginState.attemptCount >= 3;
    },
    emailRules() {
      return [
        (v) =>
          !!v || this.$vuetify.lang.t("$vuetify.login.validation.email_empty"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$vuetify.lang.t("$vuetify.login.validation.email_format"),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.login.validation.password_empty"),
        (v) =>
          (v && v.length >= 6) ||
          this.$vuetify.lang.t("$vuetify.login.validation.password_length"),
      ];
    },
  },
};
</script>