/*
    this master playlist have to be uploaded to every video. As master.m3u8
    #EXTM3U
    #EXT-X-VERSION:3
    #EXT-X-STREAM-INF:BANDWIDTH=800000,RESOLUTION=640x360
    (firebase)360p.m3u8
    #EXT-X-STREAM-INF:BANDWIDTH=1400000,RESOLUTION=842x480
    (firebase)480p.m3u8
    #EXT-X-STREAM-INF:BANDWIDTH=2800000,RESOLUTION=1280x720
    (firebase)720p.m3u8
    #EXT-X-STREAM-INF:BANDWIDTH=5000000,RESOLUTION=1920x1080
    (firebase)1080p.m3u8
*/
export const masterPlaylistAsBase64 = "I0VYVE0zVQ0KI0VYVC1YLVZFUlNJT046Mw0KI0VYVC1YLVNUUkVBTS1JTkY6QkFORFdJRFRIPTgwMDAwMCxSRVNPTFVUSU9OPTY0MHgzNjANCihmaXJlYmFzZSkzNjBwLm0zdTgNCiNFWFQtWC1TVFJFQU0tSU5GOkJBTkRXSURUSD0xNDAwMDAwLFJFU09MVVRJT049ODQyeDQ4MA0KKGZpcmViYXNlKTQ4MHAubTN1OA0KI0VYVC1YLVNUUkVBTS1JTkY6QkFORFdJRFRIPTI4MDAwMDAsUkVTT0xVVElPTj0xMjgweDcyMA0KKGZpcmViYXNlKTcyMHAubTN1OA0KI0VYVC1YLVNUUkVBTS1JTkY6QkFORFdJRFRIPTUwMDAwMDAsUkVTT0xVVElPTj0xOTIweDEwODANCihmaXJlYmFzZSkxMDgwcC5tM3U4"