<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="elevation-8 pa-4">
          <div :class="($vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'">
            {{ $vuetify.lang.t("$vuetify.history.header") }}
          </div>
          <div class="text--secondary">
            {{ $vuetify.lang.t("$vuetify.history.description") }}
          </div>
          <v-row justify="space-around" no-gutters>
            <v-col
              :cols="$vuetify.breakpoint.lgAndUp ? 9 : undefined"
              class="mt-6"
            >
              <v-row
                align="center"
                v-for="item in displayedVideos"
                dense
                v-bind:key="item.video"
              >
                <v-col cols="auto">
                  <span>
                    {{
                      new Date(item.timestamp).toLocaleString(undefined, {
                        dateStyle: "short",
                      }) +
                      " " +
                      new Date(item.timestamp).toLocaleString(undefined, {
                        timeStyle: "short",
                      })
                    }}
                  </span>
                </v-col>
                <v-col>
                  <VideoChip
                    :videoName="item.video"
                    :onClick="playVideo"
                    class="ml-3"
                  ></VideoChip>
                </v-col>
              </v-row>
              <div
                class="empty-state-box text--secondary mt-5"
                v-if="history.length === 0"
              >
                <span class="text-h1 mt-4">👀</span>
                <span class="mt-3">
                  {{ $vuetify.lang.t("$vuetify.home.history_empty_1") }}
                </span>
                <span class="mt-1">
                  {{ $vuetify.lang.t("$vuetify.home.history_empty_2") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-pagination v-model="page" :length="length" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoChip from "./../appComponents/VideoChip";

export default {
  name: "History",
  components: {
    VideoChip,
  },
  data() {
    return {
      itemPerPage: 50,
      page: 1,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    history() {
      return this.$store.getters.history;
    },
    userData() {
      return this.$store.getters.userData;
    },
    displayedVideos() {
      if (this.history) {
        const start = (this.page - 1) * this.itemPerPage;
        return this.history.slice(start, start + this.itemPerPage);
      } else return [];
    },
    length() {
      if (this.history) {
        const addOne = this.history.length % this.itemPerPage != 0;
        const pageSize = Math.floor(this.history.length / this.itemPerPage);
        if (addOne) return pageSize + 1;
        else return pageSize;
      } else return 1;
    },
  },
  watch: {},
  methods: {
    init() {},
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
</style>
