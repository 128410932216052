<template>
  <v-footer app padless absolute class="pa-1">
    <div v-if="!$vuetify.breakpoint.smAndDown">
      <a
        href="https://www.ulli-deinefahrschule.de/impressum/"
        target="_blank"
        class="text-caption text--secondary mr-2 font-weight-light"
      >
        {{ $vuetify.lang.t("$vuetify.footer.legalNotice") }}
      </a>
      <a
        href="https://www.ulli-deinefahrschule.de/datenschutz/"
        target="_blank"
        class="text-caption text--secondary font-weight-light"
      >
        {{ $vuetify.lang.t("$vuetify.footer.privacy") }}
      </a>
    </div>
    <v-spacer></v-spacer>
    {{ $vuetify.lang.t("$vuetify.driving_school") }}, Copyright
    {{ new Date().getFullYear() }}
    <v-spacer></v-spacer>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <a
        href="https://www.ulli-deinefahrschule.de/impressum/"
        target="_blank"
        class="text-caption text--secondary mr-2 font-weight-light"
      >
        {{ $vuetify.lang.t("$vuetify.footer.legalNotice") }}
      </a>
      <a
        href="https://www.ulli-deinefahrschule.de/datenschutz/"
        target="_blank"
        class="text-caption text--secondary font-weight-light"
      >
        {{ $vuetify.lang.t("$vuetify.footer.privacy") }}
      </a>
    </div>

    <div class="ml-2">
      <a
        @click="openCookieSettings"
        class="
          a
          text-caption text--secondary text-decoration-underline
          font-weight-light
        "
      >
        {{ $vuetify.lang.t("$vuetify.footer.cookie") }}
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {},
  methods: {
    init() {},
    openCookieSettings() {
      this.$store.commit("showCookieDialog");
    },
  },
};
</script>