<template>
  <div></div>
</template>


<script>
import { ffmpagCommand } from "./../../util/ffmpagCommand";
import { uploadBlobs } from "./../../firebase/storage";

export default {
  data: function () {
    return {
      ffmpegLoader: null,
      ffmpeg: null,
      command: ffmpagCommand,
      lastProgressValue: 0,
      outputFiles: [],
      qualities: ["360p", "480p", "720p", "1080p"],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isJobSet() {
      return this.$store.getters.isJobSet;
    },
    job() {
      return this.$store.getters.job;
    },
    progress() {
      return this.$store.getters.jobProgress;
    },
  },
  watch: {
    isJobSet: function (newVal) {
      if (newVal) {
        if (this.job.file && this.job.videoName) {
          this.encodeVideo(newVal);
        } else {
          console.error("Expected file and videoName to be nun null!");
        }
      }
    },
  },
  methods: {
    init() {
      //load ffmpeg (actually ist only the loader)
      if (!this.ffmpegLoader || !this.ffmpeg) {
        let externalScript = document.createElement("script");
        const vm = this;
        externalScript.setAttribute(
          "src",
          "https://unpkg.com/@ffmpeg/ffmpeg@0.9.5/dist/ffmpeg.min.js"
        );
        externalScript.addEventListener("load", () => {
          vm.ffmpegLoader = window.FFmpeg;
        });
        document.head.appendChild(externalScript);
      }
    },
    async encodeVideo() {
      const vm = this;
      const file = this.job.file;
      this.$store.commit("setIsJobRunning", true);
      this.$store.dispatch(
        "alertInfo",
        this.$vuetify.lang.t("$vuetify.upload.new.progress.info")
      );
      this.setProgress(null, 1, 4);
      try {
        //encodes the actual video
        //is ffmpag initialized
        if (!this.ffmpeg) {
          this.ffmpeg = this.ffmpegLoader.createFFmpeg({
            log: false,
            progress: (p) => {
              if (p.ratio - vm.lastProgressValue > 0.01) {
                vm.setProgress(p.ratio * 100);
                this.lastProgressValue = p.ratio;
              }
            },
          });
          await this.ffmpeg.load();
        }

        //load video
        this.ffmpeg.FS(
          "writeFile",
          "in.mp4",
          await this.ffmpegLoader.fetchFile(file)
        );
        this.setProgress(null, 2, 4);

        //run command
        await this.ffmpeg.run(...this.command);
        this.ffmpeg.FS("unlink", "in.mp4"); //delete input
        this.setProgress(null, 3, 4);

        //try to get all output data
        await this.getAllOutputFiles();
        this.setProgress(null, 4, 4);

        //upload files
        const uploadResult = await uploadBlobs(
          this.job.videoName,
          this.outputFiles
        );
        if (!uploadResult) {
          throw "Error during files upload!";
        }

        //it is done!
        this.$store.dispatch(
          "alertSuccess",
          this.$vuetify.lang.t("$vuetify.encoding.done", this.job.videoName)
        );
      } catch (error) {
        console.error(error);
        this.$store.dispatch(
          "alertError",
          this.$vuetify.lang.t("$vuetify.encoding.error")
        );
      } finally {
        //delete all files reset job
        this.deleteAllFiles();
        this.$store.commit("finnishJob");
      }
    },
    async getAllOutputFiles() {
      const vm = this;
      for (var qi = 0; qi < this.qualities.length; qi++) {
        const quality = this.qualities[qi];
        //get playlist file
        vm.getOneFileAndDeleteIt(quality + ".m3u8", false);
        //get ts files until an error is raised (we don't know how much parts are there)
        var i = 0;
        var go = true;
        while (go) {
          try {
            vm.getOneFileAndDeleteIt(quality + i + ".ts", true);
          } catch (error) {
            go = false;
          }
          i++;
        }
      }

      //add master playlist manually
    },
    getOneFileAndDeleteIt(name, isTsFile) {
      const data = this.ffmpeg.FS("readFile", name);
      this.outputFiles.push({
        name: name,
        blob: new Blob([data.buffer], {
          type: isTsFile ? "video/MP2T" : "application/x-mpegURL",
        }),
      });
      this.ffmpeg.FS("unlink", name);
    },
    deleteAllFiles() {
      const vm = this;
      vm.tryToDeleteOneFile("in.mp4");
      for (var qi = 0; qi < this.qualities.length; qi++) {
        const quality = this.qualities[qi];
        vm.tryToDeleteOneFile(quality + ".m3u8", false);
        var i = 0;
        var go = true;
        while (go) {
          go = vm.tryToDeleteOneFile(quality + i + ".ts", true);
          i++;
        }
      }
      console.log("all files deleted");
    },
    tryToDeleteOneFile(name) {
      try {
        this.ffmpeg.FS("unlink", name);
        return true;
      } catch (error) {
        return false;
      }
    },
    setProgress(newPercent, newStep = null, stepCount = 4) {
      this.$store.commit("setJobProgress", {
        currentStep: newStep ? newStep : this.progress.currentStep,
        steps:
          this.progress.steps === stepCount ? stepCount : this.progress.steps,
        progress: newPercent,
      });
    },
  },
};
</script>