<template>
  <v-app-bar v-if="user" app clipped-left color="primary">
    <v-app-bar-nav-icon v-if="user" @click="toggleDrawer()" />
    <v-progress-linear
      absolute
      color="secondary"
      :active="globalLoadingState"
      bottom
      :indeterminate="true"
      height="5"
    ></v-progress-linear>
    <v-img
      aspect-ratio="16:9"
      max-width="130"
      class="ml-n4"
      :src="require('../../assets/udrive_logo_neg2_3-f_rgb_gruen.svg')"
      @click="toHome"
    ></v-img>
    <v-spacer v-if="user" />
    <v-autocomplete
      v-if="user"
      :solo="this.$vuetify.theme.dark"
      :solo-inverted="!this.$vuetify.theme.dark"
      :dark="!this.$vuetify.theme.dark"
      hide-details
      dense
      rounded
      flat
      v-model="search"
      item-value="name"
      :items="videosAsArray"
      item-text="displayName"
      append-icon=""
      @change="onSearchSelected"
      :clearable="$vuetify.breakpoint.mdAndUp"
      class="mx-3 my-2"
      :no-data-text="$vuetify.lang.t('$vuetify.appBar.not_found')"
      single-line
      prepend-inner-icon="mdi-magnify"
      ref="search"
      :label="
        $vuetify.breakpoint.mdAndUp
          ? $vuetify.lang.t('$vuetify.appBar.search_long')
          : $vuetify.lang.t('$vuetify.appBar.search')
      "
    >
    </v-autocomplete>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
    <v-spacer v-if="!user" />
    <v-btn
      rounded
      elevation="3"
      color="accent"
      dark
      @click="showToken"
      v-if="showTokenButton"
    >
      get Token
    </v-btn>
  </v-app-bar>
</template>

<script>
import { getToken } from "../../firebase/firebase";
export default {
  components: {},
  data: () => ({
    darkThemeSelector: localStorage.getItem("darkmode") === "true" ? 1 : 0,
    search: null,
  }),
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawerExpanded");
    },
    toHome() {
      this.$router.push({ name: "home" });
    },
    onSearchSelected(videoName) {
      this.$router.push({ name: "player", params: { video: videoName } });
      this.$nextTick(function () {
        //reset and un focus (blur) the search
        this.search = null;
        this.$refs.search.blur();
      });
    },
    async showToken() {
      await this.$confirm(await getToken());
    },
  },
  watch: {
    darkThemeSelector: function (val) {
      if (val) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("darkmode", "true");
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("darkmode", "false");
      }
    },
  },
  computed: {
    toolbarItems() {
      return this.$store.getters.toolbar.items;
    },
    title() {
      return this.$vuetify.lang.t("$vuetify.application_name");
    },
    globalLoadingState() {
      return this.$store.getters.globalLoadingState;
    },
    user() {
      return this.$store.getters.user;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    videosAsArray() {
      return Object.values(this.videoMetaData);
    },
    showTokenButton() {
      return location && location.hostname === "localhost";
    },
  },
};
</script>