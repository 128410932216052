<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" >
        <v-card class="elevation-8 pa-4">
          <div class="text-h3 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.mustWatches.header") }}
          </div>
          <div class="text--secondary">
            {{ $vuetify.lang.t("$vuetify.mustWatches.description") }}
          </div>
          <div class="headline mt-4">
            {{ $vuetify.lang.t("$vuetify.mustWatches.add") }}
          </div>
          <v-row align="center">
            <v-col cols="12">
              <v-autocomplete
                chips
                deletable-chips
                multiple
                item-text="displayName"
                item-value="name"
                v-model="localMustWatches"
                :items="videosAsArray"
                outlined
                @change="onUpdate"
                :no-data-text="$vuetify.lang.t('$vuetify.appBar.not_found')"
              ></v-autocomplete>
            </v-col>
          </v-row>
            <v-row>
              <v-col>
                <div class="headline mt-2">
                  {{ $vuetify.lang.t("$vuetify.mustWatches.change") }}
                </div>
              </v-col>
            </v-row>
            <div
              :class="$vuetify.breakpoint.smAndDown ? '' : 'list'"
              v-if="localMustWatches.length > 0"
            >
              <v-row>
                <v-col>
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, index) in localMustWatches"
                        :ripple="false"
                        :key="index"
                        inactive
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ videoMetaData[item].displayName }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                @click="changeOrder(index, index - 1)"
                                :disabled="index === 0"
                              >
                                <v-icon>mdi-arrow-up-bold</v-icon>
                              </v-btn>
                            </template>
                            {{
                              $vuetify.lang.t(
                                "$vuetify.lessons.button.up",
                                item
                              )
                            }}
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                @click="changeOrder(index, index + 1)"
                                :disabled="
                                  index === localMustWatches.length - 1
                                "
                              >
                                <v-icon>mdi-arrow-down-bold</v-icon>
                              </v-btn>
                            </template>
                            {{
                              $vuetify.lang.t(
                                "$vuetify.lessons.button.down",
                                item
                              )
                            }}
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                @click="deleteItem(index)"
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            {{ $vuetify.lang.t("$vuetify.mustWatches.delete") }}
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "./../../firebase/firebase";

export default {
  name: "mustWatches",
  components: {},
  mounted() {
      this.localMustWatches = this.mustWatches
  },
  data: function () {
    return {
      loading: false,
      localMustWatches: [],
    };
  },
  watch: {},
  methods: {
    async onUpdate() {
      await this.save();
    },
    async changeOrder(from, to) {
      this.localMustWatches.splice(to, 0, this.localMustWatches.splice(from, 1)[0]);
      await this.save();
    },
    async save() {
      this.setLoading();
      await db.ref("mustWatches/").set(this.localMustWatches);
      this.updateAppCache();
      this.removeLoading();
    },
    async deleteItem(index){
        this.localMustWatches.splice(index, 1);
        await this.save();
    },
    updateAppCache() {
      this.$store.commit("setMustWatches", this.localMustWatches);
    },
    setLoading() {
      this.loading = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading() {
      this.loading = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    mustWatches() {
      return this.$store.getters.mustWatches;
    },
    videoMetaData() {
      return this.$store.getters.videoMetaData;
    },
    videosAsArray() {
      return Object.values(this.videoMetaData);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";

  .list {
    width: 80%;
  }

</style>