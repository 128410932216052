<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col>
        <v-card class="elevation-8 pa-4">
          <div :class="($vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'">
            {{ $vuetify.lang.t("$vuetify.allVideosLesson.header") }}
          </div>
          <div class="text--secondary">
            {{ $vuetify.lang.t("$vuetify.allVideosLesson.description") }}
          </div>
          <v-row justify="space-around">
            <v-col
              :cols="$vuetify.breakpoint.lgAndUp ? 6 : undefined"
              v-for="(lesson, index) in filteredLessons"
              v-bind:key="index"
            >
              <v-card outlined min-width="350">
                <v-card-title class="text-h5 font-weight-light">{{
                  lesson.text
                }}</v-card-title>
                <v-card-subtitle
                  v-if="lesson.description"
                  class="text--primary font-weight-light"
                >
                  {{ lesson.description }}
                </v-card-subtitle>
                <v-virtual-scroll
                  :items="lesson.linkedVideos"
                  item-height="71"
                  max-height="800"
                >
                  <template v-slot:default="{ item }">
                    <VideoChip
                      :videoName="item"
                      :onClick="playVideo"
                      class="mx-3"
                    ></VideoChip>
                  </template>
                </v-virtual-scroll>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoChip from "./../appComponents/VideoChip";

export default {
  name: "All-Videos-Lessons",
  components: {
    VideoChip,
  },
  data() {
    return {
      filteredLessons: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    lessonsAsArray() {
      const temp = Object.values(this.$store.getters.lessons);
      temp.sort((a, b) => a.index - b.index);
      return temp;
    },
    lessons() {
      return this.$store.getters.lessons;
    },
  },
  watch: {},
  methods: {
    init() {
      for (let i = 0; i < this.lessonsAsArray.length; i++) {
        if (
          this.lessonsAsArray[i].linkedVideos &&
          this.lessonsAsArray[i].linkedVideos.length !== 0 &&
          !this.lessonsAsArray[i].isHiddenInAllCategories
        ) {
          this.filteredLessons.push(this.lessonsAsArray[i]);
        }
      }
    },
    playVideo(video) {
      this.$router.push({ name: "player", params: { video: video.name } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
