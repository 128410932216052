
/**
 * calculates a playlist of all videos that are linked to a lesson.
 * The playlist is based on the order of the lessons, inside one lessen the order is more or less random it depends on the order of the linkedVideos array.
 * Videos wih multiple linked lessons will only appear once in the playlist. 
 * 
 * @param {object} vm vueInstance
 * @param {boolean} onlyUnseen flag to determine if only unseen videos should be used
 * @returns a array of video names
 */
export function calculatePlaylist(vm, onlyUnseen = false) {
    //get all lessons, all visible videos and user data
    const lessons = vm.$store.getters.lessons
    const videos = vm.$store.getters.videoMetaData
    const userData = vm.$store.getters.userData

    const ret = []
    const alreadyAdded = {}

    //sort lessons
    if (lessons && Object.keys(lessons).length !== 0) {
        const lessonAsArray = Object.values(lessons);
        lessonAsArray.sort((a, b) => a.index - b.index);

        //iterate over lessons
        for (let i = 0; i < lessonAsArray.length; i++) {
            const lesson = lessonAsArray[i]
            if (lesson.linkedVideos) {

                //iterate over all linked videos
                for (let ii = 0; ii < lesson.linkedVideos.length; ii++) {

                    //if visible?
                    if (videos[lesson.linkedVideos[ii]]) {
                        let toAdd = false
                        if (onlyUnseen) {
                            //check if the user watched the video already, if not add it to the return
                            if (!userData.watched) {
                                toAdd = true
                            } else {
                                if (!userData.watched[lesson.linkedVideos[ii]]) {
                                    toAdd = true
                                }
                            }
                        } else {
                            toAdd = true
                        }

                        if (toAdd) {
                            //make sure to add videos only once
                            if (!alreadyAdded[lesson.linkedVideos[ii]]) {
                                ret.push(lesson.linkedVideos[ii])
                                alreadyAdded[lesson.linkedVideos[ii]] = lesson.linkedVideos[ii]
                            }
                        }
                    }
                }
            }
        }
    }

    return ret
}


/**
 * returns the next N videos after the given video name.
 * 
 * @param {string[]} playlist array of video names
 * @param {string} videoName the video of wich we are searching the next ones
 * @param {number} n how many next videos should be returned
 * @param {boolean} onlyUnseen only return unseen videos
 * @param {Object} userData if onlyUnseen true, we need the userData to check if the video was seen
 * @returns a array of the next n videos
 */
export function getNextNVideo(playlist, videoName, n, onlyUnseen = true, userData = null) {

    const ret = []
    let startVideo = -1
    if (!videoName) {
        startVideo = 0
    }

    if (playlist.length !== 0) {
        for (let i = 0; i < playlist.length; i++) {

            //1st break if we have enough videos
            if (ret.length === n) break

            //2nd find start video
            if (playlist[i] === videoName && startVideo === -1) {
                startVideo = i
                continue
            }

            // 3rd add videos
            if (startVideo !== -1) {
                addVideo(onlyUnseen, userData, ret, playlist, i)
            }
        }

        //dang it the last viewed video is not in the playlist... just use the first one
        if (startVideo === -1){
            return getNextNVideo(playlist, null, n, onlyUnseen, userData)
        }

        //now add the first videos until the statVideo (imitate a ringlist)
        if (ret.length < n) {
            for (let i = 0; i < startVideo; i++) {
                if (ret.length === n) break
                addVideo(onlyUnseen, userData, ret, playlist, i)
            }
        }
    }
    return ret;
}

function addVideo(onlyUnseen, userData, ret, playlist, i) {
    if (onlyUnseen) {
        if (!userData.watched) {
            ret.push(playlist[i])
        } else {
            if (!userData.watched[playlist[i]]) {
                ret.push(playlist[i])
            }
        }
    } else {
        ret.push(playlist[i])
    }
}

