<template>
  <v-dialog
    v-model="show"
    scrollable
    :persistent="hasChanges"
    max-width="1000px"
    @click:outside="softClose"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="elevation-8">
      <v-container class="lessons">
        <v-row justify="center" align="center">
          <v-col class="text-h4 font-weight-light">
            {{ $vuetify.lang.t("$vuetify.editVideo.header", video.name) }}
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="formVideo" v-model="isValid" lazy-validation>
          <v-row align="center">
            <v-col cols="auto">
              <v-icon
                :color="localVideo.isVisible ? 'primary' : undefined"
                class="mt-5"
                style="padding-right: 0px"
              >
                {{ localVideo.isVisible ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-col>
            <v-col cols="auto"
              ><v-switch
                dense
                :label="$vuetify.lang.t('$vuetify.editVideo.visibility')"
                @change="onChange"
                hide-details="auto"
                v-model="localVideo.isVisible"
              ></v-switch>
            </v-col>
            <v-col
              class="flex-grow-1"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined"
            >
              <v-text-field
                v-model="localVideo.displayName"
                @change="onChange"
                hide-details="auto"
                prepend-icon="mdi-message-video"
                :rules="localVideo.isVisible ? displayNameRules : undefined"
                :label="
                  $vuetify.lang.t('$vuetify.manageVideos.table.displayName')
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              class="flex-grow-1"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined"
            >
              <v-combobox
                v-model="selectedLessons"
                :items="lessonsAsArray"
                @change="onChange"
                hide-details="auto"
                prepend-icon="mdi-shape"
                :label="$vuetify.lang.t('$vuetify.editVideo.lessons')"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="data.item.key"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    :color="$vuetify.theme.dark ? data.item.color.dark : data.item.color.light"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-icon left> {{data.item.icon}} </v-icon>
                    {{ data.item.text }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              class="flex-grow-1"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined"
            >
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.editVideo.description')"
                rows="1"
                counter
                @change="onChange"
                v-model="localVideo.description"
                :rules="descriptionRules"
                prepend-icon="mdi-text"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-btn text @click="close">
              {{ $vuetify.lang.t("$vuetify.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn outlined color="primary" @click="saveVideo">
              <v-icon left>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { calculatePlaylist } from '../../util/playlist';
import { db } from "./../../firebase/firebase";
import { updateLinkedVideos } from "./../../firebase/linkHelpers";

export default {
  name: "edit-video",
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    changed: {
      type: Boolean,
      required: true,
    },
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasChanges: false,
      isValid: false,
      selectedLessons: [],
      saving: false,
      localVideo: {
        isVisible: this.video.isVisible,
        displayName: this.video.displayName,
        lessons: this.video.lessons ? this.video.lessons : [],
        description: this.video.description,
        views: this.video.views
      },
    };
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        //dialog opened
        this.init();
      }
    },
    selectedLessons: function (newVal) {
      const keys = [];
      if (newVal) {
        newVal.forEach((lesson) => {
          keys.push(lesson.key);
        });
      }
      this.localVideo.lessons = keys;
    },
  },
  methods: {
    init() {
      //copy the given values to the local video
      Object.keys(this.localVideo).forEach((key) => {
        this.localVideo[key] = this.video[key];
      });
      if (!this.localVideo.description){
        this.localVideo.description = ""
      }
      if (!this.localVideo.displayName){
        this.localVideo.displayName = ""
      }
      //from key to hole objects
      this.selectedLessons = [];
      if (this.localVideo.lessons) {
        this.localVideo.lessons.forEach((val) => {
          this.selectedLessons.push(this.lessons[val]);
        });
      }
      this.hasChanges = false;
      if (this.$refs.formVideo) {
        this.$refs.formVideo.resetValidation();
      }
    },
    async saveVideo() {
      const result =
        this.video.isVisible && !this.localVideo.isVisible
          ? await this.$confirm(
              this.$vuetify.lang.t(
                "$vuetify.editVideo.confirm_hide",
                this.video.name
              )
            )
          : true;
      if (result && this.$refs.formVideo.validate()) {
        if (this.hasChanges) {
          //update linked videos, save this video, update it in the table and in the applicationCache
          this.saving = true;
          await updateLinkedVideos(
            this.video.name,
            this.video.lessons,
            this.localVideo.lessons,
            this
          );
          this.$store.commit("setPlaylist", calculatePlaylist(this));
          const result = Object.assign(this.video, this.localVideo);
          delete result.views;
          this.$store.commit("updateOneVideo", result);
          await db.ref("videos/" + this.video.name).set(result);
          result["views"] = this.localVideo.views
          this.$emit("update:video", result);
          this.$emit("update:changed", true);
        }
        this.saving = false;
        this.close();
      }
    },
    onChange() {
      this.hasChanges = true;
    },
    softClose() {
      if (!this.hasChanges) {
        this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
    },
  },
  computed: {
    lessonsAsArray() {
      const temp = Object.values(this.$store.getters.lessons);
      temp.sort((a, b) => a.index - b.index);
      return temp;
    },
    lessons() {
      return this.$store.getters.lessons;
    },
    displayNameRules() {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.editVideo.validation"),
        (v) =>
          (v && v.length >= 5) ||
          this.$vuetify.lang.t("$vuetify.validation.atLeastChar", 5),
        (v) =>
          (v && v.length <= 100) ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 100),
      ];
    },
    descriptionRules() {
      return [
        (v) =>
          v.length <= 500 ||
          this.$vuetify.lang.t("$vuetify.validation.maxChar", 500),
      ];
    },
  },
};
</script>
