<template>
  <!-- <video-background
    :src="require('../../assets/Smaller.mp4')"
    :playbackRate="0.7"
    style="height: 100vh"
    :overlay="this.$vuetify.theme.dark ? '#00000099' : ''"
  > -->
  <v-container class="mt-5" style="align-content: center">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-img
          contain
          height="150"
          max-height="150"
          :src="
            this.$vuetify.theme.dark
              ? require('../../assets/udrive_logo_neg1_3-f_rgb_gray.svg')
              : require('../../assets/udrive_logo_pos_3-f_rgb.svg')
          "
        ></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-card class="elevation-10">
          <v-toolbar color="primary" flat>
            <v-toolbar-title>{{
              $vuetify.lang.t("$vuetify.login.student")
            }}</v-toolbar-title>
            <v-spacer />
            <v-btn @click="showInfo = !showInfo" icon large>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="formStudent"
              v-model="isValid"
              lazy-validation
              v-on:submit.prevent
            >
              <v-alert
                :value="showInfo"
                transition="slide-y-reverse-transition"
                dense
                text
                type="info"
                >{{ $vuetify.lang.t("$vuetify.login.info_student") }}</v-alert
              >
              <v-alert
                :value="hasError"
                transition="slide-y-reverse-transition"
                dense
                text
                type="error"
                >{{ loginState.errorMessage }}</v-alert
              >
              <v-alert
                :value="hasToManyAttempts"
                transition="slide-y-reverse-transition"
                dense
                text
                type="info"
                >{{ $vuetify.lang.t("$vuetify.login.toManyAttempts") }}</v-alert
              >
              <v-text-field
                label="Anmeldecode"
                clearable
                id="code"
                :rules="codeRules"
                v-model="userData.code"
                required
                @keyup.enter="submit"
                prepend-icon="mdi-lock"
                type="text"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              :disabled="loginState.isLoading"
              @click="$router.push({ name: 'loginAdmin' })"
              >{{ $vuetify.lang.t("$vuetify.login.as_instructor") }}</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loginState.isLoading"
              @click="submit"
              >{{ $vuetify.lang.t("$vuetify.login.action") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- </video-background> -->
</template>

<script>
import { loginUser } from "./../../firebase/authStudent";
import { loadAppCache } from "./../../firebase/firebase";

export default {
  name: "Login",
  data: () => ({
    isValid: false,
    showInfo: false,
    userData: {
      code: "",
    },
    loginState: {
      isLoading: false,
      errorMessage: "",
      attemptCount: 0,
    },
  }),
  methods: {
    submit() {
      this.loginState.errorMessage = "";
      if (!this.$refs.formStudent.validate()) {
        return;
      }
      this.setLoading();
      this.tryLogin();
    },
    async tryLogin() {
      const user = await loginUser(this.userData.code);
      this.removeLoading();
      if (user && typeof user !== "string") {
        //valid user!
        this.$store.dispatch("setUser", user);
        await loadAppCache(this, user.uuid);
        this.$router.push({ name: "home" });
      } else if (user === "PW_NOT_FOUND" || user === "LINKED_ACC_NOT_FOUND") {
        this.loginState.errorMessage = this.$vuetify.lang.t(
          "$vuetify.login.error_wrong_code"
        );
        this.loginState.attemptCount++;
      } else {
        console.error(user);
        this.$store.dispatch(
          "alertError",
          this.$vuetify.lang.t("$vuetify.api_error_unknown")
        );
      }
    },
    setLoading() {
      this.loginState.isLoading = true;
      this.$store.commit("increaseGlobalLoadingState");
    },
    removeLoading() {
      this.loginState.isLoading = false;
      this.$store.commit("decreaseGlobalLoadingState");
    },
  },
  computed: {
    hasError() {
      return (
        !!this.loginState.errorMessage &&
        this.loginState.errorMessage.length > 0
      );
    },
    hasToManyAttempts() {
      return this.loginState.attemptCount >= 3;
    },
    codeRules() {
      return [
        (v) =>
          !!v || this.$vuetify.lang.t("$vuetify.login.validation.code_empty"),
        (v) =>
          (v && v.length == 6) ||
          this.$vuetify.lang.t("$vuetify.login.validation.code_length"),
      ];
    },
  },
};
</script>