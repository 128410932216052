<template>
  <v-dialog
    v-model="show"
    :persistent="hasChanges"
    max-width="1400px"
    @click:outside="softClose"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="elevation-8 pa-3">
      <v-container>
        <v-row justify="center">
          <v-col :class="($vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4') + ' font-weight-light'">
            {{
              $vuetify.lang.t("$vuetify.editUserRecommendations.title", student ? student.name : "")
            }}
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close()">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>
              <PlaylistPicker :playlist.sync="localRecommendations"/>
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-spacer />
          <v-col cols="auto">
            <v-btn text @click="close()">
              {{ $vuetify.lang.t("$vuetify.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn :loading="saving" outlined color="primary" @click="save">
              <v-icon left>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import PlaylistPicker from "./../appComponents/PlaylistPicker.vue"
import { db } from "./../../firebase/firebase";

export default {
  name: "editStudentRecommendations",
  components: {
    PlaylistPicker
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    student: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      hasChanges: false,
      saving: false,
      localRecommendations: [],
    };
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        //dialog opened
        this.init();
      }
    },
    localRecommendations: function (){
      this.onChange()
    }
  },
  methods: {
    init() {
      //copy the given values to the local category
      if (this.student && this.student.recommendations) {
        this.localRecommendations = this.student.recommendations.slice()
      }else {
        this.localRecommendations = []
      }

      this.hasChanges = false;    
    },
    async save() {
        this.saving = true
        this.student.recommendations = this.localRecommendations
        this.$emit("update:student", this.student);
        await db.ref("students/"+this.student.uuid+"/recommendations").set(this.localRecommendations)

        this.saving = false;
        this.close();
    },
    onChange() {
      this.hasChanges = true;
    },
    softClose() {
      if (!this.hasChanges) {
        this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
    },
  },
  computed: {
    
  },
};
</script>


